const items = [
  // {
  //   name: "Goals",
  //   icon: "cui-task",
  //   children: [
  //     {
  //       name: "Strategic Plan",
  //       url: "/plan"
  //     },
  //     {
  //       name: "Quarterly View",
  //       url: "/goals"
  //     }
  //   ]
  // },
  {
    title: true,
    name: "Goals",
    permissions: [1, 2, 3, 4]
  },
  {
    name: "Home",
    url: "/plan",
    icon: "cui-home",
    permissions: [1, 2, 3, 4]
  },
  { name: "Strategic Plan", url: "/goals", icon: "cui-task", permissions: [1, 2, 3, 4]},
  {
    title: true,
    name: "Admin",
    permissions: [1, 5]
  },
  {
    name: "Client Settings",
    url: "/settings",
    icon: "cui-settings",
    permissions: [1]
  },
  {
    name: "Users",
    url: "/users",
    icon: "cui-user",
    permissions: [1, 5]
  },
  {
    name: "Objectives & KPI",
    url: "/objectives",
    icon: "cui-map",
    permissions: [1, 4]
  },
  {
    title: true,
    name: "Owner Admin",
    permissions: [5],
    ownerAdminOnly: true
  },
  {
    name: "Clients",
    url: "/clients",
    icon: "cui-people",
    permissions: [5],
    ownerAdminOnly: true
  }
];

export default { items };
