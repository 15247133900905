import React, { Component } from "react";
import colors from "../assets/colors/colors";
import { Link } from "react-router-dom";

import axios from "axios";

export default class Kpi extends Component {
  constructor(props) {
    super(props);
    this.kpiInput = React.createRef();
    this.focusKpi = this.focusKpi.bind(this);
    console.log(this.props.key_indicator_id);
    this.state = {
      colorPrimary: props.user.color_primary || colors.primary,
      colorSecondary: props.user.color_secondary || colors.secondary,
      kpi_description: props.kpi_description,
      kpi_value: props.kpi_value,
      isNew: props.isNew || false,
      editMode: props.isNew || false
    };
  }

  focusKpi() {
    this.kpiInput.current.focus();
  }

  updateKpiHandler = () => {
    const kpi = {
      kpi_description: this.state.kpi_description,
      kpi_value: this.state.kpi_value
    };

    debugger;
    axios
      .put("/kpis/" + this.props.key_indicator_id, kpi)
      .then(res => {
        this.setState({ editMode: false });
debugger;
        this.props.kpiHandler();
        //notify user
      })
      .catch(error => {
        alert("Error occurred while saving KPI");
      });
  };

  render() {
    let backgroundSecondary = { backgroundColor: this.state.colorSecondary };
    let textPrimary = { color: this.state.colorPrimary };
    const fadeAnimation = this.state.isNew
      ? "animated fadeInRight fast"
      : "animated fadeIn faster";
    return (
      <React.Fragment>
        <div className={fadeAnimation + " my-2 kpi"}>
          <div
            className="row shadow--dark py-1 text-white"
            style={backgroundSecondary}
          >
            <div
              className="col my-auto mr-auto d-flex"
              title="Key Performance Indicator"
            >
              <input
                ref={this.kpiInput}
                type="text"
                value={this.state.kpi_description}
                className="h4 text-uppercase text-white bg-transparent border-0 kpi-desc-input"
                maxLength={20}
                onFocus={() =>
                  this.state.editMode && this.setState({ showMenu: true })
                }
                onChange={e => {
                  this.setState({ kpi_description: e.target.value });
                }}
                onBlur={() =>
                  setTimeout(() => this.setState({ showMenu: false }), 200)
                }
                readOnly={!this.state.editMode}
              />
              {this.state.editMode && (
                <i
                  className="fa fa-caret-down font-xl"
                  onClick={this.focusKpi}
                ></i>
              )}
            </div>
            <div className="col-auto my-auto">
              {this.state.editMode && (
                <button
                  className="btn bg-body rounded-0 px-0 py-0  add"
                  onClick={this.updateKpiHandler}
                  style={textPrimary}
                >
                  <i className="fas fa-check fa-fw"></i>
                </button>
              )}
              {!this.state.editMode && (
                <React.Fragment>
                  <button
                    className="btn bg-body rounded-0 px-0 py-0 mr-2  edit"
                    onClick={() => this.setState({ editMode: true })}
                    style={textPrimary}
                  >
                    <i className="fas fa-edit fa-fw"></i>
                  </button>
                  <button
                    className="btn bg-body rounded-0 px-0 py-0  delete"
                    onClick={() =>
                      this.props.deleteKpiHandler(this.props.key_indicator_id)
                    }
                    style={textPrimary}
                  >
                    <i className="fas fa-minus fa-fw"></i>
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
          {this.state.showMenu && (
            <div className="position-absolute  menu">
              {this.props.menus.map(menu => (
                <div
                  className="px-2"
                  key={menu.kpi_order}
                  onClick={() => {
                    this.setState({
                      kpi_description: menu.kpi_value,
                      showMenu: false
                    });
                  }}
                >
                  <span className="text-uppercase">{menu.kpi_value}</span>
                </div>
              ))}
            </div>
          )}
          <div className="row input-value">
            <input
              className="col bg-transparent border-0 py-2 px-3"
              value={this.state.kpi_value}
              onChange={e => this.setState({ kpi_value: e.target.value })}
              style={textPrimary}
              maxLength={50}
              readOnly={!this.state.editMode}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
