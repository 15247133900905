import React, { Component } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import InputColor from 'react-input-color';
import ActiveFilterToggler from "../RoadMap/ActiveFilterToggler";



export default class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      filer : 1,
      secondaryColor: props.user.color_secondary,
      showActive: false
    };
  }

  onChange = e => {
    this.setState({
      filer : e,
      showActive: (e > 0 ? true : false)
    });

    axios.get(`/clients/enabled/${e}`).then(res=>{
      this.setState({
        clients : res.data
      });
    });
  };

  onImpersonatBtnClick = (userId) => {
    axios
      .get("user/impersonate/" + userId)
      .then(res => {
        this.props.onImpersonateUser(res.data.session);
        this.props.selectPlan({ strategic_plan_id: undefined });

        this.props.history.push("/intermediate");
      });
  }

  async componentDidMount() {
    const clientsResponse = await axios.get(`/clients/enabled/${1}`);
    this.setState({
      clients: clientsResponse.data,
      showActive: true
    });
  }
  
  render() {
    
    return ( this.props.user.role_id === 5 ?
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-auto mr-auto">
            <h2>Clients</h2>
          </div>
          <div className="col-auto">
            <span class="filter-position">
              <ActiveFilterToggler
                color={this.state.secondaryColor}
                showActive={this.state.showActive}
                onToggleActive={this.onChange}>
              </ActiveFilterToggler>
            </span>
            <Link to="/clients/create">
              <button className="btn btn-outline-primary mr-2 shadow float-right">
                <i className="fas fa-plus"></i>&nbsp;New Client
          </button>
            </Link>
          </div>
        </div>

        {this.state.clients.length > 0 ? (
          <div className="card animated fadeIn faster">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Logo</th>
                  {/* <th>Address 1</th>
                  <th>Address 2</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zip</th> */}
                  <th>Primary Color</th>
                  <th>Secondary Color</th>
                  <th>Amber Threshold</th>
                  <th>Red Threshold</th>
                  <th className="text-center"> Email notifications </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.clients.map(client => {
                  return (
                    <tr key={client.client_id}>
                      <td>{client.client_name}</td>
                      <td>{!!client.client_logo && <img src={client.client_logo} alt={client.client_name + ' Logo'} width="90px" />}</td>
                      {/* <td>{client.address_1}</td>
                      <td>{client.address_2}</td>
                      <td>{client.city}</td>
                      <td>{client.state_prov}</td>
                      <td>{client.postal_code}</td> */}
                      <td><InputColor initialHexColor={client.color_primary} placement="right" /></td>
                      <td><InputColor initialHexColor={client.color_secondary} placement="right" /></td>
                      <td>{client.amber_threshold}%</td>
                      <td>{client.red_threshold}%</td>
                      <td >
                        <div className="text-center">
                          <label className="position-relative"
                            htmlFor={"enabled_" + client.client_id}

                          >

                            <input
                              className="checkbox position-absolute"
                              id={"enabled_" + client.client_id}
                              name={"enabled_" + client.client_id}
                              type="checkbox"
                              defaultChecked={client.enable_email}
                              disabled={true}
                            />
                            <i
                              className={`ml-2 font-lg fas ${

                                client.enable_email ? "fa-check-square" : "fa-square"
                                } `}
                              aria-hidden="true"
                            ></i>
                          </label>
                        </div>
                      </td>
                      <td>
                        <Link to={"/clients/" + client.client_id}>
                          <button className="btn btn-link py-0">
                            <i className="fas fa-edit"></i>&nbsp;Edit Client
                          </button>
                        </Link>
                        {this.props.user.role_id === 5 ? (
                        <button className="btn btn-link py-0" onClick={() => this.onImpersonatBtnClick(client.client_id)}>
                          <i className="fas fa-retweet"></i>&nbsp;Impersonate Client
                          </button>): null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </React.Fragment>
    :  <Redirect
          to="/"
          name="Home"
        /> );
  }
}
