import React, { Component } from "react";
import axios from "axios";
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { Link } from "react-router-dom";
import Kpi from "../Kpis/Kpi";
import Objective from "./Objective";
import colors from "../assets/colors/colors";
import "./Objectives.scss";
import alertMatrix from "./alertMatrix";


import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

const DragHandle = sortableHandle(() => <span><i class="fa fa-arrows-alt"></i></span>);

export default class Objectives extends Component {
  state = {
    objectives: [],
    kpis: [],
    alertTimeout: 5000,
    timerId: null,
    typeToDelete: "",
    itemToDelete: null,
    alertColor: "",
    alertMessage: "",
    isModalOpen: false,
    isAlertOpen: false,
    toggleSorting: false

  };

  async componentDidMount() {
    const objectivesResponse = await axios.get("/objectives", {
      params: { strategic_plan_id: this.props.selectedPlan.strategic_plan_id }
    });
    const kpisResponse = await axios.get("/kpis", {
      params: { strategic_plan_id: this.props.selectedPlan.strategic_plan_id }
    });
    let menuResponse = await axios.get("/kpis/menus");
    this.setState({
      objectives: objectivesResponse.data,
      kpis: kpisResponse.data,
      menus: menuResponse.data
    });
  }

  getAllKpis() {
    axios.get("/kpis", {
      params: { strategic_plan_id: this.props.selectedPlan.strategic_plan_id }
    }).then(resp => {
      debugger;
      this.setState({
        kpis: resp.data
      });
    });
  }

  startAlertTimer = () => {
    this.resetAlertTimer();
    let timerId = setTimeout(this.alertTimer, this.state.alertTimeout);
    this.setState({ timerId });
  };

  alertTimer = () => {
    this.toggleAlert();
  };

  resetAlertTimer = () => {
    clearTimeout(this.state.timerId);
  };

  setAlert = state => {
    this.setState({
      alertColor: alertMatrix.color[state],
      alertMessage: alertMatrix.getMessage(state)
    });
  };

  toggleAlert = () => {
    this.resetAlertTimer();
    this.setState({ isAlertOpen: !this.state.isAlertOpen });
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  deleteObjectiveHandler = (element_id, child_count) => {
    this.setState({
      itemToDelete:
        this.state.objectives &&
        this.state.objectives.find(o => o.element_id === element_id),
      typeToDelete: "Objective"
    });
    this.setAlert("warning");

    if (child_count > 0) {
      this.startAlertTimer();
      return this.setState({ isAlertOpen: true });
    }

    this.setState({ isModalOpen: true });
  };

  addKpiHandler = () => {
    const kpi = {
      strategic_plan_id: this.props.selectedPlan.strategic_plan_id
    };
    axios
      .post(`/kpis`, kpi)
      .then(res => {
        let nKpi = {
          key_indicator_id: res.data.id,
          kpi_description: "",
          kpi_value: "",
          isNew: true
        };

        //this.setState({ kpis: [...this.state.kpis, nKpi] });
        this.getAllKpis();
      })
      .catch(err => {
        console.log(err);
        alert("Error occurred. Unable to create kpi.");
      });
  };



  onSortEnd = ({ oldIndex, newIndex }) => {

    this.setState(({ objectives }) => ({
      objectives: arrayMove(objectives, oldIndex, newIndex),
    }));

    //UPDATE SORT EVERYTIME
    this.updateSortHandlers();
  };

  onKpiSortEnd = ({ oldIndex, newIndex }) => {

    this.setState(({ kpis }) => ({
      kpis: arrayMove(kpis, oldIndex, newIndex),
    }));

    //UPDATE SORT EVERYTIME
    this.updateKpiSortHandlers();
  };

  updateSortHandlers() {
    let newObjectiveOrder = this.state.objectives.map(function (x) { return x.element_id }).join(',');
    var sortedObj = {
      elementIds: newObjectiveOrder
    };
    axios.post("/objectiveSortOrder", sortedObj).then(x => {
      console.log("Objective order updated successfully!")
    }).catch(err => { console.log(err) });
  }

  updateKpiSortHandlers() {
    let newKpiOrder = this.state.kpis.map(function (x) { return x.key_indicator_id }).join(',');
    var sortedObj = {
      kpiSortOrder: newKpiOrder
    };
    axios.post("/kpiSortOrder", sortedObj).then(x => {
      console.log("kpi order updated successfully!")
    }).catch(err => { console.log(err) });
  }



  deleteKpiHandler = kpiId => {
    axios
      .post(`/deletekpis/${kpiId}`)
      .then(res => {
        this.setState({
          kpis: this.state.kpis.filter(k => k.key_indicator_id !== kpiId)
        });
      })
      .catch(err => {
        console.log(err);
        alert("Error occurred. Unable to delete kpi.");
      });
  };

  updateKpis = () => {

    this.getAllKpis();
  }

  deleteObjectiveFromDb = record => {
    if (!record) return;

    const objectiveId = record.element_id;

    axios
      .post(`/objectives/${objectiveId}`)
      .then(res => {
        this.setState({
          objectives: this.state.objectives.filter(
            o => o.element_id !== objectiveId
          )
        });
      })
      .catch(err => {
        this.setAlert("error");
        this.setState({
          isAlertOpen: true
        });

        console.log(err);
        // alert("Error occurred. Unable to delete objective.");
      });

    this.setState({ isModalOpen: false });
  };
  renderAdminButtons = () => {
    let backgroundPrimary = { backgroundColor: this.props.user.color_primary };

    //ADMIN buttons
    return this.props.user.role_id === 1 ? (
      <div className="my-auto admin-buttons">
        {this.state.toggleSorting && (
          <i
            className="fas fa-check float-right p-1 mr-1 text-white cursor-pointer"
            style={backgroundPrimary}
            onClick={this.sortObjectives}
          ></i>
        )}
        <i
          className={
            this.state.toggleSorting
              ? "fas fa-times float-right p-1 mr-2 text-white cursor-pointer"
              : "fas fa-edit float-right p-1 mr-1 text-white cursor-pointer"
          }
          style={backgroundPrimary}
          onClick={this.state.toggleSorting ? this.onCancel : this.onEditToggle}
        ></i>
      </div>
    ) : null;
  };

  onCancel = () => {
    this.onEditToggle();
  };

  onEditToggle = () => {
    this.setState({ toggleSorting: !this.state.toggleSorting });
  };

  sortObjectives = () => {
    this.onEditToggle();
  }

  render() {
    let color_primary = this.props.user.color_primary || colors.color_primary;
    let color_secondary =
      this.props.user.color_secondary || colors.color_secondary;

    const alert = (
      <Alert
        className="position-fixed animated slideInLeft"
        closeClassName="slideOutLeft"
        color={this.state.alertColor}
        isOpen={this.state.isAlertOpen}
        toggle={this.toggleAlert}
        fade
      >
        {this.state.alertMessage}
      </Alert>
    );

    const modal = (
      <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>Warning</ModalHeader>
        <ModalBody>
          {`This ${this.state.typeToDelete} is about to be permanently deleted. Are you sure?`}
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => this.deleteObjectiveFromDb(this.state.itemToDelete)}
          >
            Yes
          </Button>{" "}
          <Button color="success" onClick={this.toggleModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    );

    const SortableItem = SortableElement(({ value, sortIndex }) =>
      <li sortIndex={sortIndex} element_id={value.element_id} className="objectives-sortable-li">
        {this.state.toggleSorting ? (<DragHandle />) : null}
        <Objective
          {...value}
          {...this.props}
          key={value.element_id}
          deleteObjective={this.deleteObjectiveHandler}
        />
      </li>
    );

    const SortableKpiItem = SortableElement(({ value, sortIndex }) =>
      <li sortIndex={sortIndex} element_id={value.key_indicator_id} className="objectives-sortable-li">
        {this.state.toggleSorting ? (<DragHandle />) : null}
        <Kpi
          {...value}
          {...this.props}
          key={value.kpi_indicator_id}
          menus={this.state.menus}
          deleteKpiHandler={this.deleteKpiHandler}
          kpiHandler={this.updateKpis}
        />
      </li>
    );

    let objectives = this.state.objectives.map(objective => (
      <React.Fragment>
        <Objective
          {...objective}
          {...this.props}
          key={objective.element_id}
          deleteObjective={this.deleteObjectiveHandler}
        />
      </React.Fragment>
    ));


    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul class="objectives-sortable">
          {items
            .map((objective, index) => (
              <SortableItem
                key={`item-${index}`}
                index={index}
                sortIndex={index}
                value={objective}
                pressDelay={1}
              />
            ))}
        </ul>
      );
    });

    const SortableKpiList = SortableContainer(({ items }) => {
      debugger;
      return (

        <ul class="objectives-sortable">
          {items
            .map((kpi, index) => (

              <SortableKpiItem
                key={`item-${index}`}
                index={index}
                sortIndex={index}
                value={kpi}
                pressDelay={1}
              />
            ))}
        </ul>
      );
    });
    return (
      <React.Fragment>
        <React.Fragment>
          <div>{alert}</div>
          <div>{modal}</div>
        </React.Fragment>
        <div className="row mb-3">
          {/*<div className="col-md-3 ml-auto text-uppercase">*/}
          {/*<img src={this.state.client_logo} width="150px"></img>*/}
          {/*</div>*/}
          <div className="col-11">
            <p className="font-primary h4 text-center">
              {this.props.selectedPlan.plan_title}
            </p>
            <h2 className="h1 text-uppercase text-center">Objectives & KPI</h2>
          </div>
          <div className="col-1">
            {this.renderAdminButtons()}
          </div>
        </div>
        <div className="row  objectives">
          <div className="col-sm-8">
            <div className="text-primary font-weight-bold">OBJECTIVES</div>
            <div className="row animated fadeIn delay-3s">
              <div className="col">
                {this.state.objectives.length > 0 ? (
                  this.props.user.role_id === 1 ? (
                    <SortableList items={this.state.objectives} onSortEnd={this.onSortEnd} useDragHandle />) :
                    (objectives)
                ) : (
                  <div className="alert alert-warning">
                    <i className="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;
                    No objectives have been added to this strategic plan
                  </div>
                )}
              </div>
            </div>
            <Link to="/objectives/create">
              <i
                className="fas fa-plus float-right mt-3 p-1 text-white"
                style={{ "background-color": color_primary }}
              ></i>
            </Link>
          </div>
          <div className="col-sm-4">
            <div className="col text-primary font-weight-bold">
              KEY PERFORMANCE INDICATORS
            </div>
            <div className="animated fadeIn delay-1s mt-2">
              <div className="col">
                {this.state.kpis.length > 0 ? (
                  // this.state.kpis.map(kpi => (
                  // <React.Fragment key={kpi.key_indicator_id}>
                  //   <Kpi
                  //     {...kpi}
                  //     {...this.props}
                  //     key={kpi.kpi_indicator_id}
                  //     menus={this.state.menus}
                  //     deleteKpiHandler={this.deleteKpiHandler}
                  //   />
                  // </React.Fragment>

                  // ));
                  <SortableKpiList items={this.state.kpis} onSortEnd={this.onKpiSortEnd} useDragHandle />
                ) : (
                  <div className="alert alert-warning">
                    <i className="fas fa-exclamation-triangle"></i>
                    &nbsp;&nbsp; No key indicators have been added to this
                    strategic plan
                  </div>
                )}
              </div>
            </div>
            <div className="col">
              <i
                style={{ "background-color": color_secondary }}
                className="fas fa-plus float-right mt-3 p-1 text-white cursor-pointer"
                onClick={() => this.addKpiHandler()}
              ></i>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
