let alertMatrix = {
    getMessage: (state) => {
      if (state === 'error') return "There was a problem with your request.";
      return `This objective cannot be deleted if goals have been assigned to it.`;
    },
    color: {
      warning: "warning",
      error: "danger"
    }
  };
  
  export default alertMatrix;