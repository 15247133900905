import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav
} from "@coreui/react";
// sidebar nav config
import navigation from "../_nav";
// routes config
import routes from "../routes";

import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";

export default class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: {}
    };
  }

  selectPlan = plan => {
    this.setState({ selectedPlan: plan });
  };

  render() {
    const filteredItems = navigation.items.filter(
      nav =>
        (!nav.permissions ||
          nav.permissions.find(p => p === this.props.user.role_id)) &&
        (!nav.ownerAdminOnly || this.props.user.client_id === 0)
    );
    const filterNavigation = { items: filteredItems };
          
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader {...this.props} selectPlan={this.selectPlan} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav
              navConfig={filterNavigation}
              {...this.props}
              router={router}
            />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <div className="container-fluid mt-3">
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        <route.component
                          {...props}
                          user={this.props.user}
                          selectedPlan={this.state.selectedPlan}
                          selectPlan={this.selectPlan}
                          onSessionUpdate={this.props.onSessionUpdate}
                          onImpersonateUser={this.props.onImpersonateUser}
                        />
                      )}
                    />
                  ) : null;
                })}
                {this.props.user.role_id === 5 ? <Redirect from="/" to="/clients" /> : <Redirect from="/" to="/plan" />}
              </Switch>
            </div>
          </main>
        </div>
        <AppFooter className="mt-5">
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}
