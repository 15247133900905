import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import axios from "axios";
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { Dropdown } from "./Dropdown";
import "./StrategicPlan.scss";
import logo from "../assets/img/L2G_Logo_draft.png";
import colors from "../assets/colors/colors";

const DragHandle = sortableHandle(() => <span><i class="fa fa-arrows-alt"></i></span>);

export default class StrategicPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorPrimary: props.user.color_primary || colors.primary,
      colorSecondary: props.user.color_secondary || colors.secondary,
      isEditing: false,
      strategic_plan_id: 0,
      plan_title: "",
      plan_begin_date: null,
      show_dropdown: false,
      inputs: {
        belief_statement: "",
        vision: "",
        mission: "",
        core_values: "",
        strengths: "",
        weaknesses: "",
        opportunities: "",
        threats: ""
      },
      inputsUnedited: {
        belief_statement: "",
        vision: "",
        mission: "",
        core_values: "",
        strengths: "",
        weaknesses: "",
        opportunities: "",
        threats: ""
      },
      objectives: [],
      plans: [],
      kpis: []
    };
  }

  toggleDropdown = () => {
    this.setState({ show_dropdown: !this.state.show_dropdown });
  };

  async componentDidMount() {
    const planApi = this.props.selectedPlan.strategic_plan_id
      ? "/plans/" + this.props.selectedPlan.strategic_plan_id
      : "/plans/current";

    const [planResponse, plansResponse] = await Promise.all([
      axios.get(planApi),
      axios.get("/plans")
    ]);

    this.loadPlan(planResponse.data);

    this.setState({
      plans: plansResponse.data
    });
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.selectedPlan.strategic_plan_id !==
      prevProps.selectedPlan.strategic_plan_id
    ) {
      const planResponse = await axios.get(
        "/plans/" + this.props.selectedPlan.strategic_plan_id
      );

      this.loadPlan(planResponse.data);
    }
  }

  async loadPlan(data) {
    const [objectivesResponse, kpisResponse] = await Promise.all([
      axios.get("objectives", {
        params: { strategic_plan_id: data.strategic_plan_id }
      }),
      axios.get("kpis", {
        params: { strategic_plan_id: data.strategic_plan_id }
      })
    ]);

    const {
      belief_statement,
      strategic_plan_id,
      plan_title,
      plan_begin_date,
      vision,
      mission,
      core_values,
      strengths,
      weaknesses,
      opportunities,
      threats
    } = data;
    const editableFields = {
      belief_statement,
      vision,
      mission,
      core_values,
      strengths,
      weaknesses,
      opportunities,
      threats
    };

    this.props.selectPlan(data);
    this.setState({
      strategic_plan_id,
      plan_title,
      plan_begin_date,
      inputs: { ...editableFields },
      inputsUnedited: { ...editableFields },
      objectives: objectivesResponse.data,
      kpis: kpisResponse.data
    });
  }

  onEditToggle = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  onCancel = () => {
    let valuesToReset = {};

    Object.keys(this.state.inputs).forEach(key => {
      const value = this.state.inputsUnedited[key];
      if (value !== this.state.inputs[key]) {
        valuesToReset[key] = value;
      }
    });

    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        ...valuesToReset
      }
    }));
    this.onEditToggle();
  };

  onSave = () => {
    let valuesToSave = {};

    Object.keys(this.state.inputs).forEach(key => {
      const value = this.state.inputs[key];
      if (value !== this.state.inputsUnedited[key]) {
        valuesToSave[key] = value;
      }
    });

    axios
      .put("/plans/" + this.state.strategic_plan_id, this.state.inputs)
      .then(() => {
        this.setState(prevState => ({
          inputsUnedited: {
            ...prevState.inputs,
            ...valuesToSave
          }
        }));
        this.onEditToggle();
      })
      .catch(error => {
        // To do: Add better messaging to the UI.
        alert("There was a problem saving. Please try again.");
      });
  };

  onInputChange = (value, prop) => {
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [prop]: value
      }
    }));
  };

  renderInputText = prop => {
    if (this.state.isEditing) {
      return (
        <textarea
          className="textarea"
          value={this.state.inputs[prop] || ""}
          onChange={e => this.onInputChange(e.target.value, prop)}
        />
      );
    }

    return <p className="mb-0">{this.state.inputs[prop]}</p>;
  };

  renderAdminButtons = () => {
    let backgroundPrimary = { backgroundColor: this.state.colorPrimary };

    return this.props.user.role_id === 1 ? (
      <div className="my-auto admin-buttons">
        {this.state.isEditing && (
          <i
            className="fas fa-check float-right p-1 mr-1 text-white cursor-pointer"
            style={backgroundPrimary}
            onClick={this.onSave}
          ></i>
        )}
        <i
          className={
            this.state.isEditing
              ? "fas fa-times float-right p-1 mr-2 text-white cursor-pointer"
              : "fas fa-edit float-right p-1 mr-1 text-white cursor-pointer"
          }
          style={backgroundPrimary}
          onClick={this.state.isEditing ? this.onCancel : this.onEditToggle}
        ></i>
        {!this.state.isEditing && (
          <Link to={"/plan/create"} className="my-auto">
            <i
              className="fas fa-plus float-right p-1 mr-2 text-white"
              style={backgroundPrimary}
            ></i>
          </Link>
        )}
      </div>
    ) : null;
  };

  renderTooltip = (objective, i) => {
    return (
      <UncontrolledTooltip placement="bottom" target={`objective_${i}`}>
        {<p>{objective.resources}</p>}
      </UncontrolledTooltip>
    );

  };
  onSortStart = ({ oldIndex, newIndex }) => {
    
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ objectives }) => ({
      objectives: arrayMove(objectives, oldIndex, newIndex),
    }));

  

    //UPDATE SORT EVERYTIME
    this.updateSortHandlers();
  };

  onSortKPIEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ kpis }) => ({
      kpis: arrayMove(kpis, oldIndex, newIndex),
    }));

    this.updateKpiSortHandlers();
  };
  

 updateSortHandlers(){
   let newObjectiveOrder = this.state.objectives.map(function(x){ return x.element_id }).join(',');
  var sortedObj = {
    elementIds : newObjectiveOrder
  };
  axios.post("/objectiveSortOrder",sortedObj).then(x=>{
    console.log("Objective order updated successfully!")
  }).catch(err=>{console.log(err)});
 }

 updateKpiSortHandlers() {
  let newKpiOrder = this.state.kpis.map(function (x) { return x.key_indicator_id }).join(',');
  var sortedObj = {
    kpiSortOrder: newKpiOrder
  };
  axios.post("/kpiSortOrder", sortedObj).then(x => {
    console.log("kpi order updated successfully!")
  }).catch(err => { console.log(err) });
}


 renderObjectives = backgroundPrimary => {
  return this.state.objectives.map((objective, i) => (
    <div
      className="shadow py-2 px-3 mb-2 d-flex justify-content-center  strategic-plan-objective"
      id={`objective_${i}`}
      key={i}
      style={backgroundPrimary}
    >
      <h3 className="my-auto text-uppercase text-white text-center h5  strategic-plan-objective-text tracking-wide">
        {objective.description}
      </h3>
      {objective.resources && this.renderTooltip(objective, i)}
    </div>
  ));
};


  renderKpis = textPrimary => {
    const kpis = this.state.kpis.map((kpi, i) => (
      <div
        className="shadow py-2 px-3 mb-2 d-flex justify-content-center  strategic-plan-objective"
        id={`kpi_${i}`}
        key={i}
        style={textPrimary}
      >
        <h3 className="my-auto text-center h5 strategic-plan-objective-text">
          <span className="text-uppercase">{kpi.kpi_description}</span>:
          <br />
          {kpi.kpi_value}
        </h3>
      </div>
    ));

    return (
      this.state.kpis.length > 0 && <div className="row mb-4 mx-0">{kpis}</div>
    );
  };


  renderBelief = () => {
    const beliefStatement = this.state.inputsUnedited.belief_statement;

    return beliefStatement || this.state.isEditing ? (
      <div className="row shadow bg-light py-2 px-3 mb-4 mx-0">
        {this.state.isEditing && (
          <h5 className="mt-2 text-uppercase">Belief Statement</h5>
        )}
        <h3 className="my-0 font-italic text-center h4 w-100  belief-container">
          {this.renderInputText("belief_statement")}
        </h3>
      </div>
    ) : null;
  };

  render() {
    const SortableItem = SortableElement(({ value, sortIndex, backgroundPrimary}) =>
      <li sortIndex={sortIndex} className="strategic-plan-objective">
         <DragHandle />
        <div
          className="shadow py-2 px-3 mb-2 d-flex justify-content-center objwidth"
          id={`objective_${sortIndex}`}
          key={sortIndex}
          style={backgroundPrimary}
        >
          <h3 className="my-auto text-uppercase text-white text-center h5  strategic-plan-objective-text tracking-wide">
            {value.description}
          </h3>
          {value.resources && this.renderTooltip(value, sortIndex)}
        </div>
      </li>
    );

    const SortableKpisItem = SortableElement(({ value, sortIndex, textPrimary}) =>
      <li sortIndex={sortIndex} className="strategic-plan-objective">
         <DragHandle />
        <div
          className="shadow py-2 px-3 mb-2 d-flex justify-content-center objwidth"
          id={`kpis_${sortIndex}`}
          key={sortIndex}
          style={textPrimary}
        >
          <h3 className="my-auto text-center h5 strategic-plan-objective-text">
            <span className="text-uppercase">{value.kpi_description}</span>:
            <br />
            {value.kpi_value}
          </h3>
        </div>
      </li>
    );


    const SortableKpisList = SortableContainer(({ items , textPrimary }) => {
      return (
        <ul className="kpis-sortable">
          {items.map((kpis, index) => (
            <SortableKpisItem
              key={`item-${index}`}
              index={index}
              sortIndex={index}
              value={kpis}
              pressDelay={1}
              textPrimary={textPrimary}
            />
          ))}
        </ul>
      );
    });

    const SortableList = SortableContainer(({ items , backgroundColor }) => {
      return (
        <ul className="objective-sortable">
          {items.map((objective, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              sortIndex={index}
              value={objective}
              pressDelay={1}
              backgroundPrimary={backgroundColor}
            />
          ))}
        </ul>
      );
    });



    let backgroundPrimary = { backgroundColor: this.state.colorPrimary };
    let textPrimary = { color: this.state.colorPrimary };
    let backgroundSecondary = { backgroundColor: this.state.colorSecondary };
    let vmcStyle = {
      fontSize: (this.props.user.vmc_font_size || "15") + "pt",
      fontFamily: this.props.user.vmc_font_type || "Open Sans",
      color: this.props.user.vmc_font_color || "#fff"
    };
    let swotStyle = {
      fontSize: (this.props.user.swot_font_size || "10.5") + "pt",
      fontFamily: this.props.user.swot_font_type || "Open Sans",
      color: this.props.user.swot_font_color || "#2f353a"
    };

    return ( this.props.user.role_id !== 5 ?
      <div className="strategic-plan animated fadeInDown">
        <div className="row mb-3 admin-row">
          <div className="my-auto logo">
            <img src={this.props.user.client_logo || logo} />
          </div>
          <Dropdown
            plan_title={this.state.plan_title}
            plans={this.state.plans}
            toggleDropdown={this.toggleDropdown}
            show_dropdown={this.state.show_dropdown}
            selectPlan={this.props.selectPlan}
          />
          {this.renderAdminButtons()}
        </div>

        {this.renderBelief()}

        
        {this.props.user.role_id === 1 && this.state.isEditing === true ? (
        <div className="row mb-4 mx-0">
          <SortableKpisList
            items={this.state.kpis}
            onSortEnd={this.onSortKPIEnd}
            backgroundColor={textPrimary}
            useDragHandle
            lockAxis="x"
            axis="x" />
        </div>
        ) : (this.renderKpis(textPrimary))}
        
        
        <div className="row mb-4 mx-0">
          {/* CLINET_ID = 1 IS SUPER ADMIN */}
          {this.props.user.role_id === 1 && this.state.isEditing === true ? (

            <SortableList
              items={this.state.objectives}
              onSortEnd={this.onSortEnd}
              onSortStart={this.onSortStart}
              backgroundColor={backgroundPrimary}
              useDragHandle
              lockAxis="x"
              axis="x" />
          ) : (this.renderObjectives(backgroundPrimary))}
        </div>
        
        

        <div className="row mb-2 mx-1">
          <div className="shadow mb-3 d-flex vision strategic-plan-primary">
            <div
              className="p-3 w-100 overlay"
              style={{ ...backgroundSecondary, ...vmcStyle }}
            >
              <h3
                className="h4 text-uppercase font-weight-bold  tracking-wide"
                style={{ ...vmcStyle }}
              >
                Vision
              </h3>
              <div className="d-flex strategic-plan-section pt-1">
                {this.renderInputText("vision")}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4 mx-1">
          <div className="shadow mb-3 d-flex mission strategic-plan-primary">
            <div
              className="p-3 w-100 overlay"
              style={{ ...backgroundSecondary, ...vmcStyle }}
            >
              <h3
                className="h4 text-uppercase font-weight-bold  tracking-wide"
                style={{ ...vmcStyle }}
              >
                Mission
              </h3>
              <div className="d-flex strategic-plan-section pt-1">
                {this.renderInputText("mission")}
              </div>
            </div>
          </div>

          <div className="shadow mb-3 d-flex values strategic-plan-primary">
            <div
              className="p-3 w-100 overlay"
              style={{ ...backgroundSecondary, ...vmcStyle }}
            >
              <h3
                className="h4 text-uppercase font-weight-bold  tracking-wide"
                style={{ ...vmcStyle }}
              >
                Core Values
              </h3>
              <div className="d-flex strategic-plan-section pt-1 white-space-pre">
                {this.renderInputText("core_values")}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4 mx-2">
          <div className="bg-light shadow p-0 mb-3 strategic-plan-secondary">
            <div
              className="text-white text-center py-2"
              style={backgroundPrimary}
            >
              <h3 className="my-auto h5 text-uppercase text-white tracking-wide">
                Strengths
              </h3>
            </div>
            <div className="d-flex strategic-plan-swot p-3" style={swotStyle}>
              {this.renderInputText("strengths")}
            </div>
          </div>
          <div className="bg-light shadow p-0 mb-3  strategic-plan-secondary">
            <div
              className="text-white text-center py-2"
              style={backgroundPrimary}
            >
              <h3 className="my-auto h5 text-uppercase text-white tracking-wide" >
                Weaknesses
              </h3>
            </div>
            <div
              className="d-flex strategic-plan-swot bg-light p-3"
              style={swotStyle}
            >
              {this.renderInputText("weaknesses")}
            </div>
          </div>
          <div className="bg-light shadow p-0 mb-3 strategic-plan-secondary">
            <div
              className="text-white text-center py-2"
              style={backgroundPrimary}
            >
              <h3 className="my-auto h5 text-uppercase  text-white  tracking-wide">
                Opportunities
              </h3>
            </div>
            <div className="d-flex strategic-plan-swot p-3" style={swotStyle}>
              {this.renderInputText("opportunities")}
            </div>
          </div>
          <div className="bg-light shadow p-0 mb-3  strategic-plan-secondary">
            <div
              className="text-center py-2"
              style={backgroundPrimary}
            >
              <h3 className=" my-auto h5 text-uppercase text-white  tracking-wide">
                Threats
              </h3>
            </div>
            <div className="d-flex strategic-plan-swot p-3" style={swotStyle}>
              {this.renderInputText("threats")}
            </div>
          </div>
        </div>
      </div>
     :  <Redirect
          to="/clients"
          name="Clients"
   /> );
  }
}
