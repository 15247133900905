import colors from "../assets/colors/colors";
import styled from "styled-components";

export const StyledButtonOutline = styled.button`
  && {
    color: ${props =>
      props.color || (props.primary ? colors.primary : colors.secondary)};
    border-color: ${props =>
      props.color || (props.primary ? colors.primary : colors.secondary)};
  }

  &&.active:not(:disabled),
  &&:hover:not(:disabled) {
    color: white;
    background-color: ${props =>
      props.color || (props.primary ? colors.primary : colors.secondary)};
    border-color: ${props =>
      props.color || (props.primary ? colors.primary : colors.secondary)};
  }

  &&:disabled {
    color: ${props =>
      props.color || (props.primary ? colors.primary : colors.secondary)};
    background-color: transparent;
  }
`;
