import React, { Component } from "react";
import NumberFormat from "react-number-format";
import "./RagInput.scss";



export default class RagInput extends Component {
  getRagBgClass(ragStatus) {
    if (ragStatus === "G") {
      return "success";
    } else if (ragStatus === "A") {
      return "warning";
    } else if (ragStatus === "R") {
      return "danger";
    }
    return "";
  }

  percentChangeHandler = value => {
    const percent = parseInt(value);

    if (!percent) {
      this.props.onPercentChange(0);
    }

    if (percent >= 0 && percent <= 100) {
      this.props.onPercentChange(percent);
    }
  };

  renderInput = () => {
    if (!this.props.percentInput) return;

    return !this.props.disabled ? (
      <>
        <input
          className="my-0 form-control-plaintext  input input-toggled"
          placeholder={`${this.props.percent_complete}`}
          onChange={e => this.percentChangeHandler(e.target.value)}
        />
        <span className="percent">%</span>
      </>
    ) : (
      <>
        <p className="form-control-plaintext percent-readonly">
          {this.props.percent_complete} %
        </p>
      </>
    );
  };

  render() {
    const background = this.getRagBgClass(this.props.rag_status);
    const inputClasses =
      "form-control" + this.props.rag_status + " bg-" + background;
    const badgeClasses =
      "badge badge-pill" + this.props.rag_status + " badge-" + background;
    const tooltip = this.props.disabled ? "Rag Status" : "Percent Complete";
    const isBlank = this.props.rag_status === "W";

    return (
      <div
        className={`rag align-items-center d-flex ${!this.props.percentInput &&
          "mr"}`}
      >
        <div
          className={`traffic-light ${this.props.rag_status} mr-2`}
          title={tooltip}
        >
          <div className="top"></div>
          <div className="middle"></div>
          <div className="bottom"></div>
        </div>
        {this.renderInput()}
      </div>
    );
  }
}
