import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

export default function ChampionSelect({
  selectedUserId,
  users,
  onUserChange,
  disabled
}) {
  let selectedUserName;
  let selectedUser = users.find(user => user.user_id === selectedUserId);

  if (selectedUser) {
    selectedUserName = selectedUser.first_name + " " + selectedUser.last_name;
  }

  return (
    <UncontrolledDropdown className="d-inline">
      <DropdownToggle
        className="rounded-0 py-0 mr-1 shadow  expand-collapse"
        disabled={disabled}
        color="body"
        caret
        
      >
        {selectedUserName || "Champion"}
      </DropdownToggle>
      <DropdownMenu style={{maxHeight:"300px",overflow:"auto"}}>
        {users.map(user => (
          <DropdownItem
            active={user.user_id === selectedUserId}
            onClick={() => onUserChange(user)}
           
          >
            {user.first_name + " " + user.last_name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
