import React from "react";
import { StyledButtonOutline } from "../utils/StyledButton";

export default function TimelineToggle(props) {
  const buttonClasses = ["btn", "rounded-0", "py-0"];
  const buttonColor = props.color;

  return (
    <React.Fragment>
      <StyledButtonOutline
        color={buttonColor}
        className={[...buttonClasses, props.showTimeline ? "" : "active"].join(
          " "
        )}
        onClick={() => props.onToggleTimeline(false)}
      >
        Goals
      </StyledButtonOutline>
      <StyledButtonOutline
        color={buttonColor}
        className={[...buttonClasses, props.showTimeline ? "active" : ""].join(
          " "
        )}
        onClick={() => props.onToggleTimeline(true)}
      >
        Timeline
      </StyledButtonOutline>
    </React.Fragment>
  );
}
