import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import axios from "axios";

import AppLayout from "./layout/AppLayout";
import Login from "./Login/Login";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ForgotPassword/ResetPassword";
import "./App.scss";


class App extends Component {
  state = {
    user:
      process.env.NODE_ENV === "development"
        ? {
          user_id: "5",
          first_name: "Dev",
          last_name: "Mode",
          username: "test",
          role_id: 1,
          client_id: 1,
          department_id: 1,
          department_name: "Development",
          color_primary: "#112945",
          color_secondary: "#fe6700",
          client_logo: null,
          vmc_font_type: "Open Sans",
          vmc_font_color: "#fff",
          vmc_font_size: "15pt",
          swot_font_type: "Fjalla One",
          swot_font_color: "#112945",
          swot_font_size: "10.5pt"
        }
        : null,
    data: null,
    errorMessage: null
  };

  componentDidMount() {
    this.onSessionUpdateHandler();
  }

  onLoginHandler = login => {

    // debugger;
    // if (this.state.user != null && this.state.user.imporsonatedByUserID > 0) {
    //   this.onImpersonateUser(this.user.user_id);
    //   return;
    // }

    this.setState({ errorMessage: null });
    axios
      .post("login", login)
      .then(res => {

        this.setState({ user: res.data.session }, () => {
          if (this.state.user.vmc_font_type) {
            //Load fonts from Google font api
            axios.get(
              "https://fonts.googleapis.com/css?family=" +
              this.state.user.vmc_font_type
            );
          }
          if (this.state.user.swot_font_type) {
            axios.get(
              "https://fonts.googleapis.com/css?family=" +
              this.state.user.swot_font_type
            );
          }
        });
      })
      .catch(error => {
        this.setState({ errorMessage: error.response.data });
      });
  };
  onForgotPasswordHandler = forgotpassword => {
    this.setState({ errorMessage: null });
    axios
      .post("forgotpassword", forgotpassword)
      .then(res => {

        if (res.status == 200 && res.data == "SUCCESS") {
          this.setState({ successMessage: "Please check your email and confirm your reset password request" });
        }
        else if (res.status == 200 && res.data == "ERROR") {
          this.setState({ errorMessage: "Account does not exists. Please enter valid User Name" });
        }
      })
      .catch(error => {
        this.setState({ errorMessage: error.response.data });
      });
  };

  onLogoutHandler = () => {
    axios
      .post("logout")
      .then(res => this.setState({ user: null }))
      .catch(err => alert("Error occurred while logging out"));
  };

  onImpersonateUser = (sessiondata) => {
    this.setState({ user: sessiondata }, () => {
      if (this.state.user.vmc_font_type) {
        //Load fonts from Google font api
        axios.get(
          "https://fonts.googleapis.com/css?family=" +
          this.state.user.vmc_font_type
        );
      }
      if (this.state.user.swot_font_type) {
        axios.get(
          "https://fonts.googleapis.com/css?family=" +
          this.state.user.swot_font_type
        );
      }
    });
  }

  onImpersonateLogout = (sessiondata) => {
    this.setState({ user: sessiondata }, () => {
      if (this.state.user.vmc_font_type) {
        //Load fonts from Google font api
        axios.get(
          "https://fonts.googleapis.com/css?family=" +
          this.state.user.vmc_font_type
        );
      }
      if (this.state.user.swot_font_type) {
        axios.get(
          "https://fonts.googleapis.com/css?family=" +
          this.state.user.swot_font_type
        );
      }

    });
  }

  onSessionUpdateHandler = () => {
    axios
      .get("login")
      .then(res => {
        this.setState({ user: res.data.session }, () => {
          //Load fonts from Google font api
          axios.get(
            "https://fonts.googleapis.com/css?family=" +
            this.state.user.vmc_font_type
          );
          axios.get(
            "https://fonts.googleapis.com/css?family=" +
            this.state.user.swot_font_type
          );
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ user: null });
      });
  };



  render() {
    let redirect = null;

    if (this.state.user) {
      redirect = <Redirect to="/" />;
    }

    return (
      <HashRouter>
        {redirect}
        <Switch>

          {!this.state.user ? (
            <Route
              path="/login"
              name="Login"
              render={props => (
                <Login
                  onLogin={this.onLoginHandler}
                  {...props}
                  errorMessage={this.state.errorMessage}
                />
              )}
            />
          ) : null}
          {this.state.user ? (
            <Route
              path="/"
              name="Home"
              render={props => (
                <AppLayout
                  user={this.state.user}
                  onLogout={this.onLogoutHandler}
                  onSessionUpdate={this.onSessionUpdateHandler}
                  onImpersonateUser={this.onImpersonateUser}
                  onImpersonateLogout={this.onImpersonateLogout}
                  {...props}
                />
              )}
            />
          ) : null}
          <Route path="/forgotpassword">
            <ForgotPassword onForgotPassword={this.onForgotPasswordHandler} errorMessage={this.state.errorMessage} successMessage={this.state.successMessage} />
          </Route>
          <Route path="/resetpassword/:id"
            render={props =>
              <ResetPassword  {...props} />
            }>
          </Route>
          <Redirect from="/" to="/login" />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
