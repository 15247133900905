/**
 * Toggle collapse state of child element by id.
 * @param {number} id - element_id of child.
 */
export function childToggleHandler(id) {
  this.setState(prevState => ({
    childElementsCollapse: {
      ...prevState.childElementsCollapse,
      [id]: !this.state.childElementsCollapse[id]
    }
  }))
};

/**
 * Add child element id to childElementsCollapse object and set to false.
 * @param {number} id - element_id of child.
 */
export function childAddHandler (id) {
  this.setState(prevState => ({
    childElementsCollapse: {
      ...prevState.childElementsCollapse,
      [id]: false
    }
  }))
};

/**
 * Generate an object with keys of child element_id's and values of collapse
 * state. Defaults to true.
 * @param {*} childElements
 */
export let generateChildElementsCollapse = childElements => {
  return childElements.reduce((childIds, child) => {
    childIds[child.element_id] = true;
    return childIds;
  }, {});
}

/**
 * Generate an array of child element_id's.
 * @param {*} childElements
 */
export let generateChildElementIds = childElements => {
  return childElements.map(childElement => {
    return childElement.element_id
  });
}
