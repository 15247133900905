import React, { Component } from "react";
import axios from "axios";
import { FrappeGantt } from "frappe-gantt-react";

import "./Timeline.scss";
import { Spin } from "antd";


var secondaryColor = '';
var primaryColor = '';

export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tasks: [],
      tasksOriginal: [],
      showGoals: true,
      showStrategies: true,
      showActions: true,
      loading: false
    };
    primaryColor = props.primaryColor;
    secondaryColor = props.secondaryColor;
  }

  componentDidMount() {
    this.getTimelineElements();
  }

  componentDidUpdate(prevProps,nextState) {
    if (
      this.props.quarter !== prevProps.quarter ||
      this.props.year !== prevProps.year ||
      this.props.department_id !== prevProps.department_id
    ) {
      this.setState({ isLoading: true }, () => this.getTimelineElements());
    }
    
  }


  chartDataProcess() {
    
    if (window.document.querySelectorAll(".js-gantt-label-container text").length > 0) {
      window.document.querySelectorAll(".js-gantt-label-container")[0].classList.add("custom-gantt-label-container");
      window.document.querySelectorAll(".js-gantt-label-container")[0].style.height = window.document.querySelectorAll(".gantt-container")[0].offsetHeight;

      window.document.querySelectorAll(".loader")[0].style.display = "block";

      let elements = window.document.querySelectorAll(".js-gantt-label-container text");
      elements.forEach((element) => {
        
        var elementText = element.innerHTML;
        if (elementText.indexOf('G:') > -1) {
          elementText = elementText.replace("G:", "");
          element.style["font-weight"] = '700';
          element.style.color = primaryColor;
        } else if (elementText.indexOf('S:') > -1) {
          var xValue = element.getAttribute('x');
          xValue = parseInt(xValue) + 10;
          element.setAttribute('x', xValue);
          element.style.color = primaryColor;
          elementText = elementText.replace("S:", "");
        } else if (elementText.indexOf('A:') > -1) {
          var xValue = element.getAttribute('x');
          xValue = parseInt(xValue) + 20;
          element.setAttribute('x', xValue);
          element.style.color = "#000000";
          elementText = elementText.replace("A:", "");
        }
        element.innerHTML = elementText;
      });
      
    }
    if(window.document.querySelectorAll(".loader")[0] != undefined)
    {
      window.document.querySelectorAll(".loader")[0].style.display = "none";
    }
  }

  chartProgress() {
    setTimeout(() => {
      this.chartDataProcess();
      
        
    }, 300);
  }

  getTimelineElements() {
    
    const quarter = this.props.quarter;
    const year = this.props.year;
    let prefixMap = {
      1: "A:",
      2: "S:",
      3: "G:"
    };

    let endpointUrl = `/elements/timeline?userId=${this.props.userId}`;
    
    if (year && quarter) {
      
      if(quarter == -1){        
        const startMonth = 1;
        const endMonth = 12;
        const endDate = new Date(year, endMonth, 0);
        const startString = `&startDate=${year}-${startMonth}-01`;
        const endString = `&endDate=${year}-${endMonth}-${endDate.getDate()}`;
        endpointUrl = endpointUrl.concat(startString).concat(endString);
      }
      else{
        const startMonth = quarter * 3 - 3;
        const endMonth = startMonth + 3;
        const endDate = new Date(year, endMonth, 0);
        const startString = `&startDate=${year}-${startMonth + 1}-01`;
        const endString = `&endDate=${year}-${endMonth}-${endDate.getDate()}`;
        endpointUrl = endpointUrl.concat(startString).concat(endString);
      }
    }
    
    axios
      .get(endpointUrl, {
        params: { strategic_plan_id: this.props.strategic_plan_id }
      })
      .then(res => {
        const validElements = res.data.filter(
          el => el.start_date_time && el.end_date_time
        );

        const tasks = validElements.map(el => {
          return {
            id: "element" + el.element_id,
            level_id: el.level_id,
            name: `${prefixMap[el.level_id]} ${el.description} ${el.level_id === 1 ? `${el.percent_complete}%` : ""
              }`,
            start: el.start_date_time,
            end: el.end_date_time,
            progress: el.percent_complete,
            dependencies: "",
            custom_class: `gantt-rag-${el.rag_status}`
          };
        });
        if (tasks.length == 0) {
          var sample = {
            level_id: 1,
            progress: 0,
            start: new Date().toISOString().substr(0, 10),
            end: new Date().toISOString().substr(0, 10),
            name: " ",
            id: 'Task 0', // to delete chart bar
            dependencies: "",
            custom_class: "sample-task"
          };
          tasks.push(sample);
        }

        this.setState({ isLoading: false, tasksOriginal: tasks, loading: false }, () =>
          this.handleElementFilterChange()
        );
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleElementFilterChange() {
    const filteredTasks = this.state.tasksOriginal.filter(
      task =>
        (this.state.showGoals && task.level_id === 3) ||
        (this.state.showStrategies && task.level_id === 2) ||
        (this.state.showActions && task.level_id === 1)
    );
    this.setState({ tasks: filteredTasks });
    
    if (window.document.querySelectorAll(".loader")[0] != undefined) {
      window.document.querySelectorAll(".loader")[0].style.display = "block";
    }
  }

  render() {
    const checkboxDisplay = (
      <form className="display-filter-form">
        <div className="row justify-content-center mx-auto mb-2">
          <div className="col-auto">
            <label className="position-relative" htmlFor="showGoals">
              Goals
              <input
                className="position-absolute"
                id="showGoals"
                name="showGoals"
                type="checkbox"
                defaultChecked={this.state.showGoals}
                onChange={() => {
                  this.setState({ showGoals: !this.state.showGoals, loading: true }, () => {
                    this.handleElementFilterChange();
                  });
                }}
              />
              <i
                className={`ml-2 font-lg fas ${this.state.showGoals ? "fa-check-square" : "fa-square"
                  } `}
                aria-hidden="true"
              ></i>
            </label>
          </div>
          <div className="col-auto">
            <label className="position-relative" htmlFor="showStrategies">
              Strategies
              <input
                className="position-absolute"
                id="showStrategies"
                name="showStrategies"
                type="checkbox"
                defaultChecked={this.state.showStrategies}
                onChange={() => {
                  this.setState(
                    { showStrategies: !this.state.showStrategies },
                    () => {
                      this.handleElementFilterChange();
                    }
                  );
                }}
              />
              <i
                className={`ml-2 font-lg fas ${this.state.showStrategies ? "fa-check-square" : "fa-square"
                  } `}
                aria-hidden="true"
              ></i>
            </label>
          </div>
          <div className="col-auto">
            <label className="position-relative" htmlFor="showActions">
              Actions
              <input
                className="position-absolute"
                id="showActions"
                name="showActions"
                type="checkbox"
                defaultChecked={this.state.showActions}
                onChange={() => {
                  this.setState(
                    { showActions: !this.state.showActions, loading: true },
                    () => {
                      this.handleElementFilterChange();
                    }
                  );
                }}
              />
              <i
                className={`ml-2 font-lg fas ${this.state.showActions ? "fa-check-square" : "fa-square"
                  } `}
                aria-hidden="true"
              ></i>
            </label>
          </div>
        </div>
      </form>
    );

    const chart = (
      <div className="shadow rounded" id="divToPDF">
          <FrappeGantt
            tasks={this.state.tasks}
            viewMode="Month"
            onClick={task => console.log(task)}
            onDateChange={(task, start, end) => console.log(task, start, end)}
           onTasksChange={this.chartProgress()}
          />
          <div className="loader"><div className="loader-icon"></div></div>
      </div>
    );
    return (
      <React.Fragment>
        {checkboxDisplay}
        {!this.state.isLoading && (
          <div className="animated fadeIn">
            {this.state.tasks && this.state.tasks.length > 0 ? (
              <React.Fragment>
                {chart}
              </React.Fragment>
            ) : (
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <div className="alert alert-warning">
                      <i className="fas fa-exclamation-triangle"></i>
                      <span className="ml-2">
                        {this.state.showGoals ||
                          this.state.showStrategies ||
                          this.state.showActions
                          ? "No records found for the specified filter criteria"
                          : "Please filter by Goals, Strategies, or Actions"}
                      </span>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
