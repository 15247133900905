import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";

import logo from "../assets/img/L2G_Logo_draft.png";
import colors from "../assets/colors/colors";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      errorMessage: props.errorMessage,
      successMessage:props.successMessage
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage !== prevProps.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
    if (this.props.successMessage !== prevProps.successMessage) {
        this.setState({ successMessage: this.props.successMessage });
      }
  }

  forgotPasswordClickedHandler = () => {
    if (this.state.username) {
      this.setState({ errorMessage: null,successMessage : null });
      this.props.onForgotPassword({
        username: this.state.username
      });
  
    } else {
      this.setState({ errorMessage: "Please enter a username" });
    }
  };

  keyUpHandler = event => {
    // Check for enter key
    if (event.keyCode === 13) {
      event.preventDefault();
      this.forgotPasswordClickedHandler();
    }
  };

  render() {
    let textSecondary = { color: colors.secondary };
    let errorAlert = null;
    if (this.state.errorMessage) {
      errorAlert = (
        <Row className="mt-3 mb-0">
          <Col>
            <Alert color="danger" className="mb-0">
              {this.state.errorMessage}
            </Alert>
          </Col>
        </Row>
      );
    }
    let successAlert = null;
    if(this.state.successMessage){
        successAlert = (
            <Row className="mt-3 mb-0">
              <Col>
                <Alert color="success" className="mb-0">
                  {this.state.successMessage}
                </Alert>
              </Col>
            </Row>
          );
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <img src={logo} alt="Lead2Goals" />
                      <p className="text-muted">Forgot Password</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                          onChange={event =>
                            this.setState({ username: event.target.value })
                          }
                          onKeyUp={this.keyUpHandler}
                          maxLength="250"
                        />
                      </InputGroup>
                      
                      <Row className="mt-5"> 
                        <Col xs="6">
                          <Button
                            type="button"
                            color="primary"
                            className="px-4 py-2"
                            onClick={this.forgotPasswordClickedHandler}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    {errorAlert}
                    {successAlert}
                  </CardBody>
                </Card>
                <Card
                  className="text-white py-5 d-md-down-none"
                  style={{ width: "44%", backgroundColor: colors.primary }}
                >
                  <CardBody className="text-center">
                    <div>
                      <h1>Forgot Password</h1>
                      <p>
                        Enter the username assciated with you account to Reset password.
                      </p>
                      <p>
                        This system is currently in beta v
                        {process.env.REACT_APP_VERSION}
                      </p>
                      <p>
                        <a style={textSecondary} href="https://lead2goals.com">
                          Lead2Goals.com
                        </a>
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ForgotPassword;
