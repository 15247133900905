import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import InputColor from "react-input-color";
import { SwatchesPicker } from "react-color";
import { SketchPicker } from "react-color";
import FontPicker from "font-picker-react";

export default function EditFonts(props) {
  return (
    <React.Fragment>
      <div>
        <label htmlFor="activeFontFamily" className="col-form-label">
          Font Type
        </label>
      </div>
      <div>
        <FontPicker
          pickerId={props.groupName}
          apiKey="AIzaSyApVihdlJlX2mEGCXnaykFhi1r6U_Rbnqg"
          activeFontFamily={props.activeFontFamily}
          onChange={nextFont => {
            props.fontTypeChanged(nextFont.family);
          }}
        />
        <p className={"apply-font-" + props.groupName}>
          The font will be applied to this text.
        </p>
      </div>
      <div>
        <label htmlFor="font_color" className="col-form-label mr-3">
          Color
        </label>
        {!props.editMode ? (
          <a onClick={() => props.toggleEditMode()}>
            <InputColor
              initialHexColor={props.font_color}
              onChange={color => console.debug(color)}
              placement="right"
            />
            <span> </span>
            <i className="fas fa-edit"></i>
          </a>
        ) : (
          <React.Fragment>
            <a onClick={() => props.toggleEditMode()}>
              <i className="fas fa-check"></i>
            </a>
            <SketchPicker
              className="mt-sm-4"
              color={props.font_color}
              onChangeComplete={({ hex }) => props.fontColorChanged(hex)}
            />
          </React.Fragment>
        )}
      </div>
      <div class="form-inline">
        <label htmlFor="font_size" className="col-form-label mr-3">
          Size (pt)
        </label>
        <input
          type="text"
          className="form-control d-inline w-25"
          placeholder="font size"
          id="font_size"
          value={props.font_size ?? 20}
          onChange={event => {
            const re = /^[0-9\b]+$/;
            if (event.target.value === "" || re.test(event.target.value)) {
              props.fontSizeChanged(event.target.value);
            }
          }}
        />
      </div>
      <div className="input-group"></div>
    </React.Fragment>
  );
}
