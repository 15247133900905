import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

export default function YearFilter({
  color,
  selectedYear,
  years,
  onYearChanged
}) {
  return (
    <UncontrolledDropdown className="d-inline-block">
      <DropdownToggle
        className="rounded-0 py-0"
        style={{ backgroundColor: color, borderColor: color }}
        caret
      >
        {selectedYear || "All Goals"}
      </DropdownToggle>
      <DropdownMenu>
        {years.map(year => (
          <DropdownItem
            style={year === selectedYear ? { backgroundColor: color } : null}
            active={year === selectedYear}
            onClick={() => onYearChanged(year)}
          >
            {year}
          </DropdownItem>
        ))}
        <DropdownItem divider></DropdownItem>
        <DropdownItem
          active={!selectedYear}
          onClick={() => onYearChanged(null)}
        >
          Show All Goals
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
