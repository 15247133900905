import React from "react";
import { StyledButtonOutline } from "../utils/StyledButton";

export default function TimelineToggle(props) {
  const buttonClasses = ["btn", "rounded-0", "py-0"];
  const buttonColor = props.color;

  return (
    <React.Fragment>
      <StyledButtonOutline
        color={buttonColor}
        className={[...buttonClasses, props.showActive ? "" : "active"].join(
          " "
        )}
        onClick={() => props.onToggleActive(0)}
      >
        All
      </StyledButtonOutline>
      <StyledButtonOutline
        color={buttonColor}
        className={[...buttonClasses, props.showActive ? "active" : ""].join(
          " "
        )}
        onClick={() => props.onToggleActive(1)}
      >
        Active
      </StyledButtonOutline>
    </React.Fragment>
  );
}
