import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { Collapse } from "reactstrap";
import axios from "axios";
import moment from "moment";
import { debounce } from "throttle-debounce";
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import Goal from "./Goal";
import RagInput from "./RagInput";
import "./Objective.scss";
import colors from "../assets/colors/colors";
import {
  childToggleHandler,
  childAddHandler,
  generateChildElementsCollapse,
  generateChildElementIds
} from "./childUtils";
const DragHandle = sortableHandle(() => <span><i class="fa fa-arrows-alt"></i></span>);
export default class Objective extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorPrimary: props.user.color_primary || colors.primary,
      colorSecondary: props.user.color_secondary || colors.secondary,
      element_id: props.element_id,
      description: props.description,
      childElementsCollapse: generateChildElementsCollapse(props.childElements),
      goalIds: generateChildElementIds(props.childElements),
      prevStrategyElementIds:[]
    };

    this.childToggleHandler = childToggleHandler.bind(this);
    this.childAddHandler = childAddHandler.bind(this);
  }

  handleGoalAdded = () => {
    this.props.onCreateGoalClicked();
    if (this.props.collapse) {
      this.props.onCollapseToggle();
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onGoalSortOrderEnd(this.state.element_id,arrayMove(this.props.childElements.map((goal) => goal.element_id), oldIndex, newIndex));
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
  };

  maintainStrategyCollapsedIds = (strategyId) =>{

    let prevstorageIds = this.state.prevStrategyElementIds;
    
    if(prevstorageIds.filter(x=>x ===  strategyId) > 0)
    {
      prevstorageIds = prevstorageIds.filter( x=> x !== strategyId);
    }
    else{
      prevstorageIds.push(strategyId);
    }

    this.setState({
      prevStrategyElementIds : prevstorageIds
    });
  }
  

  render() {

    const SortableItem = SortableElement(({ goal, sortIndex, isNew, collapse}) =>
    <li sortIndex={sortIndex}  className="objectives-sortable-li-goals">
      { this.props.toggleSorting ? (<DragHandle />) : null } 
      <React.Fragment key={goal.element_id}>
          <Goal
            {...goal}
            collapse={collapse}
            key={goal.element_id}
            objecive_element_id={this.state.element_id}
            isNew={isNew}
            onChildAdded={this.childAddHandler}
            user={this.props.user}
            users={this.props.users}
            onToggle={this.childToggleHandler}
            onDeleteClicked={this.props.onDeleteClicked}
            onCreateActionClicked={strategyId =>
              this.props.onCreateActionClicked(goal, strategyId)
            }
            onCreateStrategyClicked={() =>
              this.props.onCreateStrategyClicked(goal)
            }
            onActionChange={this.props.onActionChange}
            onChildExpanded={this.props.onChildExpanded}
            onChildExpandedStates={strategyId => this.maintainStrategyCollapsedIds(strategyId)}
            prevStrategyIds= {this.state.prevStrategyElementIds}
            onStrategySortOrderEnd ={this.props.onStrategySortOrderEnd}
            toggleSorting ={this.props.toggleSorting}
          ></Goal>
        </React.Fragment>
    </li>
  );

    const SortableList = SortableContainer(({ items }) => {
      
      return (
        <ul className="objectives-sortable">
          {items.map((goal, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              sortIndex={index}
              goal={goal}
              pressDelay={1}
              isNew={!this.state.goalIds.includes(goal.element_id)}
              collapse={this.state.childElementsCollapse[goal.element_id]}
            />
          ))}
        </ul>
      );
    });

    let backgroundPrimary = { backgroundColor: this.state.colorPrimary };
    let textSecondary = { color: this.state.colorSecondary };
    const goalCount = this.props.childElements.length;
    const fadeAnimation = this.props.isNew
      ? "animated fadeInRight fast"
      : "animated fadeIn faster";
    const lastChildElement =
      this.props.childElements &&
      this.props.childElements[this.props.childElements.length - 1];
    const lastChildElementId = lastChildElement && lastChildElement.element_id;
    const lastChildCollapsed = this.state.childElementsCollapse[
      lastChildElementId
    ];

    // const goals = this.props.childElements.map((goal, index) => {
    //   const isNew = !this.state.goalIds.includes(goal.element_id);
    //   const collapse = this.state.childElementsCollapse[goal.element_id];

    //   return (
    //     <React.Fragment key={goal.element_id}>
    //       <Goal
    //         {...goal}
    //         collapse={collapse}
    //         key={goal.element_id}
    //         isNew={isNew}
    //         onChildAdded={this.childAddHandler}
    //         user={this.props.user}
    //         users={this.props.users}
    //         onToggle={this.childToggleHandler}
    //         onDeleteClicked={this.props.onDeleteClicked}
    //         onCreateActionClicked={strategyId =>
    //           this.props.onCreateActionClicked(goal, strategyId)
    //         }
    //         onCreateStrategyClicked={() =>
    //           this.props.onCreateStrategyClicked(goal)
    //         }
    //         onActionChange={this.props.onActionChange}
    //         onChildExpanded={this.props.onChildExpanded}
    //         toggleSorting ={this.props.toggleSorting}
    //       ></Goal>
    //     </React.Fragment>
    //   );
    // });
    const sortableGoals = this.props.toggleSorting ?  (<SortableList items={this.props.childElements} onSortEnd={this.onSortEnd} onSortStart={this.onSortEnd}  useDragHandle />):(
      this.props.childElements.map((goal, index) => {
        const isNew = !this.state.goalIds.includes(goal.element_id);
        const collapse = this.state.childElementsCollapse[goal.element_id];
  
        return (
          <React.Fragment key={goal.element_id}>
            <Goal
              {...goal}
              collapse={collapse}
              key={goal.element_id}
              isNew={isNew}
              onChildAdded={this.childAddHandler}
              user={this.props.user}
              users={this.props.users}
              onToggle={this.childToggleHandler}
              onDeleteClicked={this.props.onDeleteClicked}
              onCreateActionClicked={strategyId =>
                this.props.onCreateActionClicked(goal, strategyId)
              }
              onCreateStrategyClicked={() =>
                this.props.onCreateStrategyClicked(goal)
              }
              onActionChange={this.props.onActionChange}
              onChildExpanded={this.props.onChildExpanded}
              toggleSorting ={this.props.toggleSorting}
            ></Goal>
          </React.Fragment>
        );
      })
    );

    return (

      <div
        className={`${fadeAnimation} ${
          this.props.collapse ? "mb-2" : ""
        } objective`}
      >
        <div
          className="row shadow--inset py-1 text-white"
          style={backgroundPrimary}
        >
          <div className="col my-auto mr-auto d-flex" title="Objective">
            <h3 className="mt-2 pr-3 font-weight-bold text-uppercase objective--br">
              Objective
            </h3>
            <p className="h3 mt-2 pl-3 text-uppercase" style={textSecondary}>
              {this.state.description}
            </p>
          </div>
          <div className="col-auto my-auto">
            <button
              title="Expand/collapse goals"
              className="btn bg-body rounded-0 py-0 shadow  expand-collapse goals-dropdown"
              onClick={this.props.onCollapseToggle}
              style={textSecondary}
            >
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span
                    className="mr-1 rounded-sm font-xs font-weight-bold  color-body dropdown-badge"
                    style={{ backgroundColor: this.state.colorSecondary }}
                  >
                    {goalCount}
                  </span>
                  <span>{goalCount === 1 ? "Goal" : "Goals"}</span>
                </div>
                {this.props.collapse ? (
                  <i className="fas fa-chevron-down fa-fw"></i>
                ) : (
                  <i className="fas fa-chevron-up fa-fw"></i>
                )}
              </div>
            </button>
          </div>
        </div>
        <Collapse isOpen={!this.props.collapse}>
          {sortableGoals}

          {sortableGoals.length === 0 && (
            <>
              <div className="row">
                <div className="col mr-auto">
                  <p className="text-muted smaller ml-3 pt-3">
                    <i className="fas fa-info-circle"></i> No goals added to
                    this objective for the selected quarter and department
                  </p>
                </div>
              </div>
              <hr className="my-0" />
            </>
          )}

          {(lastChildCollapsed ||
            (this.props.childElements && !this.props.childElements.length)) && (
            <div className="row justify-content-end ml-4 py-1">
              {!this.props.toggleSorting ?
              (
                <button
                  className={"btn rounded-0 px-0 py-0" }
                  onClick={this.handleGoalAdded}
                >
                  <p className="d-inline-block my-0 mr-3">Add Goal</p>
                  <i
                    className="fas fa-plus fa-fw text-white py-1  add add--mr"
                    style={backgroundPrimary}
                  ></i>
                </button>
                ) : null } 
            </div>
          )}
        </Collapse>
      </div>
    
    );
  }
}
