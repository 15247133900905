import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import axios from 'axios';

import logo from "../assets/img/L2G_Logo_draft.png";
import colors from "../assets/colors/colors";
import { toMomentObject } from "react-dates";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetToken: null,
      user_id: 0,
      password: null,
      confirmPassword: null,
      errorMessage: null,
      successMessage: null,
      isDisabled: false
    };
  }

  async componentDidMount() {

    
    const userResponse = await axios.get("/resetPassword/" + this.props.match.params.id).then(resp => {
      console.log(resp.data);
      if (resp.data == "LINKEXPIRED") {
        this.setState({ errorMessage: "Error! Invalid or expired reset password link" ,isDisabled: true});
      }
      else if(resp.data == "INVALIDTOKEN")
      {
        this.setState({ errorMessage: "Error! Invalid or expired reset password link" ,isDisabled: true});
      }
      else {
        this.setState({
          resetToken: this.props.match.params.id,
          user_id: resp.data
        });
      }
    }).catch(error => {
      console.error(error);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage !== prevProps.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  resetPasswordClickedHandler = () => {
    
    if (
      !this.state.password ||
      !this.state.confirmPassword
    ) {
      this.setState({ errorMessage: "Error! Please fill out all fields" ,isDisabled: false});
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      
      this.setState({
        errorMessage: "Error! Password and confirm password do not match",
        isDisabled: false
      });
      return;
    } else {
      this.setState({ errorMessage: null ,isDisabled: false});
    }

      let user = {
        password :this.state.password,
        confirmPassword:this.state.confirmPassword,
        user_id : this.state.user_id
      }

      axios.post("/resetPassword",user).then(resp => {
        if(resp.data == "SUCCESS")
        {
            this.setState({
              successMessage : `Password has been changed successfully.`,
              password:null,
              confirmPassword:null,
              errorMessage:null,
              isDisabled: true
            });
        }
      }).catch(error => {
        this.setState({
          errorMessage : "Unable to change password!",
          password:null,
          confirmPassword:null,
          errorMessage:"",
          successMessage: null,
          isDisabled: true
        });
      });
     
    
  };

  keyUpHandler = event => {
    // Check for enter key
    if (event.keyCode === 13) {
      event.preventDefault();
      this.resetPasswordClickedHandler();
    }
  };

  render() {
    let textSecondary = { color: colors.secondary };
    let errorAlert = null;
    if (this.state.errorMessage) {
      errorAlert = (
        <Row className="mt-3 mb-0">
          <Col>
            <Alert color="danger" className="mb-0">
              {this.state.errorMessage}
            </Alert>
          </Col>
        </Row>
      );
    }
    let successAlert = null;
    if(this.state.successMessage){
        successAlert = (
            <Row className="mt-3 mb-0">
              <Col>

                <Alert color="success" className="mb-0" >
                  {this.state.successMessage} please click <a href="/">here</a> to login.
                </Alert> 
              </Col>
            </Row>
          );
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <img src={logo} alt="Lead2Goals" />
                      <p className="text-muted">Reset Password</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="New password"
                          autoComplete="password"
                          onChange={event =>
                            this.setState({ password : event.target.value })
                          }
                          defaultValue={this.state.password}
                          maxLength="250"
                          
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Confirm new password"
                          onChange={event =>
                            this.setState({ confirmPassword: event.target.value })
                          }
                          defaultValue={this.state.confirmPassword}
                         
                        />
                      </InputGroup>
                      <Row >
                        <Col xs="6">
                          <Button
                            type="button"
                            color="primary"
                            className="px-4 py-2"
                            onClick={this.resetPasswordClickedHandler}
                            disabled={this.state.isDisabled}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    {errorAlert}
                    {successAlert}
                  </CardBody>
                </Card>
                <Card
                  className="text-white py-5 d-md-down-none"
                  style={{ width: "44%", backgroundColor: colors.primary }}
                >
                  <CardBody className="text-center">
                    <div>
                      <h1>Reset Password</h1>
                      <p>
                        Enter new password to reset your password
                      </p>
                      <p>
                        This system is currently in beta v
                        {process.env.REACT_APP_VERSION}
                      </p>
                      <p>
                        <a style={textSecondary} href="https://lead2goals.com">
                          Lead2Goals.com
                        </a>
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ResetPassword;
