// import React from "react";
import QuarterlyView from "./RoadMap/QuarterlyView";
import Users from "./Users/Users";
import EditUser from "./Users/EditUser";
import CreateUser from "./Users/CreateUser";
import Clients from "./SubscriberAdmin/Clients";
import EditClient from "./SubscriberAdmin/EditClient";
import CreateClient from "./SubscriberAdmin/CreateClient";
import CreateDepartment from "./SubscriberAdmin/CreateDepartment";
import Departments from "./Departments/Departments";
import EditDepartment from "./SubscriberAdmin/EditDepartment";
import CreateObjective from "./Objectives/CreateObjective";
import Objectives from "./Objectives/Objectives";
import EditObjective from "./Objectives/EditObjective";
import Profile from "./Users/Profile";
import StrategicPlan from "./StrategicPlan/StrategicPlan";
import CreatePlan from "./StrategicPlan/CreatePlan";
import MyClient from "./SubscriberAdmin/MyClient";
import EmailTemplate from "./SubscriberAdmin/EmailTemplate";
import EmailTemplateList from "./SubscriberAdmin/EmailTemplateList";
import CreateKpi from "./Kpis/CreateKpi";
import EditKpi from "./Kpis/EditKpi";

const routes = [
  { path: "/", exact: true, name: "Home" },
  {
    path: "/plan/create",
    exact: true,
    name: "CreatePlan",
    component: CreatePlan
  },
  {
    path: "/plan",
    exact: true,
    name: "Plan",
    component: StrategicPlan
  },
  {
    path: "/goals",
    exact: true,
    name: "Quarterly",
    component: QuarterlyView
  },
  { path: "/users", exact: true, name: "Users", component: Users },
  {
    path: "/users/create",
    exact: true,
    name: "CreateUser",
    component: CreateUser
  },
  {
    path: "/users/:id",
    exact: true,
    name: "EditUser",
    component: EditUser
  },
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    component: Profile
  },
  { path: "/clients", exact: true, name: "Clients", component: Clients },
  {
    path: "/departments/create",
    exact: true,
    name: "CreateDepartment",
    component: CreateDepartment
  },
  {
    path: "/departments/:id",
    exact: true,
    name: "EditDepartment",
    component: EditDepartment
  },
  {
    path: "/settings",
    exact: true,
    name: "MyClient",
    component: MyClient
  },
  {
    path: "/emails",
    exact: true,
    name: "EmailTemplateList",
    component: EmailTemplateList
  },
  {
    path: "/emails/:id",
    exact: true,
    name: "EmailTemplate",
    component: EmailTemplate
  },
  {
    path: "/clients/create",
    exact: true,
    name: "CreateClient",
    component: CreateClient
  },
  {
    path: "/clients/:id",
    exact: true,
    name: "EditClient",
    component: EditClient
  },
  {
    path: "/objectives/create",
    exact: true,
    name: "CreateObjective",
    component: CreateObjective
  },
  {
    path: "/objectives/:id",
    exact: true,
    name: "EditObjective",
    component: EditObjective
  },
  {
    path: "/objectives",
    exact: true,
    name: "Objectives",
    component: Objectives
  },
  {
    path: "/departments",
    exact: true,
    name: "Departments",
    component: Departments
  },
  {
    path: "/kpi/create",
    exact: true,
    name: "CreateKpi",
    component: CreateKpi
  },
  {
    path: "/kpis/:id",
    exact: true,
    name: "EditKpi",
    component: EditKpi
  }
];

export default routes;
