import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

class CreateDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      department_name: null,
      clients: [],
      department_id: 0,
      client_id:""
    };
  }

  async componentDidMount() {
    const [clientsResponse] = await Promise.all([
      axios.get("/clients"),
    ]);
    this.setState({
      clients:clientsResponse.data
    });
  }

  createDepartmentHandler = () => {
    const department = {
      department_name: this.state.department_name,
      client_id: this.state.client_id,
    };
    axios
        .post("/departments", department)
        .then(res => {
          this.props.history.push("/departments");
        })
        .catch(error => {
          console.log(error.response.data.message);
        });
  };

  render() {

    return (
        <div className="row animated slideInRight">
        <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
        <div className="card card-accent-primary">
        <div className="card-body">
        <h3>New Department</h3>
          <form className="mt-3">
            <div className="form-group row">
              <label for="client" className="col-sm-4 col-form-label">
              Client
              </label>
              <div className="col-sm-8">
                <select
                    id="client"
                    className="form-control"
                    value={this.state.client_id}
                    onChange={event =>
                      this.setState({ client_id: +event.target.value })
                    }
                  >
                  <option hidden>Select Client</option>
                  {this.state.clients.map(client => (
                    <option key={client.client_id} value={client.client_id} className="text-dark" >
                      {client.client_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label for="departmentName" className="col-sm-4 col-form-label">
              Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="departmentName"
                  placeholder="Department name"
                  value={this.state.department_name}
                  onChange={event =>
                    this.setState({ department_name: event.target.value })
                  }
                  />
              </div>
            </div>
            <div className="form-group row justify-content-end">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-primary mr-3"
                  onClick={this.createDepartmentHandler}
                >
                  Save Department
                </button>
                <Link to="/departments">
                  <button type="button" className="btn btn-light">
                    Cancel
                  </button>
                </Link>
              </div>
            </div>
          </form>
        </div>
        </div>
        </div>
        </div>
  );
  }
}

export default withRouter(CreateDepartment);
