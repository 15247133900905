import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";

import logo from "../assets/img/L2G_Logo_draft.png";
import colors from "../assets/colors/colors";
import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      errorMessage: props.errorMessage
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage !== prevProps.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  loginClickedHandler = () => {
    if (this.state.username && this.state.password) {
      this.setState({ errorMessage: null });
      this.props.onLogin({
        username: this.state.username,
        password: this.state.password
      });
    } else {
      this.setState({ errorMessage: "Please enter a username and password." });
    }
  };

  keyUpHandler = event => {
    // Check for enter key
    if (event.keyCode === 13) {
      event.preventDefault();
      this.loginClickedHandler();
    }
  };

  render() {
    let textSecondary = { color: colors.secondary };
    let errorAlert = null;
    if (this.state.errorMessage) {
      errorAlert = (
        <Row className="mt-3 mb-0">
          <Col>
            <Alert color="danger" className="mb-0">
              {this.state.errorMessage}
            </Alert>
          </Col>
        </Row>
      );
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <img src={logo} alt="Lead2Goals" />
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                          onChange={event =>
                            this.setState({ username: event.target.value })
                          }
                          onKeyUp={this.keyUpHandler}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          onChange={event =>
                            this.setState({ password: event.target.value })
                          }
                          onKeyUp={this.keyUpHandler}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button
                            type="button"
                            color="primary"
                            className="px-4 py-2"
                            onClick={this.loginClickedHandler}
                          >
                            Login
                          </Button>
                        </Col>
                        {<Col xs="6" className="text-right pd-10">
                          <Link to="/forgotpassword" color="link" className="px-0">
                          Forgot password?
                          </Link>
                        </Col> }
                      </Row>
                    </Form>
                    {errorAlert}
                  </CardBody>
                </Card>
                <Card
                  className="text-white py-5 d-md-down-none"
                  style={{ width: "44%", backgroundColor: colors.primary }}
                >
                  <CardBody className="text-center">
                    <div>
                      <h1>Welcome</h1>
                      <p>
                        Sign in to your account to access the Lead2Goals environment.
                      </p>
                      <p>
                        This system is currently in beta v
                        {process.env.REACT_APP_VERSION}
                      </p>
                      <p>
                        <a style={textSecondary} href="https://lead2goals.com">
                          Lead2Goals.com
                        </a>
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
