import React, { Component } from "react";
import { Alert } from "reactstrap";
import axios from "axios";
import { runInThisContext } from "vm";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      first_name: null,
      last_name: null,
      newFName: null,
      newLName: null,
      username: null,
      newUserName: null,
      user_email: null,
      newEmail: null,
      role_id: 0,
      role_name: null,
      department_id: 0,
      department_name: null,
      editMode: false,
      passwordMode: false,
      currentPassword: null,
      password: null,
      confirmPassword: null,
      passwordError: null,
      updateError: null
    };
  }

  async componentDidMount() {
    const userResponse = await axios.get("/users/" + this.props.user.user_id);

    const {
      user_id,
      first_name,
      last_name,
      username,
      user_email,
      role_id,
      department_id
    } = userResponse.data;

    const [departmentsResponse, rolesResponse] = await Promise.all([
      axios.get(`/clients/${this.props.user.client_id}/departments`),
      axios.get(`/clients/${this.props.user.client_id}/roles`)
    ]);

    const department = departmentsResponse.data.find(
      d => d.department_id === department_id
    );
    const department_name = department
      ? department.department_name
      : department_id;
    const role = rolesResponse.data.find(r => r.role_id === role_id);
    const role_name = role ? role.role_name : role_id;

    this.setState({
      user_id,
      first_name,
      last_name,
      newFName: first_name,
      newLName: last_name,
      username,
      newUserName: username,
      role_id,
      department_id,
      department_name,
      role_name,
      user_email,
      newEmail: user_email
    });
  }

  updateProfileHandler = () => {
    if (
      !this.state.newFName ||
      !this.state.newLName ||
      !this.state.newUserName
    ) {
      this.setState({
        updateError: "Please fill out all fields"
      });
      return;
    }

    const user = {
      first_name: this.state.newFName,
      last_name: this.state.newLName,
      username: this.state.newUserName,
      user_email: this.state.newEmail
    };

    axios
      .put("/users/" + this.state.user_id + "/profile", user)
      .then(res => {
        this.setState({
          editMode: false,
          first_name: user.first_name,
          last_name: user.last_name,
          username: user.username,
          user_email: user.user_email,
          updateError: null
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ updateError: "Username already exists" });
      });
  };

  updatePasswordHandler = () => {
    if (
      !this.state.currentPassword ||
      !this.state.password ||
      !this.state.confirmPassword
    ) {
      this.setState({ passwordError: "Please fill out all fields" });
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        passwordError: "New password and confirmation password must match"
      });
      return;
    } else {
      this.setState({ passwordError: null });
    }
    
    const user = {
      username: this.state.username,
      currentPassword: this.state.currentPassword,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword
    };

    
    axios
      .put("/users/" + this.state.user_id + "/password", user)
      .then(res => {
        this.setState({
          passwordMode: false,
          currentPassword: null,
          password: null,
          confirmPassword: null,
          passwordError: null
        });
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  render() {
    const inputClass = this.state.editMode
      ? "form-control animated fadeIn"
      : "form-control-plaintext font-weight-bold";

    return (
      <div className="row animated slideInRight">
        <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
          <div className="card card-accent-primary">
            <div className="card-body">
              <h3>My Profile</h3>
              <form className="mt-3">
                <div className="form-group row">
                  <label for="personName" className="col-sm-4 col-form-label">
                    First Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      readOnly={!this.state.editMode}
                      type="text"
                      className={inputClass}
                      id="personName"
                      value={this.state.newFName}
                      onChange={event =>
                        this.setState({ newFName: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="personName" className="col-sm-4 col-form-label">
                    Last Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      readOnly={!this.state.editMode}
                      type="text"
                      className={inputClass}
                      id="personName"
                      value={this.state.newLName}
                      onChange={event =>
                        this.setState({ newLName: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="newUserName" className="col-sm-4 col-form-label">
                    Username
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className={inputClass}
                      id="newUserName"
                      autoComplete="newUserName"
                      placeholder="Username"
                      value={this.state.newUserName}
                      onChange={event =>
                        this.setState({ newUserName: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="newEmail" className="col-sm-4 col-form-label">
                    Email
                  </label>
                  <div className="col-sm-8">
                    {this.state.user_email || this.state.editMode ? (
                      <input
                        type="text"
                        className={inputClass}
                        id="newEmail"
                        autoComplete="newEmail"
                        placeholder="Email"
                        value={this.state.newEmail}
                        onChange={event =>
                          this.setState({ newEmail: event.target.value })
                        }
                      />
                    ) : (
                      <div className="form-control-plaintext font-italic">
                        None
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label for="department" className="col-sm-4 col-form-label">
                    Department
                  </label>
                  <div className="col-sm-8">
                    {this.state.department_name ? (
                      <input
                        readOnly
                        type="text"
                        className="form-control-plaintext font-weight-bold"
                        id="department"
                        value={this.state.department_name}
                      />
                    ) : (
                      <div className="form-control-plaintext font-italic">
                        None assigned
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label for="role" className="col-sm-4 col-form-label">
                    Role
                  </label>
                  <div className="col-sm-8">
                    <input
                      readOnly
                      type="text"
                      className="form-control-plaintext font-weight-bold"
                      id="role"
                      value={this.state.role_name}
                    />
                  </div>
                </div>
                {this.state.passwordMode && (
                  <React.Fragment>
                    <div className="form-group row animated fadeInUp">
                      <label
                        for="currentPassword"
                        className="col-sm-4 col-form-label"
                      >
                        Current Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="password"
                          className="form-control"
                          id="currentPassword"
                          autoComplete="current-password"
                          placeholder="Current Password"
                          value={this.state.currentPassword}
                          onChange={event =>
                            this.setState({
                              currentPassword: event.target.value
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row animated fadeInUp">
                      <label
                        for="newPassword"
                        className="col-sm-4 col-form-label"
                      >
                        New Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="password"
                          className="form-control"
                          id="newPassword"
                          autoComplete="new-password"
                          placeholder="New password"
                          value={this.state.password}
                          onChange={event =>
                            this.setState({ password: event.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row animated fadeInUp">
                      <label
                        for="newPassword"
                        className="col-sm-4 col-form-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          autoComplete="confirmPassword"
                          placeholder="Retype password"
                          value={this.state.confirmPassword}
                          onChange={event =>
                            this.setState({
                              confirmPassword: event.target.value
                            })
                          }
                        />
                      </div>
                    </div>
                    {this.state.passwordError && (
                      <div className="form-group row mt-3 mb-0">
                        <div className="offset-4 col">
                          <Alert color="danger">
                            {this.state.passwordError}
                          </Alert>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {this.state.updateError && (
                  <div className="form-group row mt-3 mb-0">
                    <div className="offset-4 col">
                      <Alert color="danger">{this.state.updateError}</Alert>
                    </div>
                  </div>
                )}
                <div className="form-group row justify-content-end">
                  <div className="col-auto">
                    {this.state.editMode ? (
                      <div className="animated fadeInLeft">
                        <button
                          type="button"
                          className="btn btn-primary mr-3"
                          onClick={this.updateProfileHandler}
                        >
                          Save Profile
                        </button>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() =>
                            this.setState({
                              editMode: false,
                              newFName: this.state.first_name,
                              newLName: this.state.last_name,
                              newUserName: this.state.username,
                              updateError: null
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    ) : this.state.passwordMode ? (
                      <div className="animated fadeInUp">
                        <button
                          type="button"
                          className="btn btn-primary mr-3"
                          onClick={this.updatePasswordHandler}
                        >
                          Save Password
                        </button>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() =>
                            this.setState({
                              passwordMode: false,
                              currentPassword: null,
                              password: null,
                              confirmPassword: null,
                              passwordError: null
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div className="animated fadeInDown">
                        <button
                          type="button"
                          className="btn btn-light mr-3"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          Edit Profile
                        </button>
                        <button
                          type="button"
                          className="btn btn-light mr-3"
                          onClick={() => this.setState({ passwordMode: true })}
                        >
                          Change Password
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
