import React, { Component } from "react";
import axios from "axios";

import "./UserElements.scss";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Alert,
    Row,
    Col
} from "reactstrap";


class UserElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            assignedElements: props.elements,
            selectedUserName: null,
            champion_user_id: props.champion_user_id,
            errorChampionMessage: props.errorMessage,
            handleUserMessage: props.handleErroMessage,
            selectedClientID : props.clientID
        };
    }


    handleUserChange = user => {

        let selectedUser = this.state.users.find(u => u.user_id === user.user_id);
        if (selectedUser) {
            this.setState({ selectedUserName: selectedUser.first_name + " " + selectedUser.last_name });
        }
        this.props.selectedChampionUer(user);
    }


    
    async componentDidMount() {
        const [usersResponse] = await Promise.all([
            axios.get(`/users/enabled/${1}/${this.state.selectedClientID}/client/ASC`),
        ]);
        

        this.setState({
            users: usersResponse.data
        });
    }



    render() {


        return (
            <React.Fragment>
                <div className="row mb-2">
                    {typeof this.state.assignedElements != "undefined" && this.state.assignedElements.length > 0 ? (
                    <div className="col-12">
                        <div className="form-group row">
                        <label className="col-5 text-right">Select Champion</label>
                        <div className="col-7">
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    className="rounded-0 py-0 mr-1 shadow  expand-collapse"
                                    color="body"
                                    caret
                                >
                                    {this.state.selectedUserName || "Select Champion"}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.state.users.map(user => (
                                        user.user_id != this.state.champion_user_id ? (
                                            <DropdownItem
                                                onClick={() => this.handleUserChange(user)}
                                            >
                                                {user.first_name + " " + user.last_name}
                                            </DropdownItem>
                                        ) : null
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown></div>
                            </div>
                            </div>
                    ) : null}

                    {typeof this.state.assignedElements != "undefined" && this.state.assignedElements.length > 0 ? (
                        <div className="col-12">
                            {this.state.assignedElements.map(element => {

                                return (
                                    <div className="row">
                                        <div className="col-12">
                                        </div>
                                        <ul className="user-assigned-elements">
                                            <li>
                                                <div className="row">
                                                    <div className="col-9"><b>{element.level_name}</b></div>
                                                    <div className="col-3">{element.description}</div>
                                                </div>
                                            </li>
                                            <li>
                                                {element.goalElements.map(goal => {
                                                    return (
                                                        <ul className="goal-assigned-elements">
                                                            <li><div className="row">
                                                                <div className="col-9"><b>{goal.level_name}</b></div>
                                                                <div className="col-3">{goal.description}</div>
                                                            </div></li>
                                                            <li>{goal.strategyElements.map(strat => {
                                                                return (
                                                                    <ul className="strategy-assigned-elements">
                                                                        <li><div className="row">
                                                                            <div className="col-9"><b>{strat.level_name}</b></div>
                                                                            <div className="col-3">{strat.description}</div>
                                                                        </div></li>
                                                                        <li>{strat.actionElements.map(action => {
                                                                            return (
                                                                                <ul className="action-assigned-elements">
                                                                                    <li><div className="row">
                                                                                        <div className="col-9"><b>{action.level_name}</b></div>
                                                                                        <div className="col-3">{action.description}</div>
                                                                                    </div></li>
                                                                                </ul>
                                                                            )
                                                                        })}</li>
                                                                    </ul>
                                                                )
                                                            })}</li>
                                                        </ul>
                                                    )
                                                })}
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                            }
                        </div>) : (
                            <div className="col-12">
                                <div className="alert alert-info">No elemets assigned to user.</div>
                            </div>)}
                </div>

            </React.Fragment>
        )
    };
}

export default UserElements;