import React from "react";
import { StyledButtonOutline } from "../utils/StyledButton";

export default function QuarterFilters(props) {
  const buttonClasses = ["btn", "rounded-0", "mr-1", "py-0", "px-1"];
  const buttonColor = props.color;

  return (
    <React.Fragment>
      <div className="d-inline-block" role="group" aria-label="Quarter group">
      <StyledButtonOutline
          color={buttonColor}
          type="button"
          disabled={!props.quarter}
          className={[
            ...buttonClasses,
            props.quarter === -1 ? "active" : ""
          ].join(" ")}
          onClick={() => props.onQuarterClicked(-1)}
        >
          All Quarters
        </StyledButtonOutline>
        <StyledButtonOutline
          color={buttonColor}
          type="button"
          disabled={!props.quarter}
          className={[
            ...buttonClasses,
            props.quarter === 1 ? "active" : ""
          ].join(" ")}
          onClick={() => props.onQuarterClicked(1)}
        >
          Q1
        </StyledButtonOutline>
        <StyledButtonOutline
          color={buttonColor}
          type="button"
          disabled={!props.quarter}
          className={[
            ...buttonClasses,
            props.quarter === 2 ? "active" : ""
          ].join(" ")}
          onClick={() => props.onQuarterClicked(2)}
        >
          Q2
        </StyledButtonOutline>
        <StyledButtonOutline
          color={buttonColor}
          type="button"
          disabled={!props.quarter}
          className={[
            ...buttonClasses,
            props.quarter === 3 ? "active" : ""
          ].join(" ")}
          onClick={() => props.onQuarterClicked(3)}
        >
          Q3
        </StyledButtonOutline>
        <StyledButtonOutline
          color={buttonColor}
          type="button"
          disabled={!props.quarter}
          className={[
            ...buttonClasses,
            props.quarter === 4 ? "active" : ""
          ].join(" ")}
          onClick={() => props.onQuarterClicked(4)}
        >
          Q4
        </StyledButtonOutline>
      </div>
    </React.Fragment>
  );
}
