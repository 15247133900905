import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import React, { Component } from "react";
import axios from "axios";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledDropdown
} from "reactstrap";

import logo from "../assets/img/L2G_Logo_draft.png";
import sygnet from "../assets/img/L2G_icon_draft.png";
import colors from "../assets/colors/colors";

export default class DefaultHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorPrimary: props.user.color_primary || colors.primary,
      colorSecondary: props.user.color_secondary || colors.secondary,
    };
  }

  onImpersonateBtnLogout = () => {
    axios
      .get("user/logoutimpersonate")
      .then(res => {
        this.props.onImpersonateLogout(res.data.session);
        this.props.selectPlan({ strategic_plan_id: undefined });
        this.props.history.push("/intermediate");
      });
  }

  render() {
    let textSecondary = { color: this.state.colorSecondary };
    let margin_right_15 = { 'margin-right': '15px' };

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: logo,
            width: 114,
            height: 25,
            alt: "Lead2Goals"
          }}
          minimized={{
            src: sygnet,
            width: 25,
            height: 25,
            alt: "Lead2Goals"
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto mr-3" navbar>
          {/* <NavItem className="d-md-down-none">
            <NavLink to="#" className="nav-link">
              <i className="icon-bell"></i>
              <Badge pill color="danger">
                5
              </Badge>
            </NavLink>
          </NavItem> */}

          {
            this.props.user.imporsonatedByUserID > 0 && <NavItem style={{ "border": "1px solid red", "padding": "5px 10px", "margin-right": "10px" }} className="blink_me">
              <span
                className="text-uppercase"
                style={{ color: 'red', 'font-weight': 'bold', 'cursor': 'pointer' }}
                onClick={this.onImpersonateBtnLogout}
              >
                Impersonated
            </span>
            </NavItem>
          }


          <NavItem>
            <span
              className="text-uppercase"
              style={textSecondary}
            >
              {this.props.user.first_name} {this.props.user.last_name}
            </span>
          </NavItem>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <span className="text-muted text-uppercase">
                <i className="far fa-user-circle fa-2x"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>Account</strong>
              </DropdownItem>
              {/*<DropdownItem disabled>
                <i className="cui-envelope-closed"></i> Messages
                 <Badge color="success">42</Badge> 
              </DropdownItem>*/}
              <DropdownItem href="#/profile">
                <i className="cui-user"></i> Profile
              </DropdownItem>
              {/* <DropdownItem disabled>
                <i className="cui-settings"></i> Settings
              </DropdownItem> */}
              <DropdownItem divider />
              <DropdownItem onClick={this.props.onLogout}>
                <i className="cui-lock-locked"></i> Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}
