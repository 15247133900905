import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

class EditObjective extends Component {
  constructor(props) {
    super(props);
    this.state = {
      element_id: props.match.params.id,
      description: null,
      resources: null
    };
  }

  async componentDidMount() {
    const objectiveResponse = await axios.get(
      "/objectives/" + this.state.element_id
    );

    const { description, resources } = objectiveResponse.data;

    this.setState({
      description,
      resources
    });
  }

  updateObjectiveHandler = () => {
    if (!this.state.description || !this.state.description.trim()) return;

    const objective = {
      description: this.state.description,
      resources: this.state.resources
    };

    axios
      .put("/objectives/" + this.state.element_id, objective)
      .then(res => {
        this.props.history.push("/objectives");
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  render() {
    const descriptionCounter = 240 - (this.state.description?.length || 0);

    return (
      <div className="row animated slideInRight fast">
        <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
          <div className="card card-accent-primary">
            <div className="card-body">
              <h3>Edit Objective</h3>
              <form className="mt-3">
                <div className="form-group">
                  <label>
                    Objective Summary:{" "}
                    <span className="text-muted small ml-5">
                      ({descriptionCounter} characters remaining)
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.description}
                    maxLength={240}
                    onChange={event => {
                      this.setState({ description: event.target.value });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Objective Details</label>
                  <textarea
                    className="form-control"
                    value={this.state.resources}
                    onChange={event =>
                      this.setState({ resources: event.target.value })
                    }
                    rows={3}
                  ></textarea>
                </div>
                <div className="form-group row justify-content-end">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary mr-3"
                      onClick={this.updateObjectiveHandler}
                    >
                      Save Objective
                    </button>
                    <Link to="/objectives">
                      <button type="button" className="btn btn-light">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditObjective);
