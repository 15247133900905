import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

class CreateKpi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kpi_description: "",
      kpi_menu: "",
      menus: [],
      showMenu: false
    };
  }

  async componentDidMount() {
    let menuResponse = await axios.get("/kpis/menus")

    this.setState({menus: menuResponse.data})
  }

  createKpiHandler = () => {
    if (!this.state.new_kpi_description || !this.state.new_kpi_description.trim() ||
      !this.state.new_kpi_menu|| !this.state.new_kpi_menu.trim()) return;

    const kpi = {
      kpi_description: this.state.kpi_description,
      kpi_menu: this.state.kpi_menu,
      strategic_plan_id:this.props.selectedPlan.strategic_plan_id
    };
    axios
      .post("/kpis", kpi)
      .then(res => {
        this.props.history.push("/objectives");
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  render() {
    return (
      <div className="row animated slideInRight fast">
        <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
          <div className="card card-accent-primary">
            <div className="card-body">
              <h3>New Kpi</h3>
              <form className="mt-3" autocomplete="off">
                <div className="form-group">
                  <label for="menu">Kpi Menu</label>
                  <input 
                    type="text"
                    id="menu"
                    className="form-control col-6"
                    value={this.state.kpi_menu}
                    onFocus={e => this.setState({showMenu:true})}
                    onBlur={e => setTimeout(_ =>this.setState({showMenu:false}), 500)}
                    onChange={event =>
                      this.setState({ kpi_menu: event.target.value })
                    } 
                  />
                  {(!!this.state.menus[0] && !!this.state.showMenu) && this.state.menus.map(menu =>
                    <option onClick={e => this.setState({kpi_menu: menu.kpi_value})}>{menu.kpi_value}</option>
                  )}
                </div>
                <div className="form-group">
                  <label for="description">Kpi Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    value={this.state.kpi_description}
                    onChange={event =>
                      this.setState({ kpi_description: event.target.value })
                    }
                    rows={3}
                  ></textarea>
                </div>
                <div className="form-group row justify-content-end">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary mr-3"
                      onClick={this.createKpiHandler}
                    >
                      Save Kpi
                    </button>
                    <Link to="/objectives">
                      <button type="button" className="btn btn-light">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateKpi);
