import React, { Component } from "react";

export default class DefaultFooter extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <React.Fragment>
        <span className="">
          <a href="https://lead2goals.com">Lead2Goals</a> &copy; {year}
        </span>
        <span className="ml-auto">
          v{process.env.REACT_APP_VERSION}
        </span>
      </React.Fragment>
    );
  }
}
