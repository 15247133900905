import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import { SketchPicker } from "react-color";
import InputColor from "react-input-color";
import EditFonts from "./EditFonts";
import { Select } from 'antd';
import "./MyClient.scss";

import {
  Alert,
} from "reactstrap";

class MyClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_name: "",
      color_primary: null,
      color_secondary: null,
      amber_threshold: 0,
      red_threshold: 0,
      client_logo: null,
      edit_logo: false,
      changePrimaryColors: false,
      changeSecondaryColors: false,
      departments: [],
      new_department_name: "",
      vmc_font_type: this.props.user.vmc_font_type || "Open Sans",
      vmc_font_color: "#fff",
      vmc_font_size: 15,
      swot_font_type: this.props.user.swot_font_type || "Open Sans",
      swot_font_color: "#2f353a",
      swot_font_size: 10.5,
      vmcFontEditMode: false,
      swotFontEditMode: false,
      enable_email: false,
      emailTemplatesExist: false,
      timezone_id:0,
      timeZones:[],
      errorMessage: null,
      successMessage: null
    };
  }

  async componentDidMount() {
    const clientResponse = await axios.get("/clients/settings");
    const departmentResponse = await axios.get("/clients/departments");
    const timeZoneResponse = await axios.get("/clients/timezones");
    const {
      client_name,
      client_logo,
      color_primary,
      color_secondary,
      amber_threshold,
      red_threshold,
      vmc_font_color,
      vmc_font_size,
      swot_font_color,
      swot_font_size,
      enable_email,
      timezone_id
    } = clientResponse.data;

    const departments = departmentResponse.data;
    this.setState({
      client_name,
      client_logo,
      color_primary,
      color_secondary,
      amber_threshold,
      red_threshold,
      vmc_font_color: vmc_font_color || "#fff",
      vmc_font_size: vmc_font_size || 15,
      swot_font_color: swot_font_color || "#2f353a",
      swot_font_size: swot_font_size || 10.5,
      departments,
      enable_email,
      emailTemplatesExist: enable_email,
      timezone_id,
      timeZones:timeZoneResponse.data
    });

    
  }

  primaryColorChangeHandler = ({ hex }) => {
    this.setState({ color_primary: hex });
  };

  secondaryColorChangeHandler = ({ hex }) => {
    this.setState({ color_secondary: hex });
  };

  fileSelectedHandler = event => {
    this.getBase64(event.target.files[0], result => {
      console.log(result);
      this.setState({ client_logo: result, edit_logo: false });
    });
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    if (!!file) {
      reader.readAsDataURL(file);
      reader.onload = function() {
        cb(reader.result);
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    }
    cb("");
  }

  createClientDepartmentHandler = () => {
    const department = {
      department_name: this.state.new_department_name
    };
    axios
      .post("/clients/departments", department)
      .then(res => {
        axios.get("/clients/departments").then(res => {
          this.setState({ departments: res.data, new_department_name: "" });
        });
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  updateClientHandler = () => {
    const client = {
      color_primary: this.state.color_primary,
      color_secondary: this.state.color_secondary,
      amber_threshold: this.state.amber_threshold,
      red_threshold: this.state.red_threshold,
      client_logo: this.state.client_logo,
      vmc_font_type: this.state.vmc_font_type,
      vmc_font_color: this.state.vmc_font_color,
      vmc_font_size: this.state.vmc_font_size,
      swot_font_type: this.state.swot_font_type,
      swot_font_color: this.state.swot_font_color,
      swot_font_size: this.state.swot_font_size,
      enable_email: this.state.enable_email,
      timezone_id: this.state.timezone_id
    };
    axios
      .post("/clients/settings", client)
      .then(res => {
        this.props.onSessionUpdate();
        this.setState({ emailTemplatesExist: this.state.enable_email , successMessage : "Setting has been updated successfully!",errorMessage : null });

        setTimeout(function(){ 
           this.setState({ successMessage : null,errorMessage : null }); 
          }.bind(this),5000);

      })
      .catch(error => {
        console.log(error.response.data.message);
        this.setState({  errorMessage : "Error updating settings!",successMessage: null });
        setTimeout(function(){ 
          this.setState({ successMessage : null,errorMessage : null }); 
         }.bind(this),5000);
      });
  };

  render() {
    const emailTemplateTooltip =
      this.state.emailTemplatesExist && this.state.enable_email
        ? "View and manage email templates"
        : this.state.enable_email
        ? "Email settings must be saved before managing templates"
        : "Email notifications are not enabled";
    const emailDisplay = (
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Notifications</label>
        <div className="col-auto my-auto">
          <label
            className="position-relative enable-email"
            htmlFor="enableEmail"
          >
            Email enabled
            <input
              className="position-absolute"
              id="enableEmail"
              name="enableEmail"
              type="checkbox"
              defaultChecked={this.state.enable_email}
              onChange={event =>
                this.setState({ enable_email: !this.state.enable_email })
              }
            />
            <i
              className={`ml-2 font-lg fas ${
                this.state.enable_email ? "fa-check-square" : "fa-square"
              } `}
              aria-hidden="true"
            ></i>
          </label>
        </div>
        <div className="col-auto mr-auto">
          <Link to="/emails" title={emailTemplateTooltip}>
            <button
              type="button"
              disabled={
                !this.state.emailTemplatesExist || !this.state.enable_email
              }
              className="btn btn-sm btn-light"
            >
              Manage Email Templates
            </button>
          </Link>
        </div>
      </div>
    );

    return ( this.props.user.role_id !== 5 ?
      <React.Fragment>
        <div className="row animated slideInRight fast  my-client">
          <div className="col-lg-12 col-xl-10 m-auto">
            <div className="card card-accent-primary">
              <div className="card-body">
                <h3 className="mb-3">{this.state.client_name} Settings</h3>
                <form>
                        {this.state.errorMessage && (
                    <div className="form-group row mt-3 mb-0">
                      <div className="col">
                        <Alert color="danger">
                          {this.state.errorMessage}
                        </Alert>
                      </div>
                    </div>
                  )}
                  {this.state.successMessage && (
                    <div className="form-group row mt-3 mb-0">
                      <div className="col">
                        <Alert color="success">
                          {this.state.successMessage}
                        </Alert>
                      </div>
                    </div>
                  )}
                  <div className="form-group row">
                    <label
                      htmlFor="client_logo"
                      className="col-sm-3 col-form-label"
                    >
                      Client Logo
                    </label>
                    <div className="col-sm-9">
                      {!!this.state.client_logo && !this.state.edit_logo && (
                        <React.Fragment>
                          <img src={this.state.client_logo} width="150px"></img>{" "}
                          <a
                            onClick={() =>
                              this.setState({
                                edit_logo: !this.state.edit_logo
                              })
                            }
                          >
                            <i className="fas fa-edit"></i>
                          </a>{" "}
                          <a
                            onClick={() => this.setState({ client_logo: null })}
                          >
                            <i className="fas fa-trash"></i>
                          </a>
                        </React.Fragment>
                      )}
                      {(!this.state.client_logo || this.state.edit_logo) && (
                        <input
                          type="file"
                          className="btn btn-sm btn-light"
                          onChange={this.fileSelectedHandler}
                        />
                      )}
                    </div>
                  </div>
                  {emailDisplay}
                  <div className="form-group row color">
                    <label
                      htmlFor="color_primary"
                      className="col-sm-3 col-form-label"
                    >
                      Colors
                    </label>
                    <div className="col-sm-4">
                      <label
                        htmlFor="secondary_color"
                        className="col-form-label mr-3"
                      >
                        Primary
                      </label>
                      <div className="d-inline-block">
                        {!!this.state.color_primary &&
                          (!this.state.changePrimaryColors ? (
                            <a
                              onClick={() =>
                                this.setState({
                                  changePrimaryColors: !this.state
                                    .changePrimaryColors
                                })
                              }
                            >
                              <InputColor
                                initialHexColor={this.state.color_primary}
                                placement="right"
                                className="mr-1"
                              />
                              <i className="fas fa-edit"></i>
                            </a>
                          ) : (
                            <React.Fragment>
                              <a
                                onClick={() =>
                                  this.setState({
                                    changePrimaryColors: !this.state
                                      .changePrimaryColors
                                  })
                                }
                              >
                                <i className="fas fa-check"></i>
                              </a>
                              <SketchPicker
                                className="mt-sm-4"
                                color={this.state.color_primary}
                                onChangeComplete={
                                  this.primaryColorChangeHandler
                                }
                              />
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <label
                        htmlFor="secondary_color"
                        className="col-form-label mr-3"
                      >
                        Secondary
                      </label>
                      <div className="d-inline-block">
                        {!!this.state.color_secondary &&
                          (!this.state.changeSecondaryColors ? (
                            <a
                              onClick={() =>
                                this.setState({
                                  changeSecondaryColors: !this.state
                                    .changeSecondaryColors
                                })
                              }
                            >
                              <InputColor
                                initialHexColor={this.state.color_secondary}
                                placement="right"
                                className="mr-1"
                              />
                              <i className="fas fa-edit"></i>
                            </a>
                          ) : (
                            <React.Fragment>
                              <a
                                onClick={() =>
                                  this.setState({
                                    changeSecondaryColors: !this.state
                                      .changeSecondaryColors
                                  })
                                }
                              >
                                <i className="fas fa-check"></i>
                              </a>
                              <SketchPicker
                                className="mt-sm-4"
                                color={this.state.color_secondary}
                                onChangeComplete={
                                  this.secondaryColorChangeHandler
                                }
                              />
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="amber_threshold"
                      className="col-sm-3 col-form-label"
                    >
                      Threshold
                    </label>
                    <div className="col-sm-4">
                      <label
                        htmlFor="amber_threshold"
                        className="col-form-label mr-3"
                      >
                        Amber
                      </label>
                      <div className="d-inline-block  threshold-input-container">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amber Threshold %"
                            id="amber_threshold"
                            value={this.state.amber_threshold}
                            onChange={event =>
                              this.setState({
                                amber_threshold: event.target.value
                              })
                            }
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <label
                        htmlFor="red_threshold"
                        className="col-form-label mr-3"
                      >
                        Red
                      </label>
                      <div className="d-inline-block  threshold-input-container">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Red Threshold %"
                            id="red_threshold"
                            value={this.state.red_threshold}
                            onChange={event =>
                              this.setState({
                                red_threshold: event.target.value
                              })
                            }
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Fonts</label>
                    <div className="col-sm-4">
                      <label className="col-form-label">
                        Vision, Mission, Core Values
                      </label>
                      <EditFonts
                        groupName="vmc"
                        activeFontFamily={
                          this.state.vmc_font_type || "Open Sans"
                        }
                        font_color={this.state.vmc_font_color || "#fff"}
                        font_size={this.state.vmc_font_size || 15}
                        fontTypeChanged={vmc_font_type =>
                          this.setState({ vmc_font_type })
                        }
                        fontColorChanged={vmc_font_color =>
                          this.setState({ vmc_font_color })
                        }
                        fontSizeChanged={vmc_font_size =>
                          this.setState({ vmc_font_size })
                        }
                        editMode={this.state.vmcFontEditMode}
                        toggleEditMode={() =>
                          this.setState({
                            vmcFontEditMode: !this.state.vmcFontEditMode
                          })
                        }
                      />
                    </div>
                    <div className="col-sm-5">
                      <label className="col-form-label">
                        Strengths, Weaknesses, Opportunities, Threats
                      </label>
                      <EditFonts
                        groupName="swot"
                        activeFontFamily={
                          this.state.swot_font_type || "Open Sans"
                        }
                        font_color={this.state.swot_font_color}
                        font_size={this.state.swot_font_size || 10.5}
                        fontTypeChanged={swot_font_type =>
                          this.setState({ swot_font_type })
                        }
                        fontColorChanged={swot_font_color =>
                          this.setState({ swot_font_color })
                        }
                        fontSizeChanged={swot_font_size =>
                          this.setState({ swot_font_size })
                        }
                        editMode={this.state.swotFontEditMode}
                        toggleEditMode={() =>
                          this.setState({
                            swotFontEditMode: !this.state.swotFontEditMode
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="timezone" className="col-sm-3 col-form-label">
                      TimeZone
                  </label>
                    <div className="col-sm-6">
                      <select
                        id="timezone"
                        className="form-control"
                        value={this.state.timezone_id}
                        onChange={event =>
                          this.setState({ timezone_id: event.target.value })
                        }
                      >
                        <option hidden>Select Timezone</option>
                        {this.state.timeZones.map(timzone => (
                          <option
                            key={timzone.timezone_id}
                            value={timzone.timezone_id}
                            className="text-dark"
                          >
                            {timzone.description}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-group row justify-content-end">
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={this.updateClientHandler}
                      >
                        Save Settings
                      </button>
                      <Link to="/settings">
                        <button type="button" className="btn btn-light">
                          Cancel
                        </button>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row animated slideInRight fast">
          <div className="col-lg-12 col-xl-10 m-auto">
            <div className="card card-accent-primary">
              <div className="card-body">
                <h3>Departments</h3>
                {this.state.departments.length > 0 ? (
                  <div className="card animated fadeIn faster">
                    <table className="table table-hover mb-0">
                      <tbody>
                        {this.state.departments.map(department => {
                          return (
                            <tr key={department.department_id}>
                              <td>{department.department_name}</td>
                              <td>
                                <Link
                                  to={
                                    "/departments/" + department.department_id
                                  }
                                >
                                  <button className="btn btn-link py-0">
                                    <i className="fas fa-user-edit"></i>
                                    &nbsp;Edit Department
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : null}

                <form className="mt-3">
                  <div className="form-group row">
                    <label
                      htmlFor="departmentName"
                      className="col-sm-3 col-form-label"
                    >
                      New Department
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control"
                        id="departmentName"
                        placeholder="New department name"
                        value={this.state.new_department_name}
                        onChange={event =>
                          this.setState({
                            new_department_name: event.target.value
                          })
                        }
                      />
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn btn-primary mr-3"
                        disabled={!this.state.new_department_name}
                        onClick={this.createClientDepartmentHandler}
                      >
                        Save New Department
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    : <Redirect
        to="/clients"
        name="Clients"
      /> );
  }
}

export default withRouter(MyClient);
