import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./scss/_react_dates_overrides.css";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_L2G_API_URL;

ReactDOM.render(<App />, document.getElementById("root"));
