import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

class EditDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      department_name: null,
      client_id: null,
      clients: [],
    };
  }

  async componentDidMount() {
    const departmentResponse = await axios.get(
      "/departments/" + this.props.match.params.id
    );

    const { department_name, client_id } = departmentResponse.data;

    const [ clientsResponse] = await Promise.all([
      axios.get(`/clients`)
    ]);

    this.setState({
      department_name,
      client_id,
      clients: clientsResponse.data,
    });
  }

  updateDepartmentHandler = () => {
    const department = {
      department_name: this.state.department_name,
      client_id: this.state.client_id
    };

    axios
      .put("/departments/" + this.props.match.params.id, department)
      .then(res => {
        this.props.history.push("/settings");
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  render() {
    return (
      <div className="row animated slideInRight">
        <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
          <div className="card card-accent-primary">
            <div className="card-body">
              <h3>Edit Department</h3>
              <form className="mt-3">
                <div className="form-group row">
                  <label for="departmentName" className="col-sm-4 col-form-label">
                    Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="departmentName"
                      placeholder="Department name"
                      value={this.state.department_name}
                      onChange={event =>
                        this.setState({ department_name: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row justify-content-end">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary mr-3"
                      onClick={this.updateDepartmentHandler}
                    >
                      Save Department
                    </button>
                    <Link to="/settings">
                      <button type="button" className="btn btn-light">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditDepartment);
