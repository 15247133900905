import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {DatePicker} from "antd";
import axios from "axios";
import moment from "moment";

import {
  Alert,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import 'antd/dist/antd.css';
const { RangePicker } = DatePicker;



class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: null,
      last_name: null,
      username: null,
      user_email: null,
      role_id: 0,
      department_id: 0,
      departments: [],
      roles: [],
      enabled:false,
      start_date: null,
      end_date: null,
      errorMessage : null,
      clients:[],
      selectClient_id: 0,
      superAdmin:false,
      first_name_req: false,
      last_name_req: false,
      username_req: false,
      email_req: false,
      valid_email: false,
      department_req: false,
      role_req: false
    };
  }

  async componentDidMount() {
    const [departmentResponse, roleResponse,clientResponse] = await Promise.all([
      axios.get(`/clients/${this.props.user.client_id}/departments`),
      axios.get(`/clients/${this.props.user.client_id}/roles`),
      axios.get(`/clients`)
    ]);
    this.setState({
      departments: departmentResponse.data,
      roles: roleResponse.data,
      clients : clientResponse.data[0],
      superAdmin : (this.props.user.role_id === 5)
    });

    
  }

  disabledStartDate = (current) => {
    // Can not select days before today and today
    return current && current >= moment();
  }

  disabledEndDate = (current) => {
    // Can not select days before today and today
    return current && current <= moment();
  }

  handleUserStartDate = (date, dateString) => {

    this.setState({ start_date: dateString });

    //CHECK DATE VALIDATION
    if (!this.checkDateValidation(this.state.start_date, this.state.end_date)) {
      return;
    }
  }

  handleUserEndDate = (date, dateString) => {

    this.setState({ end_date: dateString });
    //CHECK DATE VALIDATION
    if (!this.checkDateValidation(this.state.start_date, this.state.end_date)) {
      return;
    }

    
  }

  checkDateValidation = (startDate, endDate) => {
    
    if (moment(endDate).isSameOrBefore(startDate)) {
      this.setState({ errorMessage: "End date should be gretater than start date." });
      return false;
    }

    this.setState({ errorMessage: null });

    return true;
  };

  checkDataValidation = (user) => {
    let isValid = true;
    if(IsNullOrEmptyString(user.first_name)) {
      this.state.first_name_req = true;
      isValid = false;
    }

    if(IsNullOrEmptyString(user.last_name)) {
      this.state.last_name_req = true;
      isValid = false;
    }

    if(IsNullOrEmptyString(user.username)) {
      this.state.username_req = true;
      isValid = false;
    }

    if(IsNullOrEmptyString(user.user_email)) {
      this.state.email_req = true;
      isValid = false;
    } else if(user.user_email != '') {
      if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(user.user_email)) { this.state.valid_email = true; isValid = false; }
    }

    if(user.department_id == 0) {
      this.state.department_req = true;
      isValid = false;
    }

    if(user.role_id == 0) {
      this.state.role_req = true;
      isValid = false;
    }

    return isValid;
  };

  clientChangeHandler = client_id => {
    this.setState({selectClient_id : client_id},() => this.getInfoByClientID());
  }

  getInfoByClientID(){

    axios.get(`/clients/${this.state.selectClient_id}/departments`).then(resp => {
      this.setState({
        departments: resp.data,
      });
    });
  }


  createUserHandler = () => {
    const user = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      username: this.state.username,
      role_id: this.state.role_id,
      user_email: this.state.user_email,
      department_id: this.state.department_id,
      client_id: (!this.state.superAdmin ?  this.props.user.client_id : this.state.selectClient_id),
      enabled: this.state.enabled,
      start_date: this.state.start_date,
      end_date: this.state.end_date
    };

    
    //CHECK DATE VALIDATION
    if (!this.checkDateValidation(user.start_date, user.end_date)) {
      return;
    }

    if(!this.checkDataValidation(user)) {
      return;
    }

    axios
      .post("/users", user)
      .then(res => {
        this.props.history.push("/users");
      })
      .catch(error => {
        alert("Error occurred while adding user");
        console.log(error.response.data.message);
      });
  };

  render() {
    const showPasswordWarning =
      this.state.password && this.state.password !== this.state.confirmPassword;

      let errorAlert = null;
      if (this.state.errorMessage) {
        errorAlert = (
          <Row className="mt-3 mb-0">
            <Col>
              <Alert color="danger" className="mb-0">
                {this.state.errorMessage}
              </Alert>
            </Col>
          </Row>
        );
      }

    return (
      
      <div className="row animated slideInRight">
        <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
          <div className="card card-accent-primary">
            <div className="card-body">
              <h3>New User</h3>
              <form className="mt-3">
                {this.state.superAdmin ? (
                <div className="form-group row">
                  <label
                    htmlFor="firstName"
                    className="col-sm-4 col-form-label"
                  >
                    Client
                  </label>
                  <div className="col-sm-8">
                    <select
                      id="client"
                      className="form-control"
                      value={this.state.selectClient_id}
                      onChange={event =>
                        this.clientChangeHandler(event.target.value)
                      }
                    >
                      <option hidden>Select Client</option>
                      {this.state.clients.map(client => (
                        <option
                          key={client.client_id}
                          value={client.client_id}
                          className="text-dark"
                        >
                          {client.client_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                ): null}
                <div className="form-group row">
                  <label
                    htmlFor="firstName"
                    className="col-sm-4 col-form-label"
                  >
                    Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className={`form-control ${this.state.first_name_req ? 'has-error' : ''}`}
                      id="firstName"
                      placeholder="First name"
                      value={this.state.first_name}
                      onChange={event =>
                        this.setState({ first_name: event.target.value, first_name_req:false  })
                      }
                    />
                    {this.state.first_name_req && <span className='text-danger'>Please enter first name</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="offset-sm-4 col-sm-8">
                    <input
                      type="text"
                      className={`form-control ${this.state.last_name_req ? 'has-error' : ''}`}
                      id="lastName"
                      placeholder="Last name"
                      value={this.state.last_name}
                      onChange={event =>
                        this.setState({ last_name: event.target.value, last_name_req:false  })
                      }
                    />
                    {this.state.last_name_req && <span className='text-danger'>Please enter last name</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label for="department" className="col-sm-4 col-form-label">
                    Department
                  </label>
                  <div className="col-sm-8">
                    <select
                      id="department"
                      className={`form-control ${this.state.department_req ? 'has-error' : ''}`}
                      value={this.state.department_id}
                      onChange={event =>
                        this.setState({ department_id: +event.target.value, department_req:false })
                      }
                    >
                      <option hidden>Select department</option>
                      {this.state.departments.map(department => (
                        <option
                          key={department.department_id}
                          value={department.department_id}
                          className="text-dark"
                        >
                          {department.department_name}
                        </option>
                      ))}
                    </select>
                    {this.state.department_req && <span className='text-danger'>Please select department</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="role" className="col-sm-4 col-form-label">
                    Role
                  </label>
                  <div className="col-sm-8">
                    <select
                      id="role"
                      className={`form-control ${this.state.role_req ? 'has-error' : ''}`}
                      value={this.state.role_id}
                      onChange={event =>
                        this.setState({ role_id: event.target.value, role_req:false  })
                      }
                    >
                      <option hidden>Select Role</option>
                      {this.state.roles.map(role => (
                        <option
                          key={role.role_id}
                          value={role.role_id}
                          className="text-dark"
                        >
                          {role.role_name}
                        </option>
                      ))}
                    </select>
                    {this.state.role_req && <span className='text-danger'>Please select role</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label for="newUserName" className="col-sm-4 col-form-label">
                    Username
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className={`form-control ${this.state.username_req ? 'has-error' : ''}`}
                      id="newUserName"
                      autoComplete="newUserName"
                      placeholder="Username"
                      value={this.state.username}
                      onChange={event =>
                        this.setState({ username: event.target.value, username_req:false })
                      }
                    />
                    {this.state.username_req && <span className='text-danger'>Please enter user name</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="email" className="col-sm-4 col-form-label">
                    Email
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className={`form-control ${this.state.email_req || this.state.valid_email ? 'has-error' : ''}`}
                      id="email"
                      autoComplete="email"
                      placeholder="Email"
                      value={this.state.user_email}
                      onChange={event =>
                        this.setState({ user_email: event.target.value, email_req:false, valid_email: false  })
                      }
                    />
                    {this.state.email_req && <span className='text-danger'>Please enter email</span>}
                    {this.state.valid_email && <span className='text-danger'>Please enter vaild email</span>}
                  </div>
                </div>
                {/* <div className="form-group row">
                  <label htmlFor="email" className="col-sm-4 col-form-label">
                    Enabled
                  </label>
                  <div className="col-sm-8">
                   <label
                    htmlFor="enabled"
                    className="position-relative enable-email"

                  >
                    <input
                      className="position-absolute"
                      id="enabled"
                      name="enabled"
                      type="checkbox"
                      defaultChecked={this.state.enabled}
                      onChange={() =>
                        this.setState({ enabled: !this.state.enabled })
                      }
                      style={{opacity : 0}}
                    />
                    <i
                      className={`ml-2 font-lg fas ${
                        this.state.enabled ? "fa-check-square" : "fa-square"
                        } `}
                      aria-hidden="true"
                    ></i>
                  </label>
                  </div>
                </div> */}
                <div className="form-group row">
                  <label htmlFor="start_end_date" className="col-sm-4 col-form-label">
                    Start Date
                  </label>
                  <div className="col-sm-8">
                    {
                      this.state.start_date != "" && this.state.end_date != "" && this.state.start_date != null && this.state.end_date != null
                        ? (
                          <DatePicker
                            id="start_date"
                            name="start_date"
                            bordered={true}
                            disabledDate={this.disabledStartDate}
                            placeholder="Select start date"
                            value={moment(this.state.start_date)}
                            onChange={(date, dateString) => this.handleUserStartDate(date, dateString)}
                          />
                        ) : (<DatePicker
                          id="end_date"
                          name="end_date"
                          bordered={true}
                          disabledDate={this.disabledStartDate}
                          placeholder="Select start date"
                          onChange={(date, dateString) => this.handleUserStartDate(date, dateString)}
                        />)
                    }
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="start_end_date" className="col-sm-4 col-form-label">
                    End Date
                  </label>
                  <div className="col-sm-8">
                    {
                      this.state.start_date != "" && this.state.end_date != "" && this.state.start_date != null && this.state.end_date != null
                        ? (
                          <DatePicker
                            id="end_date"
                            name="end_date"
                            bordered={true}
                            disabledDate={this.disabledEndDate}
                            placeholder="Select end date"
                            value={moment(this.state.end_date)}
                            onChange={(date, dateString) => this.handleUserEndDate(date, dateString)}
                          />
                        ) : (<DatePicker
                          id="end_date"
                          name="end_date"
                          bordered={true}
                          disabledDate={this.disabledEndDate}
                          placeholder="Select end date" 
                          onChange={(date, dateString) => this.handleUserEndDate(date, dateString)}
                        />)
                    }
                  </div>
                </div>
                {errorAlert}
                <div className="form-group row justify-content-end mt-3">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary mr-3"
                      onClick={this.createUserHandler}
                    >
                      Save User
                    </button>
                    <Link to="/users">
                      <button type="button" className="btn btn-light">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateUser);

export function IsNullOrEmptyString(e) {
  switch (e) {
      case "":
      case 0:
      case "0":
      case null:
      case false:
      case undefined:
      case typeof this == "undefined":
          return true;
      default:
          return false;
  }
}