import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import axios from "axios";
import { TimePicker, DatePicker, Select } from 'antd';
import RichTextEditor, { EditorValue } from 'react-rte';
import moment from "moment";

import 'antd/dist/antd.css';

import "./EmailTemplate.scss";

const { Option } = Select;

let days= [];

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS','IMAGE_BUTTON' ],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ]
};


class EmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      subject: "",
      body: "",
      enabled: false,
      is_scheduled: false,
      email_type_id : 0,
      schedule_type: 0,
      schedule_value: "",
      schedule_time: "10:00",
      ScheduleQuarter: 0,
      ScheduleDailyTime: "",
      ScheduleWeekleyDay: 0,
      ScheduleWeeklyTime: "",
      ScheduleMonthlyDate: "",
      ScheduleMonthlyTime: "",
      ScheduleQuarterDate: "",
      ScheduleQuarterTime: "",
      editorState : RichTextEditor.createEmptyValue(),
      TimeZone:""
    };

    this.getDaysDropdown();
  }

  onChange = (editorState) => {
    this.setState({editorState});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
        editorState.toString('html')
      );
    }
  };
  getDaysDropdown = ()=>{

    days = [];
    for (let i = 1; i <= 31; i++) {
      var day = "";
      if (i === 1) {
        day = "1st";
      }
      else if (i === 2) {
        day = "2nd"
      }
      else if (i === 3) {
        day = "3rd"
      }
      else if (i === 31) {
        day = "31st"
      }
      else {
        day = i + "th";
      }
      days.push(<Option value={i.toString()}>{day}</Option>);
    }
  }
 
    
  async componentDidMount() {

   

    //get email template
    axios
      .get("/emails/" + this.props.match.params.id)
      .then(res => {
        this.setState({
          description: res.data[0].description,
          subject: res.data[0].subject,
          body: res.data[0].body,
          enabled: res.data[0].enabled,
          is_scheduled: res.data[0].is_scheduled,
          schedule_type: res.data[0].schedule_type,
          schedule_value: res.data[0].schedule_value,
          schedule_time: res.data[0].schedule_time,
          email_type_id: res.data[0].email_type_id,
          editorState: RichTextEditor.createValueFromString(res.data[0].body,'html')   ,
          TimeZone: res.data[0].ServerTimeZone
        });
        this.clearAndSetAll();
      })
      .catch(err => {
        alert("Error loading email template");
      });

      
  }

  clearAndSetAll=()=> {

    let currentTimeData = this.state.schedule_time;
    let currentDateData = this.state.schedule_value;
    
    if (this.state.schedule_type == 1 || this.state.schedule_type == 2) {
      this.setState({ ScheduleWeekleyDay: currentDateData });
      this.setState({ ScheduleWeeklyTime: currentTimeData });
    }
    else if (this.state.schedule_type == 3) {
      this.setState({ ScheduleMonthlyDate: currentDateData });
      this.setState({ ScheduleMonthlyTime: currentTimeData });
    }
    else if (this.state.schedule_type == 4) {
      this.setState({ ScheduleQuarterDate: currentDateData });
      this.setState({ ScheduleQuarterTime: currentTimeData });
    }

  }

  

  updateEmailTemplateHandler = () => {
    const emailTemplate = {
      subject: this.state.subject,
      body: this.state.editorState.toString('html'),
      enabled: this.state.enabled,
      is_scheduled: this.state.is_scheduled,
      schedule_type: !!this.state.schedule_type ? this.state.schedule_type : 0,
      schedule_value: !!this.state.schedule_type ? this.getScheduleValue(this.state.schedule_type) : null,
      schedule_time: !!this.state.schedule_type ? this.getScheduleTime(this.state.schedule_type):null
    };
    axios
      .put("/emails/" + this.props.match.params.id, emailTemplate)
      .then(res => {
        this.props.history.push("/emails");
      })
      .catch(error => {
        console.log(error.response.data.message);
        alert("Error occurred while updating email template");
      });
  };

  getScheduleValue(schedule_type) {
    
    let scheduleValue = "";
    switch (schedule_type.toString()) {
      
      case "1":
      case "2":
        scheduleValue = this.state.ScheduleWeekleyDay;
        break;
      case "3":
        scheduleValue = this.state.ScheduleMonthlyDate;
        break;
      case "4":
        scheduleValue = this.state.ScheduleQuarterDate;
        break;
    }
    return scheduleValue;
  }

  getScheduleTime(schedule_type) {
    
    let scheduleTime = "";
    switch (schedule_type.toString()) {
      case "1":
      case "2":
        scheduleTime = this.state.ScheduleWeeklyTime;
        break;
      case "3":
        scheduleTime = this.state.ScheduleMonthlyTime;
        break;
      case "4":
        scheduleTime = this.state.ScheduleQuarterTime;
        break;
    }
    return scheduleTime;
  }

  handleScheduleTypeChange = (value) => {
    this.setState({ schedule_type: parseInt(value) });
  }

  //DAILY
  handleDailyTimeChnage = (time, dateString) => {
    this.setState({ ScheduleDailyTime: dateString });
  }

  //WEEKLY
  handleScheduleWeekDayChange = (value) => {
    this.setState({ ScheduleWeekleyDay:  parseInt(value) });
  }
  handleScheduleWeekTimeChange = (data, dateString) => {
    this.setState({ ScheduleWeeklyTime: dateString });
  }

  //MONTHLY
  handleScheduleMonthlyDateChange = (value) => {
    this.setState({ ScheduleMonthlyDate: value });
    

  }
  handleScheduleMonthlyTimeChange = (data, dateString) => {
    this.setState({ ScheduleMonthlyTime: dateString });
  }

  //QUARTER
  handleScheduleQuarterChange = (value) => {
    this.setState({ ScheduleQuarter:  parseInt(value) });
  }

  //QUARTER DATE
  handleScheduleQuarterDateChange = (value) => {
    this.setState({ ScheduleQuarterDate: value });
  }

  handleScheduleQuarterTimeChange = (data, dateString) => {
    this.setState({ ScheduleQuarterTime: dateString });
  }
  
  render() {
    return (
      <div className="row animated slideInRight fast email-template">
        <div className="col-lg-12 col-xl-10 m-auto">
          <div className="card card-accent-primary">
            <div className="card-body">
              <h3>Email Template</h3>
              <h6>{this.state.description}</h6>
              <form className="mt-3">
                <div className="form-group">
                  <label
                    htmlFor="enabled"
                    className="position-relative enable-email"

                  >
                    Email notification enabled
                    <input
                      className="position-absolute"
                      id="enabled"
                      name="enabled"
                      type="checkbox"
                      defaultChecked={this.state.enabled}
                      onChange={() =>
                        this.setState({ enabled: !this.state.enabled })
                      }
                    />
                    <i
                      className={`ml-2 font-lg fas ${
                        this.state.enabled ? "fa-check-square" : "fa-square"
                        } `}
                      aria-hidden="true"
                    ></i>
                  </label>
                </div>
                {
                 
                  this.state.is_scheduled 
                      && (this.state.schedule_type == 1 ||  this.state.schedule_type == 2 || this.state.schedule_type == 3)
                      && (this.state.email_type_id != 5 && this.state.email_type_id != 6 && this.state.email_type_id != 7)  ? (
                    <div className="form-group">
                      <div className="inline-controls">
                        <label
                          htmlFor="is_scheduled"
                          className="position-relative enable-email"
                        >
                          
                         <input
                            className="position-absolute"
                            id="is_scheduled"
                            name="is_scheduled"
                            type="checkbox"
                            defaultChecked={this.state.is_scheduled}
                            onChange={() =>
                              this.setState({ is_scheduled: !this.state.is_scheduled })
                            }
                            disabled="disabled"
                            hidden="true"
                          />
                          <i
                            className={`ml-2 font-lg fas ${
                              this.state.is_scheduled ? "fa-check-square" : "fa-square"
                              } `}
                            aria-hidden="true"
                            hidden="true"
                          ></i>
                        </label>
                        <label>Scheduled&nbsp;&nbsp; 
                          {/* <label>Scheduled {this.state.schedule_type === 1 ? (<b>Daily</b>) : this.state.schedule_type === 2 ? (<span><b>Weekly</b> on each</span>) : this.state.schedule_type === 3 ? (<b>Monthly</b>) : this.state.schedule_type === 4 ? (<b>Quaterly</b>) : null}</label> */}
                          <Select bordered={true} placeholder="Select Schedule Type"  id="ScheduleType" name="ScheduleType" onChange={this.handleScheduleTypeChange} value={this.state.schedule_type.toString()}>
                              <option value="2">Weekly</option>
                              <option value="1">bi-Weekly</option>
                              <option value="3">Monthly</option>
                            </Select>
                        </label>
                        
                        {(this.state.schedule_type == 1 || this.state.schedule_type == 2) && (this.state.email_type_id != 5 && this.state.email_type_id != 6 && this.state.email_type_id != 7) ? (
                          <div className="display-inline">
                            <span>&nbsp;&nbsp;&nbsp;on {this.state.schedule_type != 3 ? "each" : ""}</span>
                            <Select bordered={true} placeholder="Select week day"  id="ScheduleWeekleyDay" name="ScheduleWeekleyDay" onChange={this.handleScheduleWeekDayChange} value={this.state.ScheduleWeekleyDay.toString()} style={{marginLeft:'0px!important'}} >
                              <option value="1">Monday</option>
                              <option value="2">Tuesday</option>
                              <option value="3">Wednesday</option>
                              <option value="4">Thursday</option>
                              <option value="5">Friday</option>
                              <option value="6">Saturday</option>
                              <option value="7">Sunday</option>
                            </Select>
                            <span>at</span>
                            <TimePicker bordered={true} placeholder="Select time" use12Hours format="h:mm a" id="ScheduleWeeklyTime" name="ScheduleWeeklyTime" onChange={(date, dateString) => this.handleScheduleWeekTimeChange(date, dateString)} value={this.state.ScheduleWeeklyTime != "" && this.state.ScheduleWeeklyTime != null ? moment(this.state.ScheduleWeeklyTime, 'hh:mm a') : ""}  />
                            <span>{this.state.TimeZone}</span>
                          </div>) : this.state.schedule_type == 3 ? (
                            <div className="display-inline">
                              <span>&nbsp;&nbsp;&nbsp;on </span>
                              <Select bordered={true} placeholder="Select a day"   id="ScheduleMonthlyDate" name="ScheduleMonthlyDate"  className="days" 
                                      value={this.state.ScheduleMonthlyDate.toString()} 
                                      onChange={this.handleScheduleMonthlyDateChange}
                                      >
                                {days}
                              </Select>
                              <span> date at </span>
                              <TimePicker bordered={true} placeholder="Select time"  use12Hours format="h:mm a" id="ScheduleMonthlyTime" name="ScheduleMonthlyTime" onChange={(date, dateString) => this.handleScheduleMonthlyTimeChange(date, dateString)} value={this.state.ScheduleMonthlyTime != null && this.state.ScheduleMonthlyTime != "" ? moment(this.state.ScheduleMonthlyTime, 'hh:mm a') : ""} />
                            </div>
                          ) : this.state.schedule_type == 4 ? (
                            <div className="display-inline">
                              <span> on </span>
                              <Select bordered={true} value={this.state.ScheduleQuarterDate}  id="ScheduleQuarterDate" name="ScheduleQuarterDate"   placeholder="Select a day" onChange={this.handleScheduleQuarterDateChange}>
                                {days}
                              </Select>
                              <span> date  at </span>
                              <TimePicker bordered={true} placeholder="Select time"  use12Hours format="h:mm a" id="ScheduleQuarterTime" name="ScheduleQuarterTime" onChange={(date, dateString) => this.handleScheduleQuarterTimeChange(date, dateString)} value={this.state.ScheduleQuarterTime != null && this.state.ScheduleQuarterTime != "" ? moment(this.state.ScheduleQuarterTime, 'hh:mm a') : ""} />
                            </div>) : null}
                      </div>
                    </div>
                  ) : null}
                 {this.state.schedule_type == 2 && (this.state.email_type_id == 5 ||  this.state.email_type_id == 6 || this.state.email_type_id == 7) ? ( <div className="form-group inline-controls">
                  <label>Scheduled to be sent after <input type="text" id="ScheduleHours" name="ScheduleHours" className="form-control hours" value={this.state.schedule_value.toString()}
                    onChange={event => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value === '' || re.test(event.target.value)) {
                        this.setState({ schedule_value: event.target.value, ScheduleWeekleyDay: event.target.value })
                      }
                    }
                  }
                    ></input> hours of weekly update warning email</label>
                  </div>) : null}
                <div className="form-group">
                  <label htmlFor="subject" className="col-form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="subject"
                    id="subject"
                    value={this.state.subject}
                    onChange={event =>
                      this.setState({ subject: event.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Body</label>
                 <RichTextEditor
                      value={this.state.editorState}
                      onChange={this.onChange}
                      toolbarConfig={toolbarConfig}
                    />
                </div>

                <div className="form-group row justify-content-end">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary mr-3"
                      onClick={this.updateEmailTemplateHandler}
                    >
                      Save Template
                    </button>
                    <Link to="/emails">
                      <button type="button" className="btn btn-light">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmailTemplate);
