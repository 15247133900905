import React from "react";

export function Dropdown(props) {
  const {
    plan_title,
    plans,
    show_dropdown,
    toggleDropdown,
    selectPlan
  } = props;

  return (
    <div className="my-auto">
      <div
        className={`px-3 d-flex justify-content-center align-items-center position-relative  strategic-plan-dropdown-container ${
          show_dropdown ? "strategic-plan-dropdown--active" : ""
        }`}
      >
        <h1 className="text-uppercase text-center mb-0 mr-3  tracking-wide">
          {plan_title}
        </h1>
        <button className="btn" onClick={toggleDropdown}>
          <i className={`fas fa-chevron-${show_dropdown ? "up" : "down"}`}></i>
        </button>

        {show_dropdown && (
          <ul className="pb-4 py-2  strategic-plan-dropdown">
            {plans.map((plan, i) => (
              <li key={i}>
                <button
                  className="btn text-uppercase font-xl py-0 my-1  tracking-wide"
                  onClick={() => {
                    selectPlan(plan);
                    toggleDropdown();
                  }}
                >
                  <span className="fas fa-caret-right mr-2"></span>
                  {plan.plan_title}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
