let alertMatrix = {
  getMessage: (state, type) => {
    if (state === 'error') return "There was a problem with your request.";
    return `This ${type} cannot be deleted until all the levels below it have been deleted.`;
  },
  getEmailMessage: (state, type) => {
    if (state === 'error') return "Error sending email!";
    return `Email has been sent successfully!.`;
  },
  color: {
    warning: "warning",
    error: "danger"
  }
};

export default alertMatrix;
