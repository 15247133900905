import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import 'antd/dist/antd.css';

import { DatePicker } from "antd";
import axios from "axios";
import moment from "moment";
import UserElements from './UserElements'
import {
  Alert,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
const { RangePicker } = DatePicker;

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: null,
      last_name: null,
      username: null,
      department_id: null,
      role_id: null,
      departments: [],
      roles: [],
      enabled: false,
      start_date: {},
      end_date: {},
      existing_end_date: {},
      errorMessage: null,
      isModalVisible: false,
      user_modal_visible: false,
      enable_user_end_date: null,
      elements: [],
      superAdmin: false,
      client_id: this.props.user.client_id,
      client_name: null,
      first_name_req: false,
      last_name_req: false,
      username_req: false,
      email_req: false,
      valid_email: false,
      department_req: false

    };
  }

  async componentDidMount() {
    
    const userResponse = await axios.get(
      "/users/" + this.props.match.params.id
    );

    // const { first_name, last_name, username, role_id, department_id, user_email, enabled, start_date, end_date } = userResponse.data;

    console.log(userResponse.data.client_id);

    const [rolesResponse, departmentsResponse] = await Promise.all([
      axios.get(`/clients/${userResponse.data.client_id}/roles`),
      axios.get(`/clients/${userResponse.data.client_id}/departments`)
    ]);
     
    this.setState({
      first_name: userResponse.data.first_name,
      last_name: userResponse.data.last_name,
      username: userResponse.data.username,
      role_id: userResponse.data.role_id,
      department_id: userResponse.data.department_id,
      user_email: userResponse.data.user_email,
      departments: departmentsResponse.data,
      roles: rolesResponse.data,
      enabled: userResponse.data.enabled,
      start_date: (userResponse.data.start_date != null ? userResponse.data.start_date : ""),
      end_date: (userResponse.data.end_date != null ? userResponse.data.end_date : ""),
      errorMessage: null,
      existing_end_date: (userResponse.data.end_date != null ? userResponse.data.end_date : ""),
      championSelectErrorMessage: null,
      enableChapionEndDateRequired: null,
      new_champion_user_id: 0,
      superAdmin: (this.props.user.role_id === 5),
      client_id: userResponse.data.client_id,
      client_name: userResponse.data.client_name
    });
  }

  handleUserStartDate = (date, dateString) => {
    this.setState({ start_date: dateString });
    //CHECK DATE VALIDATION
    if (!this.checkDateValidation(this.state.start_date, this.state.end_date)) {
      return;
    }
  }

  handleUserEndDate = (date, dateString) => {
    this.setState({ end_date: dateString });
    //CHECK DATE VALIDATION
    if (!this.checkDateValidation(this.state.start_date, this.state.end_date)) {
      return;
    }
  }

  handleEnableUserEndDate = (date, dateString) => {
    this.setState({ enable_user_end_date: dateString, enableChapionEndDateRequired: null });
  }

  disabledStartDate = (current) => {
    // Can not select days before today and today
    return current && current >= moment();
  }

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= moment();
  }

  showModal = (user_id) => {
    if (user_id > 0) {
      axios.get(`/element/getElementByuserID/${this.state.client_id}/${user_id}`).then(res => {
        let usersElement = res.data;
        if (usersElement != null) {
          this.setState({
            isModalVisible: !this.state.isModalVisible,
            elements: usersElement,
            championSelectErrorMessage: null,
            new_champion_user_id: (usersElement.length > 0 ? 0 : -1)
          });
        }
      });
    }
  };

  showEnableModal = (user_id) => {
    if (user_id > 0) {

      this.setState({
        user_modal_visible: !this.state.user_modal_visible,
        enableChapionEndDateRequired: null,
        new_champion_user_id: user_id
      });
    }
  };

  getFormattedDate = (dateString) => {
    var date = moment(dateString);
    var dateComponent = date.utc().format('YYYY-MM-DD');
    return dateComponent.toString();
  }

  checkDateValidation = (startDate, endDate) => {
    if (moment(endDate).isBefore(startDate)) {
      this.setState({ errorMessage: "End date should be gretater than start date." });
      return false;
    }
    this.setState({ errorMessage: null });
    return true;
  };

  checkDataValidation = (user) => {
    let isValid = true;
    if(IsNullOrEmptyString(user.first_name)) {
      this.state.first_name_req = true;
      isValid = false;
    }

    if(IsNullOrEmptyString(user.last_name)) {
      this.state.last_name_req = true;
      isValid = false;
    }

    if(IsNullOrEmptyString(user.username)) {
      this.state.username_req = true;
      isValid = false;
    }

    if(IsNullOrEmptyString(user.user_email)) {
      this.state.email_req = true;
      isValid = false;
    } else if(user.user_email != '') {
      if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(user.user_email)) { this.state.valid_email = true; isValid = false; }
    }

    if(user.department_id == 0) {
      this.state.department_req = true;
      isValid = false;
    }

    return isValid;
  };

  handleUserAssignment = (user) => {
    this.setState({ new_champion_user_id: user.user_id });
  }

  updateAssignmentHandler = () => {

    let newUserID = this.state.new_champion_user_id;
    if (newUserID === 0) {
      this.setState({ championSelectErrorMessage: "Please select champion" });
      return;
    }
    else if (newUserID > 0 || newUserID == -1) {
      this.setState({ championSelectErrorMessage: null });

      var data = {
        new_user_id: newUserID,
        previous_user_id: this.props.match.params.id
      }
      //TODO UPDATE
      axios.post('/element/UpdateElementUser', data)
        .then(resp => {
          this.setState({ end_date: moment().format("YYYY-MM-DD"), isModalVisible: !this.state.isModalVisible, enable_user_end_date: null })
        }).catch(err => {
          console.log(err.response.data.message);
        });

    }
  }

  UpdateEnableUserEndDate = () => {
    if (!this.state.enable_user_end_date) {
      this.setState({
        enableChapionEndDateRequired: "Please select end date"
      });
      return;
    }
    else {
      this.setState({
        user_modal_visible: false,
      });
    }
  }

  updateUserHandler = () => {
    const user = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      username: this.state.username,
      department_id: this.state.department_id,
      role_id: this.state.role_id,
      user_email: this.state.user_email,
      start_date: this.state.start_date,
      end_date: ((this.state.enable_user_end_date != null && moment().isBefore(this.state.enable_user_end_date)) ? this.state.enable_user_end_date : this.state.end_date),
      enabled: (this.state.enable_user_end_date != null ? moment().isBefore(this.state.enable_user_end_date) :  moment().isBefore(this.state.endDate))
    };


    //CHECK DATE VALIDATION
    if (!this.checkDateValidation(user.start_date, user.end_date)) {
      return;
    }
    
    if(!this.checkDataValidation(user)) {
      return;
    }

    axios
      .put("/users/" + this.props.match.params.id, user)
      .then(res => {

        this.props.history.push("/users");
      })
      .catch(error => {

        console.log(error.response.data.message);
      });

  };

  handleCancel = e => {
    this.setState({
      isModalVisible: false,
      user_modal_visible: false
    });
  };

  render() {
    let errorChampionAlert = null;
    if (this.state.championSelectErrorMessage) {
      errorChampionAlert = (
        <Row className="mt-3 mb-0">
          <Col>
            <Alert color="danger" className="mb-0 text-center">
              {this.state.championSelectErrorMessage}
            </Alert>
          </Col>
        </Row>
      );
    }

    let errorEnableChampionAlert = null;
    if (this.state.enableChapionEndDateRequired) {
      errorEnableChampionAlert = (
        <Row className="mt-3 mb-0">
          <Col>
            <Alert color="danger" className="mb-0 text-center">
              {this.state.enableChapionEndDateRequired}
            </Alert>
          </Col>
        </Row>
      );
    }

    const modal = (
      <Modal isOpen={this.state.isModalVisible} toggle={this.handleCancel} className="modal-lg">
        <ModalHeader toggle={this.handleCancel}>Change Assingee</ModalHeader>
        <ModalBody>
          <form>
            <UserElements
              elements={this.state.elements}
              selectedChampionUer={this.handleUserAssignment}
              champion_user_id={this.props.match.params.id}
              clientID={this.state.client_id}
            />
            {errorChampionAlert}
          </form>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={this.handleCancel} className="btn btn-outline-danger">
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => this.updateAssignmentHandler()}
          >
            Disable
          </button>
        </ModalFooter>
      </Modal>
    );

    const EnableUserModal = (
      <Modal isOpen={this.state.user_modal_visible} toggle={this.handleCancel}>
        <ModalHeader toggle={this.handleCancel}>Enable User</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group row">
              <label htmlFor="start_end_date" className="col-sm-4 col-form-label">End Date</label>
              <div className="col-sm-8">
                <DatePicker
                  id="enable_end_date"
                  name="enable_end_date"
                  bordered={true}
                  disabledDate={this.disabledDate}
                  onChange={(date, dateString) => this.handleEnableUserEndDate(date, dateString)}
                />
              </div>
            </div>
            {errorEnableChampionAlert}
          </form>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={this.handleCancel} className="btn btn-outline-danger">
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => this.UpdateEnableUserEndDate()}
          >
            Enable
          </button>
        </ModalFooter>
      </Modal>
    );

    let errorAlert = null;
    if (this.state.errorMessage) {
      errorAlert = (
        <Row className="mt-3 mb-0">
          <Col>
            <Alert color="danger" className="mb-0">
              {this.state.errorMessage}
            </Alert>
          </Col>
        </Row>
      );
    }

    return (
      <React.Fragment>
        <React.Fragment>{modal}</React.Fragment>
        <React.Fragment>{EnableUserModal}</React.Fragment>
        <div className="row animated slideInRight">
          <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
            <div className="card card-accent-primary">
              <div className="card-body">
                <h3>Edit User</h3>
                <form className="mt-3">

                  {  this.state.superAdmin ? (
                  <div className="form-group row">
                    <label
                      htmlFor="firstName"
                      className="col-sm-4 col-form-label"
                    >
                      Client
                    </label>
                    <div className="col-sm-8">
                    <b>{this.state.client_name}</b> 
                    </div>
                  </div>
                  ): null}


                  <div className="form-group row">
                    <label
                      htmlFor="firstName"
                      className="col-sm-4 col-form-label"
                    >
                      Name
                  </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className={`form-control ${this.state.first_name_req ? 'has-error' : ''}`}
                        id="firstName"
                        placeholder="First name"
                        value={this.state.first_name}
                        onChange={event =>
                          this.setState({ first_name: event.target.value, first_name_req:false })
                        }
                      />
                      {this.state.first_name_req && <span className='text-danger'>Please enter first name</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="offset-sm-4 col-sm-8">
                      <input
                        type="text"
                        className={`form-control ${this.state.last_name_req ? 'has-error' : ''}`}
                        id="lastName"
                        placeholder="Last name"
                        value={this.state.last_name}
                        onChange={event =>
                          this.setState({ last_name: event.target.value, last_name_req:false })
                        }
                      />
                      {this.state.last_name_req && <span className='text-danger'>Please enter last name</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="department" className="col-sm-4 col-form-label">
                      Department
                  </label>
                    <div className="col-sm-8">
                      <select
                        id="department"
                        className={`form-control ${this.state.department_req ? 'has-error' : ''}`}
                        value={this.state.department_id}
                        onChange={event =>
                          this.setState({ department_id: event.target.value, department_req:false })
                        }
                      >
                        <option hidden>Select department</option>
                        {this.state.departments.map(department => (
                          <option
                            key={department.department_id}
                            value={department.department_id}
                            className="text-dark"
                          >
                            {department.department_name}
                          </option>
                        ))}
                      </select>
                      {this.state.department_req && <span className='text-danger'>Please select department</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="role" className="col-sm-4 col-form-label">
                      Role
                  </label>
                    <div className="col-sm-8">
                      <select
                        id="role"
                        className="form-control"
                        value={this.state.role_id}
                        onChange={event =>
                          this.setState({ role_id: event.target.value })
                        }
                      >
                        <option hidden>Select Role</option>
                        {this.state.roles.map(role => (
                          <option
                            key={role.role_id}
                            value={role.role_id}
                            className="text-dark"
                          >
                            {role.role_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="newUserName" className="col-sm-4 col-form-label">
                      Username
                  </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className={`form-control ${this.state.username_req ? 'has-error' : ''}`}
                        id="newUserName"
                        autoComplete="newUserName"
                        placeholder="Username"
                        value={this.state.username}
                        onChange={event =>
                          this.setState({ username: event.target.value, username_req:false })
                        }
                      />
                      {this.state.username_req && <span className='text-danger'>Please enter user name</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="email" className="col-sm-4 col-form-label">
                      Email
                  </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className={`form-control ${this.state.email_req || this.state.valid_email ? 'has-error' : ''}`}
                        id="email"
                        autoComplete="email"
                        placeholder="Email"
                        value={this.state.user_email}
                        onChange={event =>
                          this.setState({ user_email: event.target.value, email_req:false, valid_email: false })
                        }
                      />
                      {this.state.email_req && <span className='text-danger'>Please enter email</span>}
                      {this.state.valid_email && <span className='text-danger'>Please enter vaild email</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="start_end_date" className="col-sm-4 col-form-label">
                      Start Date
                  </label>
                    <div className="col-sm-8">
                      {
                        this.state.start_date != "" && this.state.start_date != null
                          ? (
                            <DatePicker
                              id="end_date"
                              name="start_date"
                              disabledDate={this.disabledStartDate}
                              bordered={true}
                              placeholder="Select start date"
                              value={moment(this.state.start_date)}
                              onChange={(date, dateString) => this.handleUserStartDate(date, dateString)}
                            />
                          ) : (<DatePicker
                            id="end_date"
                            name="end_date"
                            disabledDate={this.disabledStartDate}
                            bordered={true}
                            onChange={(date, dateString) => this.handleUserStartDate(date, dateString)}
                          />)
                      }
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="start_end_date" className="col-sm-4 col-form-label">
                      End Date
                  </label>
                    <div className="col-sm-8">
                      {
                        (!this.state.end_date ? (
                          <DatePicker
                            id="end_date"
                            name="end_date"
                            disabledDate={this.disabledDate}
                            bordered={true}
                            placeholder="Select end date"
                            onChange={(date, dateString) => this.handleUserEndDate(date, dateString)}
                          />
                        ) : (this.state.end_date != null && this.state.enable_user_end_date == null && moment().isBefore(this.state.enable_user_end_date)
                          ? (
                            <DatePicker
                              id="end_date"
                              name="end_date"
                              bordered={true}
                              disabledDate={this.disabledDate}
                              placeholder="Select end date"
                              value={moment(this.state.end_date)}
                              onChange={(date, dateString) => this.handleUserEndDate(date, dateString)}
                            />
                          ) : (this.state.end_date != null && this.state.enable_user_end_date != null && moment().isBefore(this.state.enable_user_end_date)) ? (<span style={{ color: "rgba(0, 0, 0, 0.65)", padding: "0px 10px" }}>{moment(this.state.enable_user_end_date).format("YYYY-MM-DD")}</span>) : (this.state.end_date != null && moment().isAfter(this.state.end_date)
                            ? (
                              <span style={{ color: "rgba(0, 0, 0, 0.65)", padding: "0px 10px" }}>{moment(this.state.end_date).format("YYYY-MM-DD")}</span>)
                            : ((this.state.end_date != null && this.state.enable_user_end_date == null && moment().isBefore(this.state.end_date) ? (<DatePicker
                              id="end_date"
                              name="end_date"
                              disabledDate={this.disabledDate}
                              bordered={true}
                              placeholder="Select end date"
                              value={moment(this.state.end_date)}
                              onChange={(date, dateString) => this.handleUserEndDate(date, dateString)}
                            />) : null))
                          )
                          )
                        )
                      }
                    </div>
                  </div>
                  {errorAlert}
                  <div className="form-group row justify-content-end mt-3" >
                    <div className="col-auto">
                      {moment().isBefore(this.state.existing_end_date) ? (
                        <button
                          type="button"
                          className="btn btn-outline-danger mr-3"
                          onClick={() => this.showModal(this.props.match.params.id)}
                        >
                          Disable User
                        </button>
                      ) : null}

                      {moment().isAfter(this.state.existing_end_date) ? (
                        <button
                          type="button"
                          className="btn btn-outline-danger mr-3"
                          onClick={() => this.showEnableModal(this.props.match.params.id)}
                        >
                          Enable User
                        </button>
                      ) : null}
                      <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={this.updateUserHandler}
                      >
                        Save User
                    </button>
                      <Link to="/users">
                        <button type="button" className="btn btn-light">
                          Cancel
                      </button>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(EditUser);

export function IsNullOrEmptyString(e) {
  switch (e) {
      case "":
      case 0:
      case "0":
      case null:
      case false:
      case undefined:
      case typeof this == "undefined":
          return true;
      default:
          return false;
  }
}