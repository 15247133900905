import renderEmpty from "antd/lib/config-provider/renderEmpty";
import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

export default function ClientsFilter({
  selectedClientID,
  clients,
  onClientChange,
  disabled
}) {
  
  let selectedClientName;
  

  let selectedClient = clients.find(client => client.client_id === selectedClientID);

  if (selectedClient) {
    selectedClientName = selectedClient.client_name;
  }
  
  if(selectedClientID === 0)
  {
    selectedClientName  = "All";
  }


  return (
    <UncontrolledDropdown className="d-inline">
      <DropdownToggle
        className="rounded-0 py-0 mr-1 shadow  expand-collapse"
        disabled={disabled}
        color="body"
        caret
      >
        {selectedClientName}
      </DropdownToggle>
      <DropdownMenu style={{maxHeight:"400px",overflow:"auto"}}>
      <DropdownItem active={ selectedClientName === "All" }  onClick={() => onClientChange(0)}>All</DropdownItem>
        {clients.map(client => (
          <DropdownItem
            active={client.client_id === selectedClientID}
            onClick={() => onClientChange(client)}
          >
            {client.client_name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
