import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import InputColor from 'react-input-color';
import { SwatchesPicker } from 'react-color';
import { SketchPicker } from 'react-color';
import { DatePicker } from "antd";

import moment from "moment";

import 'antd/dist/antd.css';
import {
  Alert,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";


const { RangePicker } = DatePicker;
class EditClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_name: null,
      address_1: null,
      address_2: null,
      city: null,
      state_prov: null,
      postal_code: null,
      color_primary: null,
      color_secondary: null,
      changePrimaryColors:false,
      changeSecondaryColors:false,
      amber_threshold: 10,
      red_threshold: 30,
      client_logo: null,
      edit_logo:false,
      start_date: {},
      end_date:{},
      timezone_id:0,
      timezones: []
    };
  }

  async componentDidMount() {
    const clientResponse = await axios.get("/clients/"+this.props.match.params.id);
    const timeZoneResponse = await axios.get("/clients/timezones");

    this.setState({timezones:timeZoneResponse.data});
    this.setState(clientResponse.data);

  }

  primaryColorChangeHandler = ({ hex }) => {
    this.setState({ color_primary: hex });
  };

  secondaryColorChangeHandler = ({ hex }) => {
    this.setState({ color_secondary: hex });
  };

  fileSelectedHandler = event =>{
    //@TODO: add filter for file size
    this.getBase64(event.target.files[0], (result) => { console.log(result)
      this.setState({ client_logo: result, edit_logo:false});
    });
  }

  checkDateValidation = (startDate, endDate) => {
    
    if (moment(endDate).isSameOrBefore(startDate)) {
      this.setState({ errorMessage: "End date should be gretater than start date." });
      return false;
    }

    this.setState({ errorMessage: null });

    return true;
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    if(!!file){
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    cb("")
  }

  handleUserEndDate = (date,dateString) =>{
    
    this.setState({end_date : dateString});
     //CHECK DATE VALIDATION
     if (!this.checkDateValidation(this.state.start_date, this.state.end_date)) {
      return;
    }
  }
  handleUserStartDate = (date,dateString) =>{
    
    this.setState({start_date : dateString});
     //CHECK DATE VALIDATION
     if (!this.checkDateValidation(date, this.state.end_date)) {
      return;
    }
  }
  

  updateClientHandler = () => {
    const client = {
      client_name: this.state.client_name,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      city: this.state.city,
      state_prov: this.state.state_prov,
      postal_code: this.state.postal_code,
      color_primary: this.state.color_primary,
      color_secondary: this.state.color_secondary,
      amber_threshold: this.state.amber_threshold,
      red_threshold: this.state.red_threshold,
      client_logo: this.state.client_logo,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      timezone_id: this.state.timezone_id,
      errorMessage : null
    };

     //CHECK DATE VALIDATION
     if (!this.checkDateValidation(this.state.start_date, this.state.end_date)) {
      return;
    }

    axios
      .put("/clients/"+ this.props.match.params.id, client)
      .then(res => {
        this.props.history.push("/clients");
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  render() {

    let errorAlert = null;
    if (this.state.errorMessage) {
      errorAlert = (
        <Row className="mt-3 mb-0">
          <Col>
            <Alert color="danger" className="mb-0">
              {this.state.errorMessage}
            </Alert>
          </Col>
        </Row>
      );
    }


    return (
      <div className="row animated slideInRight fast">
        <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
          <div className="card card-accent-primary">
            <div className="card-body">
              <h3>Edit Client</h3>
              <form className="mt-3">
                <div className="form-group row">
                  <label for="clientName" className="col-sm-4 col-form-label">
                    Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="clientName"
                      placeholder="Name"
                      value={this.state.client_name}
                      onChange={event =>
                        this.setState({ client_name: event.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label for="client_logo" className="col-sm-4 col-form-label">
                    Client Logo
                  </label>
                  <div className="col-sm-8">
                    {!!this.state.client_logo && !this.state.edit_logo &&
                    <React.Fragment>
                      <img src={this.state.client_logo} width="150px"></img> <span>   </span>
                      <a onClick={()=>this.setState({edit_logo : !this.state.edit_logo})}><i className="fas fa-edit" ></i></a> <span>   </span>
                      <a onClick={()=>this.setState({client_logo : null})}><i className="fas fa-trash" ></i></a>
                    </React.Fragment>
                    }
                    {(!this.state.client_logo || this.state.edit_logo) &&
                      <input type="file" onChange={this.fileSelectedHandler}/>
                    }
                  </div>
                </div>

                <div className="form-group row color">
                  <label
                    htmlFor="color_primary"
                    className="col-sm-3 col-form-label"
                  >
                    Colors
                  </label>
                  <label
                    htmlFor="secondary_color"
                    className="col-form-label"
                  >
                    Primary
                  </label>
                  <div className="col-sm-3 ">
                    {!!this.state.color_primary &&
                    (
                      !this.state.changePrimaryColors ? (
                        <a onClick={() => this.setState({changePrimaryColors: !this.state.changePrimaryColors})}>
                          <InputColor initialHexColor={this.state.color_primary} placement="right"/>
                          <span>   </span><i className="fas fa-edit"></i>
                        </a>
                      ) : (
                        <React.Fragment>
                          <a onClick={() => this.setState({changePrimaryColors: !this.state.changePrimaryColors})}><i
                            className="fas fa-check"></i></a>
                          <SketchPicker
                            className="mt-sm-4"
                            color={this.state.color_primary}
                            onChangeComplete={this.primaryColorChangeHandler}
                          />
                        </React.Fragment>
                      )
                    )}
                  </div>
                  <label
                    htmlFor="secondary_color"
                    className="col-form-label"
                  >
                    Secondary
                  </label>
                  <div className="col-sm-3">
                    {!!this.state.color_secondary &&
                    (
                      !this.state.changeSecondaryColors ? (
                        <a onClick={() => this.setState({changeSecondaryColors: !this.state.changeSecondaryColors})}>
                          <InputColor initialHexColor={this.state.color_secondary} placement="right"/>
                          <span>   </span><i className="fas fa-edit"></i>
                        </a>
                      ) : (
                        <React.Fragment>
                          <a
                            onClick={() => this.setState({changeSecondaryColors: !this.state.changeSecondaryColors})}><i
                            className="fas fa-check"></i></a>
                          <SketchPicker
                            className="mt-sm-4"
                            color={this.state.color_secondary}
                            onChangeComplete={this.secondaryColorChangeHandler}
                          />
                        </React.Fragment>
                      )
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="amber_threshold"
                    className="col-sm-3 col-form-label"
                  >
                    Threshold
                  </label>
                  <label
                    htmlFor="amber_threshold"
                    className="col-form-label"
                  >
                    Amber
                  </label>
                  <div className="col-sm-3 input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Amber Threshold %"
                      id="amber_threshold"
                      value={this.state.amber_threshold}
                      onChange={event =>
                        this.setState({amber_threshold: event.target.value})
                      }
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <label
                    htmlFor="red_threshold"
                    className="col-form-label"
                  >
                    Red
                  </label>
                  <div className="col-sm-3 input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Red Threshold %"
                      id="red_threshold"
                      value={this.state.red_threshold}
                      onChange={event =>
                        this.setState({red_threshold: event.target.value})
                      }
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label for="address1" className="col-sm-4 col-form-label">
                    Address
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Line 1"
                      id="address1"
                      value={this.state.address_1}
                      onChange={event =>
                        this.setState({ address_1: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="offset-sm-4 col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Line 2"
                      id="address2"
                      value={this.state.address_2}
                      onChange={event =>
                        this.setState({ address_2: event.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="offset-sm-4 col-sm-8">
                    <input
                      type="text"
                      placeholder="City"
                      className="form-control"
                      id="city"
                      value={this.state.city}
                      onChange={event =>
                        this.setState({ city: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="offset-sm-4 col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      placeholder="State/Province"
                      value={this.state.state_prov}
                      onChange={event =>
                        this.setState({ state_prov: event.target.value })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Zip Code"
                      id="zip"
                      value={this.state.postal_code}
                      onChange={event =>
                        this.setState({ postal_code: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="start_date" className="col-sm-4 col-form-label">
                    Start Date
                  </label>
                    <div className="col-sm-8">
                    {
                      this.state.start_date != ""&& this.state.start_date != null
                        ? (
                          <DatePicker
                            id="start_date"
                            name="start_date"
                            bordered={true}
                            placeholder="Select start date"
                            value={moment(this.state.start_date, 'YYYY-MM-DD')}
                            onChange={(date, dateString) => this.handleUserStartDate(date, dateString)}
                          />
                        ) : (<DatePicker
                          id="start_date"
                          name="start_date"
                          bordered={true}
                          placeholder="Select start date"
                          onChange={(date, dateString) => this.handleUserStartDate(date, dateString)}
                        />)
                    }
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="end_date" className="col-sm-4 col-form-label">
                    End Date
                  </label>
                    <div className="col-sm-8">
                    {
                      this.state.end_date != "" && this.state.end_date != null 
                        ? (
                          <DatePicker
                            id="end_date"
                            name="end_date"
                            bordered={true}
                            placeholder="Select end date"
                            value={moment(this.state.end_date, 'YYYY-MM-DD')}
                            onChange={(date, dateString) => this.handleUserEndDate(date, dateString)}
                          />
                        ) : (<DatePicker
                          id="end_date"
                          name="end_date"
                          bordered={true}
                          onChange={(date, dateString) => this.handleUserEndDate(date, dateString)}
                        />)
                    }
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="timezone" className="col-sm-4 col-form-label">
                    TimeZone
                  </label>
                  <div className="col-sm-8">
                    <select
                      id="timezone"
                      className="form-control"
                      value={this.state.timezone_id}
                      onChange={event =>
                        this.setState({ timezone_id: event.target.value })
                      }
                    >
                      <option hidden>Select Timezone</option>
                      {this.state.timezones.map(timzone => (
                        <option
                          key={timzone.timezone_id}
                          value={timzone.timezone_id}
                          className="text-dark"
                        >
                          {timzone.description}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {errorAlert}
                <div className="form-group row justify-content-end mt-3">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary mr-3"
                      onClick={this.updateClientHandler}
                    >
                      Save Client
                    </button>
                    <Link to="/clients">
                      <button type="button" className="btn btn-light">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditClient);
