import React, { Component } from "react";
import colors from "../assets/colors/colors";
import { Link } from "react-router-dom";
import axios from "axios";

export default class Objective extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorPrimary: props.user.color_primary || colors.primary,
      colorSecondary: props.user.color_secondary || colors.secondary
    };
  }

  render() {
    let backgroundPrimary = { backgroundColor: this.state.colorPrimary };
    let textPrimary = { color: this.state.colorPrimary };
    const fadeAnimation = this.state.isNew
      ? "animated fadeInRight fast"
      : "animated fadeIn faster";
    return (
      <React.Fragment>
        <div className={fadeAnimation + " my-2 objective"}>
          <div
            className="row shadow--dark py-1 text-white"
            style={backgroundPrimary}
          >
            <div
              className="objective-desc col my-auto mr-auto d-flex"
              title="Objective"
            >
              <p className="h4 text-uppercase my-1">{this.props.description}</p>
            </div>
            <div className="col-auto my-auto">
              <React.Fragment>
                <Link to={"/objectives/" + this.props.element_id}>
                  <button
                    className="btn bg-body rounded-0 px-0 py-0 mr-2  edit"
                    onClick={() => this.setState({ editMode: true })}
                    style={textPrimary}
                  >
                    <i className="fas fa-edit fa-fw"></i>
                  </button>
                </Link>
                <button
                  className="btn bg-body rounded-0 px-0 py-0  delete"
                  onClick={() =>
                    this.props.deleteObjective(this.props.element_id, this.props.child_count)
                  }
                  style={textPrimary}
                >
                  <i className="fas fa-minus fa-fw"></i>
                </button>
              </React.Fragment>
            </div>
          </div>

          <div className="row input-value px-3 pt-3">
            <p>{this.props.resources}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
