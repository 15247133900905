import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { Collapse } from "reactstrap";
import axios from "axios";
import moment from "moment";

import "./Strategy.css";
import Action from "./Action";
import RagInput from "./RagInput";
import ChampionSelect from "./ChampionSelect";
import colors from "../assets/colors/colors";
import {
  childToggleHandler,
  childAddHandler,
  generateChildElementsCollapse,
  generateChildElementIds
} from "./childUtils";

export default class Strategy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorPrimary: props.user.color_primary || colors.primary,
      colorSecondary: props.user.color_secondary || colors.secondary,
      element_id: props.element_id,
      description: props.description,
      descriptionUnedited: props.description,
      start_date_time: props.start_date_time
        ? moment.parseZone(props.start_date_time)
        : null,
      end_date_time: props.end_date_time
        ? moment.parseZone(props.end_date_time)
        : null,
      focusedInput: null,
      champion_user_id: props.champion_user_id,
      champion_user_id_unedited: props.champion_user_id,
      childElements: props.childElements,
      childElementsCollapse: generateChildElementsCollapse(props.childElements),
      actionIds: generateChildElementIds(props.childElements),
      isEditing: false
    };

    this.childToggleHandler = childToggleHandler.bind(this);
    this.childAddHandler = childAddHandler.bind(this);
  }

  componentDidMount() {
    if (this.props.isNew && !this.props.toggleSorting) {
      this.props.onChildAdded(this.state.element_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.description !== prevProps.description ||
      this.props.champion_user_id !== prevProps.champion_user_id ||
      this.props.start_date_time !== prevProps.start_date_time ||
      this.props.end_date_time !== prevProps.end_date_time ||
      this.props.percent_complete !== prevProps.percent_complete ||
      this.props.rag_status !== prevProps.rag_status
    ) {
      this.setState({
        description: this.props.description,
        champion_user_id: this.props.champion_user_id,
        start_date_time: this.props.start_date_time
          ? moment.parseZone(this.props.start_date_time)
          : null,
        end_date_time: this.props.end_date_time
          ? moment.parseZone(this.props.end_date_time)
          : null,
        percent_complete: this.props.percent_complete,
        rag_status: this.props.rag_status,
        
      });
    }
  }

  autoSave = () => {
    const element = {
      element_id: this.state.element_id,
      description: this.state.description,
      champion_user_id: this.state.champion_user_id
    };
    axios
      .put("/element/" + element.element_id, element)
      .then(() => {
        this.onSave();
        if(!this.props.toggleSorting)
        {
          this.props.onChildExpanded();
        }
      })
      .catch(error => {
        console.log(error.response.data.message);
        alert("There was a problem saving. Please try again.");
        this.onCancel();
      });
  };

  onEditToggle = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  onCancel = () => {
    this.setState({
      champion_user_id: this.state.champion_user_id_unedited,
      description: this.state.descriptionUnedited
    });
    this.onEditToggle();
  };

  onSave = () => {
    this.setState({
      champion_user_id_unedited: this.state.champion_user_id,
      descriptionUnedited: this.state.description
    });
    this.onEditToggle();
  };

  userChangeHandler = user => {
    this.setState({ champion_user_id: user.user_id });
  };

  handleActionAdded = () => {
    this.props.onCreateActionClicked();
    if (this.props.collapse) {
      this.props.onToggle(this.state.element_id);
    }
  };

  renderEditSaveBtn = background => {
    return this.state.isEditing ? (
      <button
        className="btn rounded-0 px-0 py-0 mr-2 shadow text-white  add"
        onClick={this.autoSave}
        style={background}
      >
        <i className="fas fa-check fa-fw"></i>
      </button>
    ) : (
      <button
        className="btn rounded-0 px-0 py-0 mr-2 shadow text-white  add"
        onClick={this.onEditToggle}
        style={background}
      >
        <i className="fas fa-edit fa-fw"></i>
      </button>
    );
  };

  renderMinusCancelBtn = background => {
    return this.state.isEditing ? (
      <button
        className="btn rounded-0 px-0 py-0 shadow text-white  add"
        onClick={this.onCancel}
        style={background}
      >
        <i className="fas fa-times fa-fw"></i>
      </button>
    ) : (
      <button
        className="btn rounded-0 px-0 py-0 shadow text-white  add"
        onClick={() => this.props.onDeleteClicked(this, "strategy")}
        style={background}
      >
        <i className="fas fa-minus fa-fw"></i>
      </button>
    );
  };

  renderInput = () => {
    return this.state.isEditing ? (
      <input
        type="text"
        className="font-lg form-control-plaintext ml-1 pl-1  input-toggled"
        value={this.state.description}
        onChange={event => {
          this.setState({
            description: event.target.value
          });
        }}
      />
    ) : (
      <p className="font-lg form-control-plaintext ml-2">
        {this.state.descriptionUnedited}
      </p>
    );
  };

  render() {
    let backgroundPrimary = { backgroundColor: this.state.colorPrimary };
    let textSecondary = { color: this.state.colorSecondary };
    const actionCount = this.props.childElements.length;

    const actions = this.props.childElements.map(action => {
      const isNew = !this.state.actionIds.includes(action.element_id);
      const collapse = this.state.childElementsCollapse[action.element_id];

      return (
        <React.Fragment key={action.element_id}>
          <Action
            collapse={collapse}
            key={action.element_id}
            isNew={isNew}
            {...action}
            user={this.props.user}
            users={this.props.users}
            onChildAdded={this.childAddHandler}
            onToggle={this.childToggleHandler}
            onActionChange={this.props.onActionChange}
            onChildExpanded={this.props.onChildExpanded}
            onDeleteClicked={this.props.onDeleteClicked}
            StrategyAssignedTologgedInUser = {this.state.isStrategyAssignedtoLoggedInuser}
            GoalAssignedTologgedInUser = {this.props.GoalAssignedTologgedInUser}
            toggleSorting ={this.props.toggleSorting}
          />
        </React.Fragment>
      );
    });

    const fadeAnimation = this.props.isNew ? " animated fadeInRight" : "";
    return (
      <div className={`ml-4`}>
        <div className="row shadow--inset py-2  strategy">
          <div
            className="col my-auto mr-auto d-flex align-items-center"
            title="Strategy"
          >
            <div
              className="font-lg mb-0 pr-2 font-weight-bold text-uppercase  objective--br"
              style={textSecondary}
            >
            </div>
            {this.renderInput()}
          </div>
          <div className="col-auto my-auto">
            <ChampionSelect
              disabled={!this.state.isEditing}
              selectedUserId={this.state.champion_user_id}
              users={this.props.users}
              onUserChange={this.userChangeHandler}
            ></ChampionSelect>
          </div>

          <div className="col-auto my-auto">
            <RagInput
              rag_status={this.props.rag_status}
              percent_complete={this.props.percent_complete}
              disabled={true}
            ></RagInput>
          </div>
          <div className="col-auto my-auto">
            <DateRangePicker
              disabled
              startDate={this.state.start_date_time}
              startDateId={"startDate" + this.props.id}
              endDate={this.state.end_date_time}
              endDateId={"endDate" + this.props.id}
              onDatesChange={({ startDate, endDate }) =>
                this.setState({ startDate, endDate })
              }
              displayFormat="M/D/YYYY"
              small
              noBorder
              anchorDirection="right"
            />
          </div>
          <div className="col-auto my-auto">
            <button
              className="btn btn-link text-white rounded-0 py-0 mr-2 shadow  expand-collapse"
              onClick={() => {
               // this.props.onChildExpandedStates(this.state.element_id);
                this.props.onToggle(this.state.element_id);
                if (this.props.collapse) {
                  this.props.onChildExpanded();
                }
              }}
              style={backgroundPrimary}
            >
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span
                    className="mr-1 rounded-sm font-xs font-weight-bold  dropdown-badge"
                    style={{
                      backgroundColor: "white",
                      color: this.state.colorPrimary
                    }}
                  >
                    {actionCount}
                  </span>
                  <span>{actionCount === 1 ? "Action" : "Actions"}</span>
                </div>
                {this.props.collapse ? (
                  <i className="fas fa-chevron-down fa-fw"></i>
                ) : (
                  <i className="fas fa-chevron-up fa-fw"></i>
                )}
              </div>
            </button>
            {!this.props.toggleSorting ? this.renderEditSaveBtn(backgroundPrimary) : null}
            {!this.props.toggleSorting ? this.renderMinusCancelBtn(backgroundPrimary):null}
          </div>
        </div>
        <Collapse isOpen={!this.props.collapse}>
          {actions}

          <div className="row justify-content-end ml-4 py-1">
            {!this.props.toggleSorting ? (
            <button
              className="btn rounded-0 px-0 py-0"
              onClick={this.handleActionAdded}
            >
              <p className="d-inline-block my-0 mr-3">Add Action</p>
              <i
                className="fas fa-plus fa-fw text-white py-1  add add--mr"
                style={backgroundPrimary}
              ></i>
            </button>)
            : null}
          </div>
        </Collapse>
      </div>
    );
  }
}
