import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";

class EmailTemplateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailTemplates: [],
      email_type_1:false,
      email_type_2:false,
      email_type_3:false,
      email_type_4:false,
      email_type_5:false,
      email_type_6:false,
      email_type_7:false,
    };

}

setStateValues = function(emailtemplaetype,isCHecked){

  switch (emailtemplaetype) {
    case 1:
      this.setState({ email_type_1: isCHecked });
      break;
    case 2:
      this.setState({ email_type_2: isCHecked });
      break;
    case 3:
      this.setState({ email_type_3: isCHecked });
      break;
    case 4:
      this.setState({ email_type_4: isCHecked });
      break;
    case 5:
      this.setState({ email_type_5: isCHecked });
      break;
    case 6:
      this.setState({ email_type_6: isCHecked });
      break;
    case 7:
      this.setState({ email_type_7: isCHecked });
      break;
    case 8:
      this.setState({ email_type_8: isCHecked });
      break;
    case 9:
      this.setState({ email_type_9: isCHecked });
      break;
  }
}
  getStateValues = function (emailtemplaetype) {
    let isCheched = false;
    switch (emailtemplaetype) {
      case 1:
        isCheched = this.state.email_type_1;
        break;
      case 2:
        isCheched = this.state.email_type_2;
        break;
      case 3:
        isCheched = this.state.email_type_3;
        break;
      case 4:
        isCheched = this.state.email_type_4;
        break;
      case 5:
        isCheched = this.state.email_type_5;
        break;
      case 6:
        isCheched = this.state.email_type_6;
        break;
      case 7:
        isCheched = this.state.email_type_7;
        break;
      case 8:
        isCheched = this.state.email_type_8;
        break;
      case 9:
        isCheched = this.state.email_type_9;
        break;
    }
  return isCheched;
}

  async componentDidMount() {
    this.getEmailTemplates();
  }

  handleEmailEnabedChange = (event,email_template_id) => {
    
    let emailStats = {
      isChecked : event.target.checked,
      email_template_id: email_template_id
    }

    
    axios
      .put("/emails/UpdateEmailEnabled", emailStats)
      .then(res => {
        this.setStateValues(email_template_id,emailStats.isChecked);
      })
      .catch(error => {
        console.log(error);
        alert("Error occurred while updating email template");
      });

   

  }

  getEmailTemplates = () => {
    axios
      .get(`/emails`)
      .then(res => {
        this.setState({ emailTemplates: res.data });
        let templates = res.data;

        setTimeout(() => {
          templates.forEach(element => {
            let isEnabeld = (element.enabled == 1 ? true : false);
            this.setStateValues(element.email_type_id, isEnabeld);
          });
        }, 200); 


      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.emailTemplates.length > 0 ? (
          <div className="card animated fadeIn faster">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="width-65"> Description</th>
                  <th>Email notification enabled</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.emailTemplates.map(emailTemplate => {
                  return (
                    <tr key={emailTemplate.email_template_id}>
                      <td>{emailTemplate.description}</td>
                      <td>
                        <div className="text-center">
                        <label className = "position-relative"
                          htmlFor={"enabled_"+ emailTemplate.email_template_id}
                        >

                        <input
                                className="checkbox position-absolute"
                                id={"enabled_"+ emailTemplate.email_template_id}
                                name={"enabled_"+ emailTemplate.email_template_id}
                                type="checkbox"
                                defaultChecked={emailTemplate.enabled}
                                onChange={(event) => this.handleEmailEnabedChange(event,emailTemplate.email_template_id)}
                              />
                              <i
                                className={`ml-2 font-lg fas ${
                                  
                                  this.getStateValues(emailTemplate.email_type_id)? "fa-check-square" : "fa-square"
                                } `}
                                aria-hidden="true"
                              ></i>
                        </label>
                        </div></td>
                      <td>
                        <Link to={"/emails/" + emailTemplate.email_template_id}>
                          <button className="btn btn-link py-0">
                            <i className="fas fa-edit"></i>&nbsp;Edit Template
                          </button>
                        </Link>
                      </td>
                    </tr>
                     
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(EmailTemplateList);
