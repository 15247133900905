import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

class CreatePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      belief_statement: "",
      plan_title: "",
      plan_begin_date: moment(new Date()).add(0, "year").startOf("year"),
      vision: "",
      mission: "",
      core_values: "",
      strengths: "",
      weaknesses: "",
      opportunities: "",
      threats: ""
    };
  }

  updatePlanHandler = () => {
    debugger;
    if (!this.state.plan_title || !this.state.plan_begin_date) {
      return;
    }

    const plan = {
      ...this.state
    };
    axios
      .post("/plans/", plan)
      .then(res => {
        this.props.history.push("/plan");
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  render() {
    return (
      <div className="row animated slideInRight fast">
        <div className="col-lg-12 col-xl-10 m-auto">
          <div className="card card-accent-primary">
            <div className="card-body">
              <h3>Create New Strategic Plan</h3>
              <form className="mt-3">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.plan_title}
                      onChange={event =>
                        this.setState({ plan_title: event.target.value })
                      }
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Begin Date</label>
                    <div>
                      <SingleDatePicker
                        className="form-control"
                        date={this.state.plan_begin_date}
                        onDateChange={startDate => {
                          this.setState({
                            plan_begin_date: startDate
                          });
                        }}
                        displayFormat="M/D/YYYY"
                        isOutsideRange={() => false}
                        small
                        focusedInput={this.state.focusedInput}
                        onFocusChange={focusedInput =>
                          this.setState({ focusedInput })
                        }
                        verticalSpacing={20}
                        anchorDirection="right"
                        hideKeyboardShortcutsPanel
                      />
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="form-group">
                  <label>Belief Statement</label>
                  <textarea
                    className="form-control"
                    value={this.state.belief_statement}
                    onChange={event =>
                      this.setState({ belief_statement: event.target.value })
                    }
                    rows={2}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Vision</label>
                  <textarea
                    className="form-control"
                    value={this.state.vision}
                    onChange={event =>
                      this.setState({ vision: event.target.value })
                    }
                    rows={2}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Mission</label>
                  <textarea
                    className="form-control"
                    value={this.state.mission}
                    onChange={event =>
                      this.setState({ mission: event.target.value })
                    }
                    rows={2}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Core Values</label>
                  <textarea
                    className="form-control"
                    value={this.state.core_values}
                    onChange={event =>
                      this.setState({ core_values: event.target.value })
                    }
                    rows={2}
                  ></textarea>
                </div>
                <hr></hr>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Strengths</label>
                    <textarea
                      className="form-control"
                      value={this.state.strengths}
                      onChange={event =>
                        this.setState({ strengths: event.target.value })
                      }
                      rows={3}
                    ></textarea>
                  </div>
                  <div className="form-group col">
                    <label>Weaknesses</label>
                    <textarea
                      className="form-control"
                      value={this.state.weaknesses}
                      onChange={event =>
                        this.setState({ weaknesses: event.target.value })
                      }
                      rows={3}
                    ></textarea>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Opportunities</label>
                    <textarea
                      className="form-control col"
                      value={this.state.opportunities}
                      onChange={event =>
                        this.setState({ opportunities: event.target.value })
                      }
                      rows={3}
                    ></textarea>
                  </div>
                  <div className="form-group col">
                    <label>Threats</label>
                    <textarea
                      className="form-control"
                      value={this.state.threats}
                      onChange={event =>
                        this.setState({ threats: event.target.value })
                      }
                      rows={3}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row justify-content-end">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-primary mr-3"
                      onClick={this.updatePlanHandler}
                    >
                      Save Plan
                    </button>
                    <Link to="/plan">
                      <button type="button" className="btn btn-light">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* {JSON.stringify(this.state, null, 2)} */}
      </div>
    );
  }
}

export default withRouter(CreatePlan);
