import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

class Departments extends Component {
  constructor(props) {
    super(props);
    this.state = {  departments: [], clientLookup: {} };
  }
  async componentDidMount() {
    const [
      departmentsResponse,
      clientsResponse
    ] = await Promise.all([
      axios.get("/departments"),
      axios.get("/clients"),
    ]);
    const clientLookup = {};
    clientsResponse.data.forEach(client => {
      clientLookup[client.client_id] = client.client_name;
    });
    const departments = departmentsResponse.data;
    this.setState({
      departments,
      clientLookup
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-auto mr-auto">
            <h2>Departments</h2>
          </div>
          <div className="col-auto">
            <Link to="/departments/create">
              <button className="btn btn-outline-primary mr-2 shadow float-right">
                <i className="fas fa-user-plus"></i>&nbsp;New Department
              </button>
            </Link>
          </div>
        </div>
        {this.state.departments.length > 0 ? (
          <div className="card animated fadeIn faster">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Department</th>
                  <th>Client</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.departments.map(department => {
                  return (
                    <tr key={department.department_id}>
                      <td>{department.department_name}</td>
                      <td> {this.state.clientLookup[department.client_id]}</td>
                      <td>
                          <Link to={"/departments/" + department.department_id}>
                            <button className="btn btn-link py-0">
                              <i className="fas fa-user-edit"></i>&nbsp;Edit Department
                            </button>
                          </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Departments;
