import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";

import axios from "axios";
import { saveAs } from 'file-saver';

import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import Objective from "./Objective";
import DepartmentFilter from "./DepartmentFilter";
import QuarterFilters from "./QuarterFilters";
import Timeline from "./Timeline";
import TimelineToggle from "./TimelineToggle";

import YearFilter from "./YearFilter";
import "./quarterlyView.scss";
import logo from "../assets/img/L2G_Logo_draft.png";
import alertMatrix from "./alertMatrix";
import ChampionFilter from "./ChampionFilter";

import html2canvas from 'html2canvas';
import pdfConverter from "jspdf";

var path =  require('path');

const EmailTemplateType = {
  ActionCompletion: 1,
  StrategyCompletion: 2,
  GoalCompletion: 3,

};

class QuarterlyView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertTimeout: 5000,
      timerId: null,
      itemToDelete: null,
      typeToDelete: "",
      isAlertOpen: false,
      alertColor: "",
      alertMessage: "",
      isModalOpen: false,
      showTimeline: false,
      objectives: [],
      users: [],
      year: new Date().getFullYear(),
      quarter: Math.floor(new Date().getMonth() / 3) + 1,
      prevQuarter: null,
      departments: [],
      department_name: props.user.department_name,
      department_id: props.user.department_id,
      years: [new Date().getFullYear()],
      primaryColor: props.user.color_primary,
      secondaryColor: props.user.color_secondary,
      champion_user_id: props.user.user_id,
      champion_user_name: props.user.first_name + ' ' + props.user.last_name,
      toggleSorting: false,
      timelineState: false
    };
  }

  async componentDidMount() {
    const [
      usersResponse,
      clientsResponse,
      departmentsResponse
    ] = await Promise.all([
      axios.get("/users"),
      axios.get(`/clients/${this.props.user.client_id}/years`),
      axios.get("/clients/departments")
    ]);

    this.setState({
      users: usersResponse.data,
      years: clientsResponse.data.map(y => y.client_year),
      departments: departmentsResponse.data
    });


    this.getElements();

    console.log(this.state.champion_user_id);
    
  }

  onCancel = () => {
    this.onEditToggle();
  };

  onEditToggle = () => {
    this.setState({ toggleSorting: !this.state.toggleSorting });
  };

  sortObjectives = () => {
    this.onEditToggle();
  }

  renderAdminButtons = () => {
    let backgroundPrimary = { backgroundColor: this.state.primaryColor };

    return this.props.user.role_id === 1 ? (
      <div className="my-auto admin-buttons">
        {this.state.toggleSorting && (
          <i
            className="fas fa-check float-right p-1 mr-1 text-white cursor-pointer"
            style={backgroundPrimary}
            onClick={this.sortObjectives}
          ></i>
        )}
        <i
          className={
            this.state.toggleSorting
              ? "fas fa-times float-right p-1 mr-2 text-white cursor-pointer"
              : "fas fa-edit float-right p-1 mr-1 text-white cursor-pointer"
          }
          style={backgroundPrimary}
          onClick={this.state.toggleSorting ? this.onCancel : this.onEditToggle}
        ></i>
      </div>
    ) : null;
  };

  userChangeHandler = user => {
    if(this.state.showTimeline) {
      this.setState({ timelineState: true});
    } else {
      this.setState({ timelineState: false});
    }
    
    this.setState({ champion_user_id: (user !== 0 ? user.user_id : 0), champion_user_name: (user.first_name !== undefined ? user.first_name + ' ' + user.last_name : 'All')}, () => this.getElements());
  };

  actionTaskCompletion = (currentObjective) => {

    try {
      const goal = currentObjective;
      const strategy = goal.childElements[0];
      const action = strategy.childElements[0];

      let elementIDs = [];

      let ccemailList = [];
      let toEmailAddress = '';

      //ADD CURRENT ACTION ASSIGNED CHAMPION
      elementIDs.push(action.element_id);

      //ADD CURRENT ACTION ASSIGNED CHAMPION
      elementIDs.push(strategy.element_id);

      //GET CHAMPION USER INFO BY ELEMENT ID
      axios.get("/elements/sendActionCompletionEmail", {
        params: {
          elementIDs: elementIDs.toString(),
          currentElementID: action.element_id,
          parentElementID: action.parent_id,
          emailTypeID: EmailTemplateType.ActionCompletion,
          currentOwnerID: action.champion_user_id
        }
      }).then(res => {
        if (res.status == 400) {

        }
        else if (res.status == 200) {
          this.setCustomAlert("warning");
          this.setState({
            isAlertOpen: true
          });
        }
      });
    }
    catch (err) {
      this.setCustomAlert("error");
      this.setState({
        isAlertOpen: true
      });
    }
  }

  sortGoals = (element_id,order) => {

    let objective = this.state.objectives.filter(element => {
      return element.element_id === element_id;
    });

    let goals = [...objective[0].childElements];

    goals.sort((a, b) => {
      const a_index = order.indexOf(a.element_id),
        b_index = order.indexOf(b.element_id);

      if (a_index < b_index) {
        return -1;
      } else if (a_index > b_index) {
        return 1;
      } else {
        return 0;
      }
    });

    var sortedObj = {
      elementIds : order.toString()
    };
    
    axios.post("/objectiveSortOrder",sortedObj).then(x=>{
      console.log("Objective order updated successfully!");
    }).catch(err=>{console.log(err)});
  

    objective[0].childElements = goals;

    this.setState({ objectives: [...this.state.objectives] });
  }

  sortStrategy = (objective_element_id,goal_id,order) => {

    let objective = this.state.objectives.filter(element => {
      return element.element_id === objective_element_id;
    });

     let goals = [...objective[0].childElements];

    let filterdGoals = goals.filter(element => {
      return element.element_id === goal_id;
    });

     let strategies = [...filterdGoals[0].childElements]

     strategies.sort((a, b) => {
      const a_index = order.indexOf(a.element_id),
        b_index = order.indexOf(b.element_id);

      if (a_index < b_index) {
        return -1;
      } else if (a_index > b_index) {
        return 1;
      } else {
        return 0;
      }
    });

    var sortedObj = {
      elementIds : order.toString()
    };
    
    axios.post("/objectiveSortOrder",sortedObj).then(x=>{
      console.log("Objective order updated successfully!");
    }).catch(err=>{console.log(err)});
    
    goals.filter(x=> x.element_id ==goal_id).map(y=>y.childElements = strategies);

    objective.filter(x=> x.element_id ==objective_element_id).map(y=>y.childElements = goals);
    
    //objective[0].childElements = goals;

    this.setState({ objectives: [...this.state.objectives] });
  }




  stretegyTaskCompletion = (currentObjective) => {

    try {
      const goal = currentObjective;
      const strategy = goal.childElements[0];
      const action = strategy.childElements[0];

      let elementIDs = [];

      //ADD CURRENT ACTION ASSIGNED CHAMPION
      elementIDs.push(strategy.element_id);

      //ADD CURRENT ACTION ASSIGNED CHAMPION
      elementIDs.push(goal.element_id);

      //GET CHAMPION USER INFO BY ELEMENT ID
      axios.get("/elements/sendStrategyCompletionEmail", {
        params: {
          elementIDs: elementIDs.toString(),
          currentElementID: strategy.element_id,
          parentElementID: strategy.parent_id,
          emailTypeID: EmailTemplateType.StrategyCompletion,
          currentOwnerID: strategy.champion_user_id
        }
      }).then(res => {
        if (res.status == 400) {
          this.setCustomAlert("error");
          this.setState({
            isAlertOpen: true
          });
        }
        else if (res.status == 200) {
          this.setCustomAlert("warning");
          this.setState({
            isAlertOpen: true
          });
        }
      });
    }
    catch (err) {
      this.setCustomAlert("error");
      this.setState({
        isAlertOpen: true
      });
    }
  }


  goalTaskCompletion = (currentObjective) => {
    try {
      const goal = currentObjective;
      const strategy = goal.childElements[0];
      const action = strategy.childElements[0];

      let elementIDs = [];

      //ADD CURRENT ACTION ASSIGNED CHAMPION
      elementIDs.push(goal.element_id);

      //ADD CURRENT ACTION ASSIGNED CHAMPION
      elementIDs.push(strategy.element_id);

      //GET CHAMPION USER INFO BY ELEMENT ID
      axios.get("/elements/sendGoalCompletionEmail", {
        params: {
          elementIDs: elementIDs.toString(),
          currentElementID: strategy.element_id,
          parentElementID: goal.element_id,
          emailTypeID: EmailTemplateType.GoalCompletion,
          currentOwnerID: goal.champion_user_id
        }
      }).then(res => {
        if (res.status == 400) {
          this.setCustomAlert("error");
          this.setState({
            isAlertOpen: true
          });
        }
        else if (res.status == 200) {
          this.setCustomAlert("error");
          this.setState({
            isAlertOpen: true
          });
        }

      });
    }
    catch (err) {
      this.setCustomAlert("error");
      this.setState({
        isAlertOpen: true
      });
    }
  }


  congratulateChampions = (objectiveData) => {
    const goal = objectiveData;
    const strategy = goal.childElements[0];
    const action = strategy.childElements[0];

    //CHECK IF CURRENT ACTION IS C (RAG_STATUS) => COMPLETED  BY USING RAG STATUS
    if (action.rag_status == "C") {

      this.actionTaskCompletion(objectiveData);

      if (strategy.rag_status == "C") {

        this.stretegyTaskCompletion(objectiveData);

        if (goal.rag_status == "C") {
          this.goalTaskCompletion(objectiveData);
        }
      }
    }
  }


  resetAlertTimer = () => {
    clearTimeout(this.state.timerId);
  };

  componentWillUnmount() {
    this.resetAlertTimer();
  }

  async getElementsData() {
    const quarter = this.state.quarter;
    const year = this.state.year;

    if(typeof this.state.champion_user_id === "undefined"){
      this.setState({champion_user_id : this.props.user.user_id})
    }

    let endpointUrl = `/elements?userId=${this.state.champion_user_id}`;
    if(quarter == -1)
    {
      const startMonth = 1;
      const endMonth = 12;
      const endDate = new Date(year, endMonth, 0);
      const startString = `&startDate=${year}-${startMonth}-01`;
      const endString = `&endDate=${year}-${endMonth}-${endDate.getDate()}`;
      endpointUrl = endpointUrl.concat(startString).concat(endString);
    }
    else if (year && quarter) {
      const startMonth = quarter * 3 - 3;
      const endMonth = startMonth + 3;
      const endDate = new Date(year, endMonth, 0);
      const startString = `&startDate=${year}-${startMonth + 1}-01`;
      const endString = `&endDate=${year}-${endMonth}-${endDate.getDate()}`;
      endpointUrl = endpointUrl.concat(startString).concat(endString);
    }

    debugger;

    try {

      const { data } = await axios.get(endpointUrl, {
        params: { strategic_plan_id: this.props.selectedPlan.strategic_plan_id }
      });

      return data;
    } catch (error) {
      console.log(error);
      alert("Error occurred retrieving elements.");
    }
  }

  getElements = () => {
    debugger;
    this.getElementsData()
      .then(data => {

        this.setState({ showTimeline: false });
        let objectives = [];
        // add collapse prop to objective objects
        data.forEach(objective => {
          objectives.push({
            ...objective,
            collapse: true
          });
        });

        this.setState({
          objectives
        });

        if(this.state.timelineState) { 
          this.setState({ showTimeline: true });
        }
      })
      .catch(error => {
        alert("Error occurred retrieving elements.");
      });
  };


  generatePDF = () => {
    const quarter = this.state.quarter;
    const year = this.state.year;
    const departmentName = (this.state.department_name != null && this.state.department_name != "" ? this.state.department_name : "");
    
    if(typeof this.state.champion_user_id === "undefined"){
      this.setState({champion_user_id : this.props.user.user_id})
    }

    let endpointUrl = `?userId=${this.state.champion_user_id}`;
    let filterData = {
     
    }


    if(quarter == -1)
    {
      const startMonth = 1;
      const endMonth = 12;
      const endDate = new Date(year, endMonth, 0);
      const startString = `&startDate=${year}-${startMonth}-01`;
      const endString = `&endDate=${year}-${endMonth}-${endDate.getDate()}`;
      endpointUrl = endpointUrl.concat(startString).concat(endString);


      this.filterData = {
        userId : this.state.champion_user_id,
        startDate: `${year}-${startMonth + 1}-01`,
        endDate: `${year}-${endMonth}-${endDate.getDate()}`,
        strategic_plan_id: this.props.selectedPlan.strategic_plan_id,
        department_name: `${departmentName}`,
        quarter: `All`,
        year: `${year}`,
        champion_user: `${this.state.champion_user_name}`
      }
    }
    else if (year && quarter) {
      const startMonth = quarter * 3 - 3;
      const endMonth = startMonth + 3;
      const endDate = new Date(year, endMonth, 0);
      const startString = `&startDate=${year}-${startMonth}-01`;
      const endString = `&endDate=${year}-${endMonth}-${endDate.getDate()}`;
      endpointUrl = endpointUrl.concat(startString).concat(endString);


      this.filterData = {
        userId : this.state.champion_user_id,
        startDate: `${year}-${startMonth + 1}-01`,
        endDate: `${year}-${endMonth}-${endDate.getDate()}`,
        strategic_plan_id: this.props.selectedPlan.strategic_plan_id,
        department_name: `${departmentName}`,
        quarter: `${quarter}`,
        year: `${year}`,
        champion_user: `${this.state.champion_user_name}`
      }
    }

    try {
      axios.post("/elements/ExportToPDF", this.filterData).then(resp => {
        if (resp.data != null || resp.data != "") {
          let fileName = path.basename(resp.data.filename);
          fileName = fileName.toString().replace(/^.*[\\\/]/, '');
          if (fileName != null || fileName != "") {

            axios.get("/elements/fetchPDFFile?file=" + fileName + "", { responseType: 'blob' }).then((res) => {
              const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
              saveAs(pdfBlob, 'StrategyPlan.pdf');

              let file = {
                file :fileName
              };

              axios.post("/elements/deletePDFFile", file).then(resp =>{});
            
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
      alert("Error occurred retrieving elements.");
    }
  }

  exportpdf = () => {

    let input = window.document.getElementById('divToPDF');
    let selectedPlan = window.document.getElementById('selected-plan');
    let fileName = "StrategyPlan_Timeline.pdf";
    html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const doc = new pdfConverter({
        orientation: 'l',
        unit: 'pt',
        format: 'a4'
      });
      const imgProps= doc.getImageProperties(imgData);
      let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      const imgHeight = (imgProps.height * pageWidth) / imgProps.width;
      let heightLeft = imgHeight;

      doc.setFontSize(16);
      doc.text(selectedPlan.innerText, pageWidth / 2, 40, 'center');

      doc.setFontSize(18);
      if(this.state.department_name != null && this.state.department_name != "" ) {
        doc.text(this.state.department_name.toUpperCase() , pageWidth / 2, 65, 'center');
      }
      
      doc.setFontSize(12);
      doc.text('Champion: ' + this.state.champion_user_name + '  Quarter: Q'+(this.state.quarter == -1 ? "All" : this.state.quarter)+'-'+ this.state.year, pageWidth / 2, 90, 'center');

      // if(imgHeight.toFixed(0) > 1095) {
        doc.addImage(imgData, 'JPEG', 12, 100, (pageWidth - 25), (imgHeight + 25));
        heightLeft -= pageHeight;
        let position = 0;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'JPEG', 12, position, (pageWidth - 25), (imgHeight + 25));
          heightLeft -= pageHeight;
        }
      // } else {
      //   doc.addImage(imgData, 'JPEG', 12, 60, (pageWidth - 25), (imgHeight - 50));
      // }
    doc.save(fileName);
    });
  }

  startAlertTimer = () => {
    this.resetAlertTimer();
    let timerId = setTimeout(this.alertTimer, this.state.alertTimeout);
    this.setState({ timerId });
  };

  alertTimer = () => {
    this.toggleAlert();
  };

  deptChangeHandler = ({ department_id, department_name }) => {
    this.setState({ department_id, department_name }, () => this.getElements());
  };

  exportToPdfHandler = () => {
    this.generatePDF();
  };

  yearChangedHandler = year => {
    const newState = { year };
    if (!year && this.state.year) {
      newState.quarter = null;
      newState.prevQuarter = this.state.quarter;
    } else if (year && !this.state.quarter) {
      newState.quarter = this.state.prevQuarter;
    }
    this.setState(newState, () => this.getElements());
  };

  quarterChangedHandler = quarter => {
    debugger;
    this.setState({ quarter }, () => this.getElements());
  };

  

  createGoalHandler = objective => {
    
    let goals = [...objective.childElements];
    const goal = {
      parent_id: objective.element_id,
      department_id: this.state.department_id,
      description: "New Goal",
      level_id: 3,
      childElements: [],
      percent_complete: 0,
      duration: 0
    };
    axios.post("/element", goal).then(res => {
      goal.element_id = res.data.id;
      goal.isVisibletoUser = true;
      goals.push(goal);
      objective.childElements = goals;
      this.setState({ objectives: [...this.state.objectives] });
    });
  };

  setAlert = (state, type) => {
    this.setState({
      alertColor: alertMatrix.color[state],
      alertMessage: alertMatrix.getMessage(state, type)
    });
  };

  setCustomAlert = (state, type) => {
    this.setState({
      alertColor: alertMatrix.color[state],
      alertMessage: alertMatrix.getEmailMessage(state, type)
    });
  };

  toggleAlert = () => {
    this.resetAlertTimer();
    this.setState({ isAlertOpen: !this.state.isAlertOpen });
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  refreshChildElementsHandler = async () => {
    
    let updatedObjectives = [];
    await this.getElementsData().then(data => {
      // add collapse prop to objective objects
      data.forEach(objective => {
        updatedObjectives.push({
          ...objective,
          collapse: true
        });
      });
    }).catch(error => {
      alert("Error occurred retrieving elements.");
    });

    if (updatedObjectives) {
      const mergedObjectives = this.mergeElements(updatedObjectives);
      this.setState({ objectives: mergedObjectives });
    }
  };

  collapseToggleHandler = async objective => {
    let updatedObjectives = [...this.state.objectives];
    let objectiveIndex = this.state.objectives.findIndex(element => {
      return element.element_id === objective.element_id;
    });

    const collapse = !objective.collapse;
    let newObj = objective;

    if (!collapse) {
      const res = await this.getElementsData();

      if (res) {
        newObj = res.find(nd => nd.element_id === objective.element_id);
      }
    }

    updatedObjectives[objectiveIndex] = {
      ...newObj,
      collapse: collapse
    };
    this.setState({ objectives: updatedObjectives });
  };

  /**
   * Removes a goal, strategy or action from state by id.
   * @param {number} id
   */
  removeElementById = id => {
    let objectives = [...this.state.objectives];
    let CheckForGoals  = [];
    let CheckForStrategy = [];
    let CheckForAction = [];

    objectives = objectives.filter(objective => objective.element_id !== id);
    objectives.forEach(objective => {
      CheckForGoals = [];
      CheckForStrategy = [];
      CheckForAction = [];


      objective.childElements = objective.childElements.filter(
        goal => goal.element_id !== id
      );

      CheckForGoals  = objective.childElements.filter(
        ele => ele.level_id === 3 && ele.champion_user_id == this.state.champion_user_id
      );
      
      objective.childElements.forEach(goal => {
        goal.childElements = goal.childElements.filter(
          strategy => strategy.element_id !== id
        );

        CheckForStrategy  = goal.childElements.filter(
          ele => ele.level_id === 2 && ele.champion_user_id == this.state.champion_user_id
        );
        

        goal.childElements.forEach(strategy => {
          strategy.childElements = strategy.childElements.filter(
            action => action.element_id !== id
          );

          CheckForAction  = strategy.childElements.filter(
            ele => ele.level_id === 1 && ele.champion_user_id == this.state.champion_user_id
          );
      

        });
      });
      objective.isVisibletoUser = (CheckForGoals.length > 0 || CheckForStrategy.length > 0 || CheckForAction.length > 0 ? true: false);
    });

    this.setState({ objectives });
  };

  /**
   * Deletes a goal, strategy or action from the DB.
   * @param {object} record - Record to delete (needs to have state.element_id properties).
   */
  deleteRecordFromDb = record => {
    const id = record.state.element_id;

    axios
      .delete(`/element/${id}`)
      .then(res => {
        this.removeElementById(id);
      })
      .catch(e => {
        this.setAlert("error");
        this.setState({
          isAlertOpen: true
        });
      });

    this.setState({ isModalOpen: false });
  };

  deleteRecordHandler = (record, type) => {
    this.setState({
      itemToDelete: record,
      typeToDelete: type
    });
    this.setAlert("warning", type);

    const descCheckString = "New " + type;

    if (
      record.state.description
        .toLowerCase()
        .startsWith(descCheckString.toLowerCase()) &&
      !record.state.champion_user_id &&
      !record.state.end_date_time &&
      !record.state.percent_complete &&
      !record.state.resources &&
      !record.state.start_date_time &&
      (!record.props.childElements || !record.props.childElements.length)
    ) {
      return this.deleteRecordFromDb(record);
    }

    if (record.props.childElements && record.props.childElements.length) {
      this.startAlertTimer();
      return this.setState({ isAlertOpen: true });
    }

    this.setState({ isModalOpen: true });
  };

  createStrategyHandler = goal => {
    let strategies = [...goal.childElements];
    const strat = {
      parent_id: goal.element_id,
      department_id: this.state.department_id,
      description: "New Strategy",
      level_id: 2,
      childElements: [],
      percent_complete: 0,
      duration: 0
    };
    axios.post("/element", strat).then(res => {
      strat.element_id = res.data.id;
      strategies.push(strat);
      goal.childElements = strategies;
      this.setState({ objectives: [...this.state.objectives] });
    });
  };

  createActionHandler = (goal, strategyId) => {
    let strategy = goal.childElements.find(s => s.element_id === strategyId);

    let actions = [...strategy.childElements];
    const action = {
      parent_id: strategyId,
      department_id: this.state.department_id,
      description: "New Action",
      level_id: 1,
      percent_complete: 0,
      duration: 0
    };
    axios.post("/element", action).then(res => {
      action.element_id = res.data.id;
      actions.push(action);
      strategy.childElements = actions;
      this.setState({ objectives: [...this.state.objectives] });
    });
  };

  mergeElements = updatedObjectives => {
    
    const currObjectives = [...updatedObjectives];
    const prevObjectives = [...this.state.objectives];
    
    const mergedObjectives = currObjectives.map(obj => {
      const prevObj =
        prevObjectives.find(o => o.element_id === obj.element_id) || {};
      return {
        ...prevObj,
        ...obj,
        ...{
          collapse: prevObj.collapse,
          childElements: obj.childElements.map(goal => {
            const prevGoal = prevObj.childElements
              ? prevObj.childElements.find(
                g => g.element_id === goal.element_id
              )
              : {};
            return {
              ...prevGoal,
              ...goal,
              ...{
                childElements: goal.childElements.map(strat => {
                  const prevStrat = prevGoal.childElements
                    ? prevGoal.childElements.find(
                      s => s.element_id === strat.element_id
                    )
                    : {};
                  return {
                    ...prevStrat,
                    ...strat,
                    ...{
                      childElements: strat.childElements.map(action => {
                        const prevAction = prevStrat.childElements
                          ? prevStrat.childElements.find(
                            a => a.element_id === action.element_id
                          )
                          : {};
                        return {
                          ...prevAction,
                          ...action
                        };
                      })
                    }
                  };
                })
              }
            };
          })
        }
      };
    });
    return mergedObjectives;
  };

  actionUpdatedHandler = actionId => {
    axios.get("/elements/action/" + actionId).then(res => {
      const goal = res.data[0];
      const strategy = goal.childElements[0];
      const action = strategy.childElements[0];
      const objectives = this.state.objectives.map(obj =>
        obj.element_id === goal.parent_id
          ? {
            ...obj,
            ...{
              childElements: obj.childElements.map(g =>
                g.element_id === goal.element_id
                  ? {
                    ...goal,
                    ...{
                      childElements: g.childElements.map(s =>
                        s.element_id === strategy.element_id
                          ? {
                            ...strategy,
                            ...{
                              childElements: s.childElements.map(a =>
                                a.element_id === action.element_id
                                  ? action
                                  : a
                              )
                            }
                          }
                          : s
                      )
                    }
                  }
                  : g
              )
            }
          }
          : obj
      );
      this.setState({ objectives });

      //GET LATEST CHAMPIONS AND SEND THEM EMAILS
      this.congratulateChampions(goal);

    });
  };

  render() {
    let objectives = this.state.objectives.map(objective => {

      return (
        <Objective
          {...objective}
          key={objective.element_id}
          collapse={objective.collapse}
          onCollapseToggle={() => this.collapseToggleHandler(objective)}
          user={this.props.user}
          users={this.state.users}
          onCreateGoalClicked={() => this.createGoalHandler(objective)}
          onDeleteClicked={this.deleteRecordHandler}
          onCreateStrategyClicked={this.createStrategyHandler}
          onCreateActionClicked={this.createActionHandler}
          onActionChange={this.actionUpdatedHandler}
          onChildExpanded={this.refreshChildElementsHandler}
          onGoalSortOrderEnd ={this.sortGoals}
          onStrategySortOrderEnd ={this.sortStrategy}
          toggleSorting = {this.state.toggleSorting}
        />
      );
    });

    return ( this.props.user.role_id !== 5 ? 
      <div className="position-relative quarterly-view">
        <Alert
          className="position-fixed animated slideInLeft"
          closeClassName="slideOutLeft"
          color={this.state.alertColor}
          isOpen={this.state.isAlertOpen}
          toggle={this.toggleAlert}
          fade
        >
          {this.state.alertMessage}
        </Alert>

        <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Warning</ModalHeader>
          <ModalBody>
            {`This ${this.state.typeToDelete} is about to be permanently deleted. Are you sure?`}
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => this.deleteRecordFromDb(this.state.itemToDelete)}
            >
              Yes
            </Button>{" "}
            <Button color="success" onClick={this.toggleModal}>
              No
            </Button>
          </ModalFooter>
        </Modal>

        <div className="row mb-3 header-row">
          <div className="mb-3 mt-1 ml-2 logo">
            <img src={this.props.user.client_logo || logo} />
          </div>
          <div className="text-center text-uppercase">
            <h2 className="mb-0 font-weight-normal" id="selected-plan">
              {this.props.selectedPlan.plan_title}
            </h2>
            <p className="h1  font-primary">{this.state.department_name}</p>
          </div>
          <div className="mb-3 mt-1 ml-2 admin-edit-Buttons">
            {this.renderAdminButtons()}
          </div>
          
        </div>

        <div className="row mb-4 viewFilterRow">
          <div className="filterContainer">
            <div className="d-inline-block d-inline-block-parent">
              <ChampionFilter
                  selectedUserId={this.state.champion_user_id}
                  users={this.state.users}
                  onUserChange={this.userChangeHandler}
                  disabled={false}
              ></ChampionFilter>
              {/* <DepartmentFilter
                color={this.state.primaryColor}
                user={this.props.user}
                selectedDeptId={this.state.department_id}
                departments={this.state.departments}
                onDeptChange={this.deptChangeHandler}
              ></DepartmentFilter> */}
            </div>
            <div className="d-inline-block d-inline-block-parent">
              <QuarterFilters
                color={this.state.primaryColor}
                user={this.props.user}
                quarter={this.state.quarter}
                onQuarterClicked={this.quarterChangedHandler}
              ></QuarterFilters>
              <YearFilter
                color={this.state.primaryColor}
                user={this.props.user}
                selectedYear={this.state.year}
                years={this.state.years}
                onYearChanged={this.yearChangedHandler}
              ></YearFilter>
            </div>
            {(this.props.user.role_id == 1 || this.props.user.role_id == 4 || this.props.user.role_id == 5) ?(
            <div className="d-inline-block d-inline-block-parent">
              <button id="btnGeneratePDF" className={this.state.showTimeline ? "btn btn-secondary btn-sm hide" : "btn btn-secondary btn-sm"} onClick={() => this.generatePDF()}>Export PDF</button>
              <button id="btnExportPDF" className={this.state.showTimeline ? "btn btn-secondary btn-sm" : "btn btn-secondary btn-sm hide"} onClick={() => this.exportpdf()}>Export PDF</button>
            </div>
            ):null}
          </div>
          <div className="toggleContainer">
            <TimelineToggle
              color={this.state.secondaryColor}
              showTimeline={this.state.showTimeline}
              onToggleTimeline={show => this.setState({ showTimeline: show })}
            ></TimelineToggle>
          </div>
        </div>

        {!this.state.showTimeline ? (
          objectives
        ) : (
            <Timeline
              quarter={this.state.quarter}
              year={this.state.year}
              department_id={this.state.department_id}
              strategic_plan_id={this.props.selectedPlan.strategic_plan_id}
              userId={this.state.champion_user_id}
              primaryColor={this.state.primaryColor}
              secondaryColor={this.state.secondaryColor}
            ></Timeline>
          )}
      </div>
    : <Redirect
          to="/clients"
          name="Clients"
      />);
  }
}

export default withRouter(QuarterlyView);
