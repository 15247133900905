import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { Collapse } from "reactstrap";
import axios from "axios";
import moment from "moment";
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import "./Goal.css";
import RagInput from "./RagInput";
import Strategy from "./Strategy";
import ChampionSelect from "./ChampionSelect";
import colors from "../assets/colors/colors";
import {
  childToggleHandler,
  childAddHandler,
  generateChildElementsCollapse,
  generateChildElementIds
} from "./childUtils";
const DragHandle = sortableHandle(() => <span><i class="fa fa-arrows-alt"></i></span>);
export default class Goal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorPrimary: props.user.color_primary || colors.primary,
      colorSecondary: props.user.color_secondary || colors.secondary,
      element_id: props.element_id,
      description: props.description,
      descriptionUnedited: props.description,
      start_date_time: props.start_date_time
        ? moment.parseZone(props.start_date_time)
        : null,
      end_date_time: props.end_date_time
        ? moment.parseZone(props.end_date_time)
        : null,
      focusedInput: null,
      ragId: props.ragId,
      champion_user_id: props.champion_user_id,
      champion_user_id_unedited: props.champion_user_id,
      childElements: props.childElements,
      childElementsCollapse: generateChildElementsCollapse(props.childElements),
      editDescription: false,
      strategyIds: generateChildElementIds(props.childElements),
      isEditing: false,
      selectedStratagyIds : props.prevStrategyIds,
    };

    this.childToggleHandler = childToggleHandler.bind(this);
    this.childAddHandler = childAddHandler.bind(this);
  }

  componentDidMount() {
    if (this.props.isNew && !this.props.toggleSorting) {
      this.props.onChildAdded(this.state.element_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.description !== prevProps.description ||
      this.props.champion_user_id !== prevProps.champion_user_id ||
      this.props.start_date_time !== prevProps.start_date_time ||
      this.props.end_date_time !== prevProps.end_date_time ||
      this.props.percent_complete !== prevProps.percent_complete ||
      this.props.rag_status !== prevProps.rag_status
    ) {
      this.setState({
        description: this.props.description,
        champion_user_id: this.props.champion_user_id,
        start_date_time: this.props.start_date_time
          ? moment.parseZone(this.props.start_date_time)
          : null,
        end_date_time: this.props.end_date_time
          ? moment.parseZone(this.props.end_date_time)
          : null,
        percent_complete: this.props.percent_complete,
        rag_status: this.props.rag_status
        
      });

    }
  }

  autoSave = () => {
    const element = {
      element_id: this.state.element_id,
      description: this.state.description,
      champion_user_id: this.state.champion_user_id
    };
    axios
      .put("/element/" + element.element_id, element)
      .then(() => {
        this.onSave();
        if(!this.props.toggleSorting)
        {
          this.props.onChildExpanded();
        }
      })
      .catch(error => {
        console.log(error.response.data.message);
        alert("There was a problem saving. Please try again.");
        this.onCancel();
      });
  };

  onEditToggle = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  onCancel = () => {
    this.setState({
      champion_user_id: this.state.champion_user_id_unedited,
      description: this.state.descriptionUnedited
    });
    this.onEditToggle();
  };

  onSave = () => {
    this.setState({
      champion_user_id_unedited: this.state.champion_user_id,
      descriptionUnedited: this.state.description
    });
    this.onEditToggle();
  };

  userChangeHandler = user => {
    this.setState({ champion_user_id: user.user_id });
  };

  handleStrategyAdded = () => {
    this.props.onCreateStrategyClicked();
    if (this.props.collapse) {
      this.props.onToggle(this.state.element_id);
    }
  };

  renderEditSaveBtn = background => {
    return this.state.isEditing ? (
      <button
        className="btn rounded-0 px-0 py-0 mr-2 shadow text-white  add"
        onClick={this.autoSave}
        style={background}
      >
        <i className="fas fa-check fa-fw"></i>
      </button>
    ) : (
      <button
        className="btn rounded-0 px-0 py-0 mr-2 shadow text-white  add"
        onClick={this.onEditToggle}
        style={background}
      >
        <i className="fas fa-edit fa-fw"></i>
      </button>
    );
  };

  renderMinusCancelBtn = background => {
    return this.state.isEditing ? (
      <button
        className="btn rounded-0 px-0 py-0 shadow text-white  add"
        onClick={this.onCancel}
        style={background}
      >
        <i className="fas fa-times fa-fw"></i>
      </button>
    ) : (
      <button
        className="btn rounded-0 px-0 py-0 shadow text-white  add"
        onClick={() => this.props.onDeleteClicked(this, "goal")}
        style={background}
      >
        <i className="fas fa-minus fa-fw"></i>
      </button>
    );
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onStrategySortOrderEnd(this.props.objecive_element_id,this.state.element_id,arrayMove(this.props.childElements.map((goal) => goal.element_id), oldIndex, newIndex));
  };

  renderInput = () => {
    return this.state.isEditing ? (
      <input
        type="text"
        className="font-lg form-control-plaintext ml-1 pl-1 text-white  input-toggled"
        value={this.state.description}
        onChange={event => {
          this.setState({
            description: event.target.value
          });
        }}
      />
    ) : (
      <p className="font-lg form-control-plaintext ml-2 text-white">
        {this.state.descriptionUnedited}
      </p>
    );
  };

  render() {

    const SortableItem = SortableElement(({ strategy, sortIndex, isNew, collapse}) =>
    <li sortIndex={sortIndex} element_id={strategy.element_id} className="objectives-sortable-li-strategy">
       { this.props.toggleSorting ? (<DragHandle />) : null } 
      <React.Fragment key={strategy.element_id}>
          <Strategy
            collapse={collapse}
            key={strategy.element_id}
            isNew={isNew}
            user={this.props.user}
            users={this.props.users}
            {...strategy}
            onChildAdded={this.childAddHandler}
            onToggle={this.childToggleHandler}
            onCreateActionClicked={() => {
              this.props.onCreateActionClicked(strategy.element_id);
            }}
            onDeleteClicked={this.props.onDeleteClicked}
            onActionChange={this.props.onActionChange}
            onChildExpanded={this.props.onChildExpanded}
            GoalAssignedTologgedInUser ={this.state.isAssignedToLoggedInUser}
            onChildExpandedStates = {this.props.onChildExpandedStates}
            toggleSorting ={this.props.toggleSorting}
          />
        </React.Fragment>
    </li>
  );


    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul class="objectives-sortable">
          {items
          .map((strategy, index) => (
            <SortableItem
              key={`item-strategy-${index}`}
              index={index}
              sortIndex={index}
              strategy={strategy}
              pressDelay={1}
              isNew={!this.state.strategyIds.includes(strategy.element_id)}
              collapse={(this.state.selectedStratagyIds.filter(x=>x == strategy.element_id).length > 0 ? false :true)}
            />
          ))}
        </ul>
      );
    });

    const sortableStrategy = this.props.toggleSorting ? (<SortableList items={this.props.childElements} onSortEnd={this.onSortEnd}  useDragHandle />) : (

      this.props.childElements.map(strategy => {
        const isNew = !this.state.strategyIds.includes(strategy.element_id);
        const collapse = this.state.childElementsCollapse[strategy.element_id];
  
        return (
          <React.Fragment key={strategy.element_id}>
            <Strategy
              collapse={collapse}
              key={strategy.element_id}
              isNew={isNew}
              user={this.props.user}
              users={this.props.users}
              {...strategy}
              onChildAdded={this.childAddHandler}
              onToggle={this.childToggleHandler}
              onCreateActionClicked={() => {
                this.props.onCreateActionClicked(strategy.element_id);
              }}
              onDeleteClicked={this.props.onDeleteClicked}
              onActionChange={this.props.onActionChange}
              onChildExpanded={this.props.onChildExpanded}
              GoalAssignedTologgedInUser ={this.state.isAssignedToLoggedInUser}
              onChildExpandedStates ={this.props.onChildExpandedStates}
            />
          </React.Fragment>
        );
      })
    );

    let backgroundPrimary = { backgroundColor: this.state.colorPrimary };
    let backgroundSecondary = { backgroundColor: this.state.colorSecondary };
    const strategyCount = this.props.childElements.length;
    const lastChildElement =
      this.props.childElements &&
      this.props.childElements[this.props.childElements.length - 1];
    const lastChildElementId = lastChildElement && lastChildElement.element_id;
    const lastChildCollapsed = this.state.childElementsCollapse[
      lastChildElementId
    ];

    // const strategies = this.props.childElements.map(strategy => {
    //   const isNew = !this.state.strategyIds.includes(strategy.element_id);
    //   const collapse = this.state.childElementsCollapse[strategy.element_id];

    //   return (
    //     <React.Fragment key={strategy.element_id}>
    //       <Strategy
    //         collapse={collapse}
    //         key={strategy.element_id}
    //         isNew={isNew}
    //         user={this.props.user}
    //         users={this.props.users}
    //         {...strategy}
    //         onChildAdded={this.childAddHandler}
    //         onToggle={this.childToggleHandler}
    //         onCreateActionClicked={() => {
    //           this.props.onCreateActionClicked(strategy.element_id);
    //         }}
    //         onDeleteClicked={this.props.onDeleteClicked}
    //         onActionChange={this.props.onActionChange}
    //         onChildExpanded={this.props.onChildExpanded}
    //         GoalAssignedTologgedInUser ={this.state.isAssignedToLoggedInUser}
    //         onChildExpandedStates ={this.props.onChildExpandedStates}
    //       />
    //     </React.Fragment>
    //   );
    // });

    const fadeAnimation = this.props.isNew ? " animated fadeInRight" : "";
    return (
     
      <div className={`ml-4`}>
        <div
          className="row shadow--inset py-2  goal"
          style={backgroundSecondary}
        >
          <div
            className="col my-auto mr-auto d-flex align-items-center"
            title="Goal"
          >
            <div className="font-lg mb-0 pr-2 text-white font-weight-bold text-uppercase  objective--br">
              Goal 
            </div>
            {this.renderInput()}
          </div>
          <div className="col-auto my-auto">
            <ChampionSelect
              disabled={!this.state.isEditing}
              selectedUserId={this.state.champion_user_id}
              users={this.props.users}
              onUserChange={this.userChangeHandler}
            ></ChampionSelect>
          </div>

          <div className="col-auto my-auto">
            <RagInput
              rag_status={this.props.rag_status}
              percent_complete={this.props.percent_complete}
              disabled={true}
            ></RagInput>
          </div>
          <div className="col-auto my-auto">
            <DateRangePicker
              disabled
              startDate={this.state.start_date_time}
              startDateId={"startDate" + this.props.id}
              endDate={this.state.end_date_time}
              endDateId={"endDate" + this.props.id}
              onDatesChange={({ startDate, endDate }) =>
                this.setState({ startDate, endDate })
              }
              displayFormat="M/D/YYYY"
              small
              noBorder
              anchorDirection="right"
            />
          </div>
          <div className="col-auto my-auto">
            <button
              className="btn btn-link rounded-0 mr-2 py-0 shadow text-white  expand-collapse"
              onClick={() => {
                this.props.onToggle(this.state.element_id);
                if (this.props.collapse) {
                   this.props.onChildExpanded();
                }
              }}
              style={backgroundPrimary}
            >
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span
                    className="mr-1 rounded-sm font-xs font-weight-bold  dropdown-badge"
                    style={{
                      backgroundColor: "white",
                      color: this.state.colorPrimary
                    }}
                  >
                    {strategyCount}
                  </span>
                  <span>{strategyCount === 1 ? "Strategy" : "Strategies"}</span>
                </div>
                {this.props.collapse ? (
                  <i className="fas fa-chevron-down fa-fw"></i>
                ) : (
                  <i className="fas fa-chevron-up fa-fw"></i>
                )}
              </div>
            </button>
            {!this.props.toggleSorting ?  this.renderEditSaveBtn(backgroundPrimary) : null}
            {!this.props.toggleSorting ? this.renderMinusCancelBtn(backgroundPrimary): null}
          </div>
        </div>
        <Collapse isOpen={!this.props.collapse}>
          {sortableStrategy}
          {/* {strategies} */}

          {(lastChildCollapsed ||
            (this.props.childElements && !this.props.childElements.length)) && (
            <div className="row justify-content-end ml-4 py-1">
              {!this.props.toggleSorting ? 
              (<button
                className={"btn rounded-0 px-0 py-0"}
                onClick={this.handleStrategyAdded}
              >
                <p className="d-inline-block my-0 mr-3">Add Strategy</p>
                <i
                  className="fas fa-plus fa-fw text-white py-1  add add--mr"
                  style={backgroundPrimary}
                ></i>
              </button>) : null}
            </div>
          )}
        </Collapse>
      </div>
    );
  }
}
