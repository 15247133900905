import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import ActiveFilterToggler from "../RoadMap/ActiveFilterToggler";
import ClientsFilter from "./ClientsFilter";

import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Fade
} from "reactstrap";
import { tuple } from "antd/lib/_util/type";
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      roleLookup: {},
      departmentLookup: {},
      filer: 1,
      isModalVisible: false,
      selcted_user_id: 0,
      selcted_user_name: "",
      newPassword: "",
      confirmPassword: "",
      passwordError: "",
      selected_user_email: "",
      resetPassError: "",
      secondaryColor: props.user.color_secondary,
      showActive: false,
      clients:[],
      selected_client_id:0,
      superAdmin: false,
      sortOrder: 'ASC',
      sortColumn:'client'
    };
  }

  onChange = e => {

    this.setState({
      filer: e,
      showActive: (e > 0 ? true : false)
    }); 
    
    let currentClientID = this.state.superAdmin ?  this.state.selected_client_id : this.props.user.client_id;

    axios.get(`/users/enabled/${e}/${currentClientID}/${this.state.sortColumn}/ASC`).then(res => {
      this.setState({
        users: res.data
      });
    });
  };

  showModal = (user_id) => {
    if (user_id > 0) {
      axios.get(`/users/${user_id}`).then(res => {
        let user = res.data;
        if (user != null) {
          this.setState({
            isModalVisible: !this.state.isModalVisible,
            selcted_user_id: user_id,
            selcted_user_name: user.username,
            selected_user_email: user.user_email
          });
        }
      });
    }
  };

  getDatesForUser = (isActive,startDate,endDate)=>{
    let showFormattedDates = "";
    if(isActive && startDate != null)
    {
      showFormattedDates = "("+moment(startDate).format("YYYY-MM-DD")+")";
    }
    else if (!isActive && startDate != null && endDate != null){
      showFormattedDates = "("+moment(startDate).format("YYYY-MM-DD")+" - "+moment(endDate).format("YYYY-MM-DD")+")";
    }
    
    return showFormattedDates;
  }

  clientChangeHandler = client => {
    this.setState({ selected_client_id: (client !== 0 ? client.client_id : 0) }, () => this.getAllUserByClient());
  };

  getAllUserByClient(){
    axios.get(`/users/enabled/${this.state.showActive ? 1 : 0}/${this.state.selected_client_id}/${this.state.sortColumn}/ASC`).then(res=>  
      this.setState({
      users: res.data
    }));

  }

  handleCancel = e => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      resetPassError: "",
      passwordError: "",
      newPassword: "",
      confirmPassword: "",
      selcted_user_id: 0,
      selcted_user_name: "",
      selected_user_email: ""
    });
  };

  resetPasswordHandler = () => {
    if (
      !this.state.newPassword ||
      !this.state.confirmPassword
    ) {
      this.setState({ passwordError: "Error! Please fill out all fields" });
      return;
    } else if (this.state.newPassword !== this.state.confirmPassword) {

      this.setState({
        passwordError: "Error! New password and confirmation password must match"
      });
      return;
    } else {
      this.setState({ passwordError: null });
    }
    var userDetails = {
      user_id: this.state.selcted_user_id,
      username: this.state.selcted_user_name,
      password: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
      user_email: this.state.selected_user_email
    };

    axios
      .post("/users/" + userDetails.user_id + "/resetbyAdmin", userDetails)
      .then(res => {
        this.setState({
          password: null,
          confirmPassword: null,
          passwordError: null,
          newPassword: "",
          confirmPassword: ""
        });

        var message = res.data;
        if (message == "SUCCESS") {
          this.setState({ resetPassError: "Password has been changed successfully!" });
        }
        else {
          this.setState({ resetPassError: "Error while updating password!" });
        }
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  SortHandler(sortColumn,sortOrder){
    axios.get(`/users/enabled/${this.state.showActive ? 1 : 0}/${this.state.selected_client_id}/${sortColumn}/${sortOrder}`).then(res => {
      this.setState({
        users: res.data,
        sortOrder : (sortOrder === "ASC"  ? "DESC" : "ASC"),
        sortColumn : sortColumn
      });
    });
  }


  //todo group by department
  async componentDidMount() {
    const [
      usersResponse,
      rolesResponse,
      departmentsResponse,
      clientResponse
    ] = await Promise.all([
      axios.get(`/users/enabled/${1}/${this.props.user.client_id}/${this.state.sortColumn}/${this.state.sortOrder}`),
      axios.get(`/clients/${this.props.user.client_id}/roles`),
      axios.get(`/clients/${this.props.user.client_id}/departments`),
      axios.get(`/clients`)
    ]);

    const roles = rolesResponse.data;
    const roleLookup = {};
    roles.forEach(role => {
      roleLookup[role.role_id] = role.role_name;
    });
    const departments = departmentsResponse.data;
    const departmentLookup = {};
    departments.forEach(department => {
      departmentLookup[department.department_id] = department.department_name;
    });

    let sortOrder = this.state.sortOrder;
    let sortColumn = this.state.sortColumn;
    
    this.setState({
      users: usersResponse.data,
      roleLookup,
      departmentLookup,
      showActive : true,
      clients: clientResponse.data[0],
      superAdmin: (this.props.user.role_id === 5),
      selected_client_id : 0,
      sortOrder : (sortOrder === "ASC"  ? "DESC" : "ASC"),
      sortColumn : sortColumn
    });
  }

  render() {
    const modal = (
      <Modal isOpen={this.state.isModalVisible} toggle={this.handleCancel}>
        <ModalHeader toggle={this.handleCancel}>Reset Password</ModalHeader>
        <ModalBody>
          {this.state.resetPassError && (
            <div className="form-group row mt-3 mb-0">
              <div className="col">
                <Alert color="success">
                  {this.state.resetPassError}
                </Alert>
              </div>
            </div>
          )}
          {this.state.passwordError && (
            <div className="form-group row mt-3 mb-0">
              <div className="col">
                <Alert color="danger">
                  {this.state.passwordError}
                </Alert>
              </div>
            </div>
          )}
          <form>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Username</label>
              <div className="col-sm-8 mt-2">
                <p><b>{this.state.selcted_user_name}</b></p>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">New Password</label>
              <div className="col-sm-8 mt-2">
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  autoComplete="newPassword"
                  placeholder="Enter new password"
                  onChange={(event) => this.setState({ newPassword: event.target.value })}
                  
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Confirm Password</label>
              <div className="col-sm-8 mt-2">
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  autoComplete="confirmPassword"
                  placeholder="Enter confirm password"
                  onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                  
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={this.handleCancel} className="btn btn-outline-danger">
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => this.resetPasswordHandler()}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
    );


    return (
      <React.Fragment>
        <React.Fragment>{modal}</React.Fragment>
        <div className="row mb-2">
          <div className="col-auto mr-auto ">
            <h2>Users</h2>
          </div>

          <div className="col-auto">
            {this.state.superAdmin ? (
            <span className="filter-position-client">
              <ClientsFilter selectedClientID={this.state.selected_client_id} clients={this.state.clients} onClientChange={this.clientChangeHandler}
                disabled={false}></ClientsFilter>
            </span>
            ):null}
            <span className="filter-position">
              <ActiveFilterToggler
                color={this.state.secondaryColor}
                showActive={this.state.showActive}
                onToggleActive={this.onChange}>
              </ActiveFilterToggler>
            </span>
            <Link to="/users/create">
              <button className="btn btn-outline-primary mr-2 shadow float-right">
                <i className="fas fa-user-plus"></i>&nbsp;New User
                    </button>
            </Link>
          </div>
        </div>
        {this.state.users.length > 0 ? (
          <div className="card animated fadeIn faster">
            <table className="table table-hover">
              <thead>
                <tr>
                  {this.state.superAdmin ? (
                  <th> <a href="javascript:void(0);" onClick={() => this.SortHandler(this.state.sortColumn,this.state.sortOrder)}>Client {this.state.sortOrder == "ASC" ? (<i class="fa fa-chevron-down"></i>) : (<i class="fa fa-chevron-up"></i>)}<i class="fa fa-che"></i></a></th>
                  ) : null}
                  <th>Name</th>
                  <th>Department</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.users.map(user => {
                  return (
                    <tr key={user.user_id}>
                      {this.state.superAdmin ? (
                      <td>
                        {user.client_name}
                      </td>
                       ) : null}
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                      <td>
                        {user.department_name ?? (
                          <span className="font-italic text-muted">
                            Not assigned
                          </span>
                        )}
                      </td>
                      <td>{this.state.roleLookup[user.role_id]}</td>
                      <td>{((user.end_date == null || moment().isBefore(user.end_date)) ? "Active "+this.getDatesForUser((user.end_date == null || moment().isBefore(user.end_date)),user.start_date,user.end_date)+"" : "Disabled "+this.getDatesForUser((user.end_date == null || moment().isBefore(user.end_date)),user.start_date,user.end_date)+"  ")}</td>
                      <td className="text-center">
                        <Link to={"/users/" + user.user_id}>
                          <button className="btn btn-link py-0">
                            <i className="fas fa-user-edit"></i>&nbsp;Edit User
                          </button>
                        </Link>
                        <button className="btn btn-link py-0" onClick={() => this.showModal(user.user_id)}>
                          <i className="fas fa-retweet"></i>&nbsp;Reset Password
                          </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </React.Fragment>

    );
  }
}

export default Users;