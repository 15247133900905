import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { Collapse } from "reactstrap";
import axios from "axios";
import moment from "moment";

import RagInput from "./RagInput";
import "./action.scss";
import ChampionSelect from "./ChampionSelect";
import colors from "../assets/colors/colors";

export default class Action extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorPrimary: props.user.color_primary || colors.primary,
      element_id: props.element_id,
      description: props.description,
      descriptionUnedited: props.description,
      start_date_time: props.start_date_time
        ? moment.parseZone(props.start_date_time)
        : null,
      start_date_time_unedited: props.start_date_time
        ? moment.parseZone(props.start_date_time)
        : null,
      end_date_time: props.end_date_time
        ? moment.parseZone(props.end_date_time)
        : null,
      end_date_time_unedited: props.end_date_time
        ? moment.parseZone(props.end_date_time)
        : null,
      duration_hours: props.duration_hours,
      resources: props.resources,
      resourcesUnedited: props.resources,
      focusedInput: null,
      percent_complete: props.percent_complete,
      percent_complete_unedited: props.percent_complete,
      champion_user_id: props.champion_user_id,
      champion_user_id_unedited: props.champion_user_id,
      isEditing: false
      
    };
  }

  componentDidMount() {
    if (this.props.isNew && !this.props.toggleSorting) {
      this.props.onChildAdded(this.state.element_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.rag_status !== prevProps.rag_status ||
      this.props.description !== prevProps.description ||
      this.props.champion_user_id !== prevProps.champion_user_id ||
      this.props.start_date_time !== prevProps.start_date_time ||
      this.props.end_date_time !== prevProps.end_date_time ||
      this.props.percent_complete !== prevProps.percent_complete
    ) {
      this.setState({
        description: this.props.description,
        champion_user_id: this.props.champion_user_id,
        rag_status: this.props.rag_status,
        start_date_time: this.props.start_date_time
          ? moment.parseZone(this.props.start_date_time)
          : null,
        start_date_time_unedited: this.props.start_date_time
          ? moment.parseZone(this.props.start_date_time)
          : null,
        end_date_time: this.props.end_date_time
          ? moment.parseZone(this.props.end_date_time)
          : null,
        end_date_time_unedited: this.props.end_date_time
          ? moment.parseZone(this.props.end_date_time)
          : null,
        percent_complete: this.props.percent_complete
      });
    }
  }

  autoSave = () => {
    const element = {
      element_id: this.state.element_id,
      description: this.state.description,
      resources: this.state.resources,
      champion_user_id: this.state.champion_user_id,
      percent_complete: this.state.percent_complete,
      start_date_time: this.state.start_date_time
        ? this.state.start_date_time.format("YYYY-MM-DD")
        : null,
      end_date_time: this.state.end_date_time
        ? this.state.end_date_time.format("YYYY-MM-DD")
        : null
    };
    axios
      .put("/element/" + element.element_id, element)
      .then(res => {
        this.props.onActionChange(element.element_id);
        this.onSave();
        
      })
      .catch(error => {
        console.log(error.response.data.message);
        alert("There was a problem saving. Please try again.");
        this.onCancel();
      });
  };

  onEditToggle = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  onCancel = () => {
    this.setState({
      champion_user_id: this.state.champion_user_id_unedited,
      description: this.state.descriptionUnedited,
      percent_complete: this.state.percent_complete_unedited,
      resources: this.state.resourcesUnedited,
      start_date_time: this.state.start_date_time_unedited,
      end_date_time: this.state.end_date_time_unedited
    });
    this.onEditToggle();
  };

  onSave = () => {
    this.setState({
      champion_user_id_unedited: this.state.champion_user_id,
      descriptionUnedited: this.state.description,
      percent_complete_unedited: this.state.percent_complete,
      resourcesUnedited: this.state.resources,
      start_date_time_unedited: this.state.start_date_time,
      end_date_time_unedited: this.state.end_date_time
    });
    this.onEditToggle();
  };

  userChangeHandler = user => {
    this.setState({ champion_user_id: user.user_id });
  };

  dateChangeHandler = ({ startDate, endDate }) => {
    if (startDate) startDate.startOf("day");
    if (endDate) endDate.startOf("day");

    let hours = this.state.duration_hours;
    if (!startDate || !endDate) {
      // No date range
      hours = 0;
    } else if (
      this.state.start_date_time &&
      !this.state.start_date_time.isSame(startDate, "date")
    ) {
      // move end date based on duration_hours
      endDate = moment(startDate).add(hours, "hours");
    } else if (
      !this.state.start_date_time ||
      !this.state.end_date_time ||
      !this.state.end_date_time.isSame(endDate, "date")
    ) {
      // recalculate duration
      const duration = moment.duration(endDate.diff(startDate));
      hours = duration.asHours();
    }
    this.setState({
      start_date_time: startDate,
      end_date_time: endDate,
      duration_hours: hours
    });
  };

  renderEditSaveBtn = background => {
    return this.state.isEditing ? (
      <button
        className="btn rounded-0 px-0 py-0 mr-2 shadow text-white  add"
        onClick={this.autoSave}
        style={background}
      >
        <i className="fas fa-check fa-fw"></i>
      </button>
    ) : (
      <button
        className="btn rounded-0 px-0 py-0 mr-2 shadow text-white  add"
        onClick={this.onEditToggle}
        style={background}
      >
        <i className="fas fa-edit fa-fw"></i>
      </button>
    );
  };

  renderMinusCancelBtn = background => {
    return this.state.isEditing ? (
      <button
        className="btn rounded-0 px-0 py-0 shadow text-white  add"
        onClick={this.onCancel}
        style={background}
      >
        <i className="fas fa-times fa-fw"></i>
      </button>
    ) : (
      <button
        className="btn rounded-0 px-0 py-0 shadow text-white  add"
        onClick={() => this.props.onDeleteClicked(this, "action")}
        style={background}
      >
        <i className="fas fa-minus fa-fw"></i>
      </button>
    );
  };

  renderInput = () => {
    return this.state.isEditing ? (
      <input
        type="text"
        className="font-lg form-control-plaintext ml-1 pl-1  input-toggled"
        value={this.state.description}
        onChange={event => {
          this.setState({
            description: event.target.value
          });
        }}
      />
    ) : (
      <p className="font-lg form-control-plaintext ml-2">
        {this.state.descriptionUnedited}
      </p>
    );
  };

  renderTextarea = () => {
    return this.state.isEditing ? (
      <textarea
        className="form-control Resources"
        disabled={!this.state.isEditing}
        value={this.state.resources}
        onChange={event => {
          this.setState({
            resources: event.target.value
          });
        }}
      ></textarea>
    ) : (
      <p className="form-control form-control-plaintext">
        {this.state.resourcesUnedited}
      </p>
    );
  };

  render() {
    let backgroundPrimary = { backgroundColor: this.state.colorPrimary };
    const fadeAnimation = this.props.isNew ? " animated fadeInRight" : "";
    return (
      
      <div className={"ml-4"}>
        <div className="row shadow--inset py-2  action">
          <div className="col">
            <div className="row">
              <div
                className="col my-auto mr-auto d-flex align-items-center"
                title="Action">
                <div className="font-lg mb-0 pr-2 font-weight-bold text-uppercase text-primary objective--br">
                  Action 
                </div>
                {this.renderInput()}
              </div>
              <div className="col-auto my-auto">
                <ChampionSelect
                  disabled={!this.state.isEditing}
                  selectedUserId={this.state.champion_user_id}
                  users={this.props.users}
                  onUserChange={this.userChangeHandler}
                ></ChampionSelect>
              </div>

              <div className="col-auto my-auto d-flex align-items-center">
                <RagInput
                  disabled={!this.state.isEditing}
                  rag_status={this.props.rag_status}
                  percent_complete={this.state.percent_complete_unedited}
                  percentInput
                  onPercentChange={value =>
                    this.setState({ percent_complete: value })
                  }
                ></RagInput>
              </div>
              <div className="col-auto my-auto">
                <DateRangePicker
                  disabled={!this.state.isEditing}
                  className="form-control"
                  startDate={this.state.start_date_time}
                  startDateId={"startDate" + this.props.id}
                  endDate={this.state.end_date_time}
                  endDateId={"endDate" + this.props.id}
                  onDatesChange={this.dateChangeHandler}
                  displayFormat="M/D/YYYY"
                  isOutsideRange={() => false}
                  small
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput =>
                    this.setState({ focusedInput })
                  }
                  verticalSpacing={10}
                  inputIconPosition="after"
                  anchorDirection="right"
                  hideKeyboardShortcutsPanel
                />
              </div>
              <div className="col-auto my-auto">
                <button
                  title="View Resources"
                  className="btn btn-link text-white rounded-0 mr-2 py-0 shadow  expand-collapse"
                  style={backgroundPrimary}
                  onClick={() => {
                    this.props.onToggle(this.state.element_id);
                    if (this.props.collapse) {
                      this.props.onChildExpanded();
                    }
                  }}
                >
                  <div className="align-items-center d-flex justify-content-between">
                    <span>Resources</span>
                    {this.props.collapse ? (
                      <i className="fas fa-chevron-down fa-fw"></i>
                    ) : (
                      <i className="fas fa-chevron-up fa-fw"></i>
                    )}
                  </div>
                </button>
                {this.renderEditSaveBtn(backgroundPrimary)}
                {this.renderMinusCancelBtn(backgroundPrimary)}
              </div>
            </div>
          </div>
        </div>
        <Collapse className="row bg-white ml-2" isOpen={!this.props.collapse}>
          <div className="col-12 mb-2">
            <small className="form-text text-muted">Resources Required:</small>
            {this.renderTextarea()}
          </div>
        </Collapse>
      </div>
    );
  }
}
